import { h, Component } from "preact";
import { fetchAPI } from "../../libs/api";
const _ = require('lodash');
import "./brand.scss";

// Components
import EditTab from "./tabs/editTab";

export class Brand extends Component {
   state = {
      brand: {},
      loading: true,
   };

	async refreshBrand() {
      return fetchAPI(`/api/brand/${this.props.id}`, { headers: { 'Content-Type': 'application/json' }})
         .then(response => response.json())
         .then(data => this.setState({ brand: data.brand, loading: false }))
         .catch(err => this.setState({ error: err }));
   }

   componentDidMount() {
      this.refreshBrand()
   }

   handleError(error) {
      this.setState({ errorMessage: error.message });
      this.errorDialogRef.current.show();
   }

   render(props, state) {
      const { 
         brand,  
         loading, 
         error 
      } = this.state;
      
      if (error) return <p>{error.message}</p>
      return !loading && (
         <div class="brand-info">
            <h2 class="headline">{brand.name}</h2>
				<EditTab brand={brand} />
         </div>
      );
   }
}
