import { h, Component, Fragment, createRef } from "preact"
import { fetchAPI } from "../../../libs/api"
import { Dialog } from "../../dialog"
import { Tags } from "../../tags"

const _ = require('lodash')
import "./championship.scss"

export class T100Championship extends Component {
	deleteConfirmDialogRef = createRef()
	errorDialogRef = createRef()
	archiveConfirmDialogRef = createRef()

	state = {
		championship: {},
		originalChampionship: {},
		loading: true,
		seasons: [],
		isInputError: false,
		isChangesToPersist: false,
	}

	async refreshChampionship() {
		return fetchAPI(`/api/t100/championships/${this.props.id}`, { headers: { 'Content-Type': 'application/json' } })
			.then(response => response.json())
			.then(data => this.setState({ originalChampionship: data.championship, championship: data.championship, loading: false }))
			.catch(err => this.setState({ error: err }))
	}

	componentDidMount() {
		this.refreshChampionship()
		this.fetchSeasons()
	}

	changeHandler(event, data) {
		const { championship, originalChampionship } = this.state
		const field = event?.target?.dataset?.attr || data.props['data-attr']
		const value = typeof event === "number" ? event : event?.target?.value
		const isInputError = event?.target ? !event.target.checkValidity() : false

		// Create copy of championship object and update relevant value
		let updatedChampionship = { ...championship }
		updatedChampionship[field] = (value === "" || value === undefined) ? null : value

		// Check if inputted values are different to original values
		const isChangesToPersist = !_.isEqual(originalChampionship, updatedChampionship)

		this.setState({
			isEmptyField: value === (null || ""),
			isInputError,
			isChangesToPersist,
			championship: updatedChampionship,
		})

	}

	updateChampionship() {
		const { championship } = this.state

		fetchAPI(`/api/t100/championships/${this.props.id}`, {
			method: "put",
			body: JSON.stringify({ ...championship }),
			headers: { 'Content-Type': 'application/json' }
		})
			.then(response => response.json())
			.then(() => {
				this.setState({ originaChampionship: championship, isChangesToPersist: false })
				location = `/t100/championship/${this.props.id}`
			})
			.catch(error => {
				this.setState({ errorMessage: error.message })
				this.errorDialogRef.current.show()
			})
	}

	handleError(error) {
		this.setState({ errorMessage: error.message })
		this.errorDialogRef.current.show()
	}

	deleteChampionship() {
		fetchAPI(`/api/t100/championships/${this.props.id}`, { method: "delete", headers: { 'Content-Type': 'application/json' } })
			.then(() => location = '/t100/championships')
			.catch(this.handleError.bind(this))
	}

	openDeleteDialog() {
		this.deleteConfirmDialogRef.current.show()
	}

	openArchiveDialog() {
		this.archiveConfirmDialogRef.current.show()
	}

	toggleIsActive() {
		const { championship } = this.state
		fetchAPI(`/api/t100/championships/${championship.id}/${championship.is_active ? "archive" : "publish"}`, { method: "post" })
			.then(this.refreshChampionship.bind(this))
			.catch(this.handleError.bind(this))
	}

	async fetchSeasons() {
		fetchAPI("/api/t100/seasons", { headers: { 'Content-Type': 'application/json' } })
			.then(response => response.json())
			.then(data => this.setState({ seasons: data.seasons }))
			.catch(error => alert(error.message))
	}

	render(props, state) {
		const {
			championship,
			originalChampionship,
			loading,
			error,
			errorMessage,
			isEmptyField,
			isInputError,
			isChangesToPersist
		} = this.state

		if (error) return <p>{ error.message }</p>
		return !loading && (
			<div class="championship-info">
				<h2 class="headline">{ originalChampionship.name }</h2>

				<div class="card">
					<table>
						<tr>
							<td>Name</td>
							<td>
								<input
									class="form-control form-control-sm"
									onInput={ this.changeHandler.bind(this) }
									data-attr="name"
									value={ state.championship.name }
									type="text"
								/>
							</td>
						</tr>
						<tr>
							<td>Region</td>
							<td>
								<input
									class="form-control form-control-sm"
									onInput={ this.changeHandler.bind(this) }
									data-attr="region"
									value={ state.championship.region }
									type="text"
								/>
							</td>
						</tr>
						<tr>
							<th>Season</th>
							<td>
								<div class='select'>
									<select class='form-control form-control-sm' data-attr="t100_season_id" onChange={ this.changeHandler.bind(this) }>
										<option value='' selected={ !state.championship.t100_season_id }>-</option>
										{ state.seasons.map(x => <option value={ x.id } selected={ x.id == championship.t100_season_id }>{ x.name }</option>) }
									</select>
								</div>
							</td>
						</tr>
					</table>
					<div class="pt-3">
						<button class="btn btn-sm btn-primary"
							onClick={ this.updateChampionship.bind(this) }
							disabled={ (!isChangesToPersist || isEmptyField || isInputError) }>
							Save Changes
						</button>

						{ !championship.isRelatedRecords && (
							<Fragment>
								<button class="btn btn-sm btn-secondary ml-3" onClick={ this.openArchiveDialog.bind(this) }>
									{ this.state.championship.is_active ? "Archive" : "Publish" }
								</button>

								<Dialog title={ `${championship.is_active ? "Archive" : "Publish"} championship?` } ref={ this.archiveConfirmDialogRef }
									yesText={ championship.is_active ? "Archive" : "Publish" }
									onYes={ this.toggleIsActive.bind(this) }>
									<p>Are you sure you want to { championship.is_active ? "archive" : "publish" } this championship?</p>
								</Dialog>

								<button class="btn btn-sm btn-danger ml-3" onClick={ this.openDeleteDialog.bind(this) }>
									Delete Championship
								</button>

								<Dialog title="Delete season?" ref={ this.deleteConfirmDialogRef } yesText="Confirm Delete" onYes={ this.deleteChampionship.bind(this) }>
									<p>Are you sure you want to delete this championship?</p>
								</Dialog>
							</Fragment>
						) }
					</div>

					<Dialog nofooter={ true } title="Error!" ref={ this.errorDialogRef }>
						<p>{ errorMessage }</p>
					</Dialog>
				</div>
			</div>
		)
	}
}

