import { h, Component, createRef, Fragment } from "preact";
import { fetchAPI } from "../../../libs/api";
import { Dialog } from "../../dialog";

export default class AddTier extends Component {
   state = {
       isEmptyField: true,
       errorMessage: null,
       tier: {
           name: "",
           description: "",
           basePoints: "",
           dropoffPercentage: ""
       }
   }

   errorDialogRef = createRef();

   changeHandler(event) {
       const field = event.target.dataset.attr;
       const value = event.target.value;

       let updatedTier = this.state.tier;
       updatedTier[field] = value;

       this.setState({ tier: updatedTier }, 
         () => {
           const { tier } = this.state;
           const isEmptyField = (tier.name === "") || (tier.basePoints === "") || (tier.dropoffPercentage === "");
           console.log(isEmptyField)
           this.setState({ isEmptyField });           
       });
   }

   addTier() {
       const { name, description, basePoints, dropoffPercentage } = this.state.tier
       fetchAPI("/api/tier", {
           method: "post",
           headers: { 'Content-Type': 'application/json' },
           body: JSON.stringify({ name: name, description: description, basePoints: basePoints, dropoffPercentage: dropoffPercentage })
       })
           .then(response => response.json())
           .then(() => this.props.refreshTiers())
           .then(() => this.setState({ tier: { name: "", description: "", basePoints: "", dropoffPercentage: "" } }))
           .catch(error => {
               this.setState({ errorMessage: error.message });
               this.errorDialogRef.current.show();
           });
   }

   render(props, state) {
       const { isEmptyField, errorMessage } = state;

       return (
           <Fragment>
               <Dialog yesText="Add Tier" title="Add a new Tier" ref={props.reference} onYes={this.addTier.bind(this)} disabled={isEmptyField}>
                   <table>
                        {["Name", "Description"].map((fieldHeader) => (
                           <tr>
                              <td>{fieldHeader}</td>
                              <td>
                                 <input
                                       class="form-control form-control-sm"
                                       onInput={this.changeHandler.bind(this)}
                                       data-attr={fieldHeader.toLowerCase()}
                                       value={state[fieldHeader.toLowerCase()]}
                                 />
                              </td>
                           </tr>
                        ))}
                        <tr>
                           <td>Base Points</td>
                           <td>
                              <input
                                    class="form-control form-control-sm"
                                    onInput={this.changeHandler.bind(this)}
                                    data-attr="basePoints"
                                    value={state.basePoints}
                                    type="number"
                                    min="0"
                                    max="100"
                              />
                           </td>
                        </tr>
                        <tr>
                           <td>Dropoff Percentage</td>
                           <td>
                              <input
                                    class="form-control form-control-sm"
                                    onInput={this.changeHandler.bind(this)}
                                    data-attr="dropoffPercentage"
                                    value={state.dropoffPercentage}
                                    type="number"
                                    min="0"
                                    max="100"
                              />
                           </td>
                        </tr>
                   </table>
               </Dialog>

              <Dialog nofooter={true} title="Error!" ref={this.errorDialogRef}>
                 <p>{errorMessage}</p>
              </Dialog>
           </Fragment>
       )
   }
}
