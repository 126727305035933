import Cookies from 'js-cookie'


/*let logout = document.getElementById('logout')
if(logout)
{
	logout.addEventListener("click", (e) => {
		fetch("/auth/logout", {
			method: "post",
			headers: { 'Content-Type': 'application/json' },
		})
		.then(function(response) { if(response.ok) return response.json(); return Promise.reject(response); })
		.then(function(data) {
			localStorage.removeItem('pto_token')
			//Cookies.remove('pto_token')
			location = "/"
		});
	})
}*/


/*let signupForm = document.getElementById('signup-form')
let signupError = document.getElementById('signup-error')
if(signupForm)
{
	signupForm.working = false
	signupForm.addEventListener("submit", (e) => {
		const formData = new FormData(e.target)

		if(signupForm.working) return

		signupForm.working = true


		const now = new Date();
		fetch("/auth/signup", {
			method: "post",
			headers: { 'Content-Type': 'application/json' },
			body: JSON.stringify({
				"email": encodeURI(formData.get("email")),
				"first": formData.get("first"),
				"last": formData.get("last"),
				"timezone": -now.getTimezoneOffset() / 60
			})
		})
		.then(function(response) { if(response.ok) return response.json(); return Promise.reject(response); })
		.then(function(data) {
			if(data.status == 0)
			{
				location = "/verifyemail/" + formData.get("email");
			}
			else
			{
				if(signupError) signupError.textContent = data.error
				signupForm.working = false
			}
		});

		e.preventDefault();
	})
}*/

/*let verifyEmailForm = document.getElementById('verify-email-form')
let verifyEmailError = document.getElementById('verify-email-error')
if(verifyEmailForm)
{
	verifyEmailForm.working = false
	verifyEmailForm.addEventListener("submit", (e) => {
		const formData = new FormData(e.target)

		if(verifyEmailForm.working) return

		verifyEmailForm.working = true

		fetch("/auth/verifyemail", {
			method: "post",
			headers: { 'Content-Type': 'application/json' },
			body: JSON.stringify({
				"email": encodeURI(e.target.dataset.email),
				"code": formData.get("code"),
			})
		})
		.then(function(response) { if(response.ok) return response.json(); return Promise.reject(response); })
		.then(function(data) {
			if(data.status == 0)
			{
				location = "/password/" + data.token;
			}
			else
			{
				if(verifyEmailError) verifyEmailError.textContent = data.error
				verifyEmailForm.working = false
			}
		});
		e.preventDefault();
	})
}*/

/*let passwordForm = document.getElementById('password-form')
let passwordError = document.getElementById('password-error')
if(passwordForm)
{
	passwordForm.working = false
	passwordForm.addEventListener("submit", (e) => {
		const formData = new FormData(e.target)

		if(passwordForm.working) return

		passwordForm.working = true

		fetch("/auth/setpassword", {
			method: "post",
			headers: { 'Content-Type': 'application/json' },
			body: JSON.stringify({
				"password": encodeURI(formData.get("password")),
				"token": e.target.dataset.token,
			})
		})
		.then(function(response) { if(response.ok) return response.json(); return Promise.reject(response); })
		.then(function(data) {
			if(data.status == 0)
			{
				Cookies.set('pts_token', data.token_session, { expires: 365 })
				location = "/"
			}
			else
			{
				if(passwordError) passwordError.textContent = data.error
				passwordForm.working = false
			}
		});

		e.preventDefault();
	})
}*/


/*let forgotForm = document.getElementById('forgot-form')
let forgotError = document.getElementById('forgot-error')
if(forgotForm)
{
	forgotForm.working = false
	forgotForm.addEventListener("submit", (e) => {
		const formData = new FormData(e.target)

		if(forgotForm.working) return

		forgotForm.working = true

		fetch("/auth/reset", {
			method: "post",
			headers: { 'Accept': 'application/json', 'Content-Type': 'application/json' },
			body: JSON.stringify({
				"email": encodeURI(formData.get("email")),
			})
		})
		.then(function(response) { if(response.ok) return response.json(); return Promise.reject(response); })
		.then(function(data) {
			if(data.status == 0)
			{
				location = "/verifycode/" + formData.get("email")
			}
			else
			{
				if(forgotError) forgotError.textContent = data.error
				forgotForm.working = false
			}
		});

		e.preventDefault();
	})
}*/

/*let verifyCodeForm = document.getElementById('verify-code-form')
let verifyCodeError = document.getElementById('verify-code-error')
if(verifyCodeForm)
{
	verifyCodeForm.working = false
	verifyCodeForm.addEventListener("submit", (e) => {
		const formData = new FormData(e.target)

		if(verifyCodeForm.working) return

		verifyCodeForm.working = true

		fetch("/auth/verifyreset", {
			method: "post",
			headers: { 'Content-Type': 'application/json' },
			body: JSON.stringify({
				"email": encodeURI(e.target.dataset.email),
				"code": formData.get("code"),
			})
		})
		.then(function(response) { if(response.ok) return response.json(); return Promise.reject(response); })
		.then(function(data) {
			if(data.status == 0)
			{
				location = "/password/" + data.token;
			}
			else
			{
				if(verifyCodeError) verifyCodeError.textContent = data.error
				verifyCodeForm.working = false
			}
		});
		e.preventDefault();
	})
}
*/