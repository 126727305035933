
export function serverAPI()
{
	let api_server = "https://admin.protriathletes.org" //'http://localhost:1240'
	if(location.origin.startsWith("http://192.168.7.")) api_server = 'http://192.168.7.175:1237'
	//if(location.origin == "ionic://localhost") api_server = 'http://192.168.7.175:1237'
	if(location.origin == "http://localhost:1240") api_server = "http://localhost:1237"
	if(location.origin == "http://localhost:1237") api_server = "http://localhost:1237"
	if(location.origin == "http://localhost:1234") api_server = "http://localhost:1237"

	// Local
	if(location.origin == "https://pto-cms.local.designbysweet.com") api_server = "https://pto-cms.local.designbysweet.com"

	// WAS Cluster
	// Dev
	if(location.origin == "https://pto-cms-api.dev.designbysweet.com") api_server = "https://pto-cms-api.dev.designbysweet.com"
	// Test
	if(location.origin == "https://pto-cms-api.test.designbysweet.com") api_server = "https://pto-cms-api.test.designbysweet.com"

	// PTO Cluster
	// Dev
	if(location.origin == "https://cms.dev.protriathletes.org") api_server = "https://cms.dev.protriathletes.org"
	// Test
	if(location.origin == "https://cms.test.protriathletes.org") api_server = "https://cms.test.protriathletes.org"
	// Live
	if(location.origin == "https://cms.live.protriathletes.org") api_server = "https://cms.live.protriathletes.org"

	return api_server
}

export function websocketsServerAPI()
{
	let api_server = "wss://admin.protriathletes.org" //'http://localhost:1240'
	if(location.origin.startsWith("http://192.168.7.")) api_server = 'ws://192.168.7.175:1237'
	//if(location.origin == "ionic://localhost") api_server = 'ws://192.168.7.175:1237'
	if(location.origin == "http://localhost:1240") api_server = "ws://localhost:1237"
	if(location.origin == "http://localhost:1237") api_server = "ws://localhost:1237"
	if(location.origin == "http://localhost:1234") api_server = "ws://localhost:1237"

	// LOCAL HTTPS
	if(location.origin == "https://pto-cms.local.designbysweet.com") api_server = "ws://pto-cms.local.designbysweet.com"

	// DEV HTTPS
	if(location.origin == "https://pto-cms-api.dev.designbysweet.com") api_server = "ws://pto-cms-api.dev.designbysweet.com"

	// TEST HTTPS
	if(location.origin == "https://pto-cms-api.test.designbysweet.com") api_server = "ws://pto-cms-api.test.designbysweet.com"

	return api_server
}

function handleErrors(response) {
   if (!response.ok) {
      return response.text().then((text) => { throw Error(text) });
  }
  return response;
}


export function fetchAPI(url, options)
{
	let api_server = serverAPI()

	let pto_token = localStorage.getItem('ptotoken')
	if(!options) options = { headers : { "ptotoken": pto_token }}
	else
	{
		if(!options.headers) options.headers = { "ptotoken": pto_token }
		else options.headers["ptotoken"] = pto_token
	}

	return fetch(api_server + url, options).then(handleErrors)
}
