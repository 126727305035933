import { h, render, Component, createRef, Fragment } from 'preact';
import "./tags.scss"

export class Tags extends Component {
	currentSuggestions = [];
	input_ref = null

	state = {
		tags: []
	}

	inputRef = (r) => {
		this.input_ref = r
		if(r && this.props.autofocus) {
			this.setState({ update: true }, () => {r.focus()})
		}
	}

	handleInputEvent = e => {
		let v = e.target.value;
		this.setState({ inputValue: v, isOpen: (v.length >= 2) });
	}

	handleDocClick = e => {
		const path = e.path || (e.composedPath && e.composedPath());
		if(!path.find(x => x == this.input_ref))
		{
			this.setState({ isOpen: false });
		}
	}

	handleFocusEvent = e => { }
	/*handleBlurEvent = e => { 
		console.log('blur', e.target)
		this.setState({ isOpen: false, inputValue: '' });
	}*/

	tagsUpdated()
	{
		if(this.props.onChange) {
			const { tags } = this.state
			const updatedTags = tags.join(",")
			this.props.onChange(this.setState({ tags: updatedTags || [] }), this)
		}
	}

	addTag(tag)
	{
		let id = tag.id		
		if(this.props.mode == "select")
		{
			if(this.props.onChange) this.props.onChange(id, this)
		}
		else if(this.props.mode == "unique")
		{
			if(this.props.onChange) this.props.onChange(id, this)
			this.setState({ tags: [id] })
		}
		else
		{
			if(!this.state.tags.find(x => x == id)) {
				const { tags } = this.state
				const updatedTags = tags.push(id)
				this.setState({ tags: updatedTags })
			}

			this.tagsUpdated()
		}
		this.setState({ inputValue: '', isOpen: false, selectedSuggestion: 0 });
	}

	handleSuggestionClick = e => {
		console.log(e.target.dataset.index, this.currentSuggestions[e.target.dataset.index])
		this.addTag(this.currentSuggestions[e.target.dataset.index])
	}

	handleKeypress = e => {
		if(!this.state.isOpen) return;
		if(!this.currentSuggestions || this.currentSuggestions.length == 0) return;
		
		switch(e.keyCode) {
			case 27: {
				this.setState({ isOpen: false, inputValue: '',  selectedSuggestion: 0 });
				break;
			}
			case 13: {
				this.addTag(this.currentSuggestions[this.state.selectedSuggestion])
				break;
			}
			case 40: {
				let newSelection = (this.state.selectedSuggestion + 1);
				this.setState({ selectedSuggestion: (newSelection >= this.currentSuggestions.length) ? 0 : newSelection });
				break;
			}
			case 38: {
				let newSelection = (this.state.selectedSuggestion - 1);
				this.setState({ selectedSuggestion: (newSelection < 0) ?  (this.currentSuggestions.length-1) : newSelection });
				break;
			}
		}
	}

	handleClickDeleteTag = e => {
		let id = parseInt(e.target.dataset.id)
		const tags = this.state.tags
		const updatedTags = tags.filter(x => x != id)
		this.setState({ isOpen: false, tags: updatedTags || [] }, () => {
			console.log(this.state.tags)
			this.tagsUpdated()
		});
	}

	handleInitialValue() {
		if(this.props.value) {
			let tags = []
			
			if(typeof this.props.value == "string") {
				tags = this.props.value.split(",")
			}
			else if(typeof this.props.value == "number") {
				tags = [this.props.value]
			}
			else {
				tags = this.props.value
			}
			for(let tag of tags) {
				let f = this.props.options.find(x => x.id == tag)
				if(f && this.state.tags.length === 0) {
					const updatedTags = [...this.state.tags, tag]
					this.setState({ tags: updatedTags })
				}
			}
		}
	}

	componentDidUpdate(prevProps) {
		if (this.props.value === null && this.state.tags.length > 0) {
			this.setState({ tags: [] })
		}

		if (prevProps.options?.[0] !== this.props.options?.[0]) {
			this.handleInitialValue()
		}
	}

	componentDidMount() {
		this.handleInitialValue()
		this.setState({ isOpen: false });
		document.addEventListener('click', this.handleDocClick, { capture: false });
	}

	componentWillUnmount() {
		document.removeEventListener('click', this.handleDocClick);
	}


	render(props, {inputValue, isOpen, selectedSuggestion}) {
		let mergedClassName = 'autocomplete';
		if(props.className) mergedClassName += props.className;    

		this.currentSuggestions = props.options.filter(s => s.name.toLowerCase().includes(this.state.inputValue.toLowerCase()));

		const autoCompleteSuggestionsClassName = 'form-control form-control-sm autocomplete-results ' + (isOpen ? 'open' : 'closed');
		
		const autoCompleteSuggestionsStyle = { display: (isOpen ? 'block' : 'none') };

		return (
			<div class='tags'>
				{ (props.mode != "unique" || this.state.tags.length == 0) &&
					<div className={mergedClassName}>
							<input //{...props} 
								placeholder={this.props.placeholder}
								ref={this.inputRef}
								class='form-control form-control-sm'
								value={inputValue} 
								type="text" 
								autocomplete="off" 
								onInput={this.handleInputEvent} 
								onKeyDown={this.handleKeypress}
								onBlur={this.handleBlurEvent}
								onFocus={this.handleFocusEvent}
							/>
							<div className={autoCompleteSuggestionsClassName} style={autoCompleteSuggestionsStyle}>
								{this.currentSuggestions.map((choice, i) => {
									const mergedResultClassName = 'autocomplete-result ' + (i === selectedSuggestion ? 'active' : '')
									return (
										<div onClick={this.handleSuggestionClick} data-index={i} className={mergedResultClassName}>
											{choice.name}
										</div>
									)
								})
								}
							</div>
					</div>
				}
				<div class='tag-list'>
					{ this.state.tags.map(x => {
						let f = props.options.find(o => o.id == x)
						if(!f) return
						return(<div class='tag'>{f.name}<span class='delete' data-id={f.id} onClick={this.handleClickDeleteTag.bind(this)}></span></div>)
					})}
				</div>
			</div>
		);
	}

	state = {
		inputValue: '',
		isOpen: false,
		selectedSuggestion: 0,
		tags: []
	}
}
