import { h, Component, createRef } from "preact"
import { fetchAPI } from "../../../libs/api"
import t100Logo from "../../../assets/t100-logo.png"
import AddContractTier from "./dialogs/addContractTier"
import "./contractTiers.scss"

export class T100ContractTiers extends Component {
    state = {
      filter: sessionStorage.getItem("contract-tiers-filter"),
      filteredContractTiers: [],
      contractTiers: [],
    }

    dialogAddContractTier = createRef()

    filter(event) {
      const filter = event.target.value	
      sessionStorage.setItem("contract-tiers-filter", filter)
      const { contractTiers } = this.state
      let filteredContractTiers

      if (filter && filter != "") {
          let tokens = filter.toLowerCase().split(" ")
          filteredContractTiers = contractTiers.filter(contractTier => {
              let out = false
              for (let token of tokens) {
                  if (contractTier.search.indexOf(token) == -1) {
                      out = true
                      break
                  }
              }
              return !out
          })
      } else {
			filteredContractTiers = contractTiers
      }
      this.setState({ filter: filter, filteredContractTiers: filteredContractTiers.slice(0, 200) })
    }

    fetchContractTiers() {
      fetchAPI("/api/t100/contract-tiers/", { headers: { 'Content-Type': 'application/json' }})
          .then(response => response.json())
          .then(data => this.setState({ contractTiers: data.contractTiers, filteredContractTiers: data.contractTiers }))
          .catch(error => alert(error.message))
      }

    componentDidMount() {
       this.fetchContractTiers()
    }

    render() {
        return (
            <div class="contract-tiers">
                <h2 class="headline">
						<img src={t100Logo} alt="T100 Logo" />
						Contract Tiers
					 </h2>

                <div class='card'>
                    <div class='d-flex align-items-center'>
                        <div>Filter:</div>
                        <input class='form-control form-control-sm ml-2' type='text' onInput={this.filter.bind(this)} value={this.state.filter}/>
                        <button class='btn btn-sm btn-secondary ml-3 flex-shrink-0' onClick={() => this.dialogAddContractTier.current.show()}>Add a new contract tier</button>
                    </div>
                </div>

                <div class="card">
                    <table class="contract-tiers-table table">
                        <tr>
                            {["ID", "Name", "Order", "Archived?"].map((columnHeader) => (
                                <th>{columnHeader}</th>
                            ))}
                        </tr>

                        {this.state?.filteredContractTiers.map((contractTier) => {
                            return (
                                <tr>
                                    <td>
                                        <a href={"/t100/contract-tier/" + contractTier.id}>{contractTier.id}</a>
                                    </td>
                                    <td>
                                        <a href={"/t100/contract-tier/" + contractTier.id}>{contractTier.name}</a>
                                    </td>
												<td>{contractTier.order || "-"}</td>
												<td>{contractTier.is_active ? 'No' : 'Yes'}</td>
                                </tr>
                            )
                        })}
                    </table>
                </div>

                <AddContractTier reference={this.dialogAddContractTier} fetchContractTiers={this.fetchContractTiers.bind(this)}/>
            </div>
        )
    }
}
