
let oxModals = document.getElementsByClassName("ox-modal")
if(oxModals)
{
	for(let m of oxModals)
	{
		let closes = m.getElementsByClassName("close-modal")
		for(let c of closes)
		{
			c._modal = m
			c.addEventListener("click", function(e) {
				this._modal.modal('hide')
			})
		}

		let yeses = m.getElementsByClassName("yes-modal")
		for(let y of yeses)
		{
			y._modal = m
			y.addEventListener("click", function(e) {
				if(this._modal && this._modal.options && this._modal.options.onYes) this._modal.options.onYes()
				this._modal.modal('hide')
			})
		}
		
		m.modal = function(a) {
			if(typeof a === "string")
			{
				if(a == 'show')
				{
					this.classList.add("show")
					document.body.classList.add("noscroll")
				}				
				else if(a == 'hide')
				{
					this.classList.remove("show")
					document.body.classList.remove("noscroll")
				}				
			}
			else if(typeof a === "object")
			{
				let options = a
				m.options = options
			}
		}
	}
}

