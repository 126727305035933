import { h, Component, createRef } from "preact";
import { fetchAPI } from "../../libs/api";
import AddTier from "./dialogs/addTier";
import "./tiers.scss";

export class Tiers extends Component {
    state = {
      filter: sessionStorage.getItem("tiers-filter"),
      filteredTiers: [],
      tiers: [],
    }

    dialogAddTier = createRef();

    filterTiers(event) {
      const filter = event.target.value;
      sessionStorage.setItem("tiers-filter", filter);
      const { tiers } = this.state;
      let filteredTiers;

      if (filter && filter != "") {
          let tokens = filter.toLowerCase().split(" ");
          filteredTiers = tiers.filter(tier => {
              let out = false;
              for (let token of tokens) {
                  if (tier.search.indexOf(token) == -1) {
                      out = true;
                      break;
                  }
              }
              return !out
          })
      } else {
          filteredTiers = tiers;
      }
      this.setState({ filter: filter, filteredTiers: filteredTiers.slice(0, 200) })
    }

    refreshTiers() {
      fetchAPI("/api/tiers/", { headers: { 'Content-Type': 'application/json' }})
          .then(response => response.json())
          .then(data => this.setState({ tiers: data.tiers, filteredTiers: data.tiers }))
          .catch(error => alert(error.message));
      }

    componentDidMount() {
       this.refreshTiers();
    }

    render(props, state) {
        return (
            <div class="tiers">
                <h2 class="headline">TIERS</h2>

                <div class='card'>
                    <div class='d-flex align-items-center'>
                        <div>Filter:</div>
                        <input class='form-control form-control-sm ml-2' type='text' onInput={this.filterTiers.bind(this)} value={this.state.filter}/>
                        <button class='btn btn-sm btn-secondary ml-3 flex-shrink-0' onClick={() => this.dialogAddTier.current.show()}>Add a new Tier</button>
                    </div>
                </div>

                <div class="card">
                    <table class="tiers-table table">
                        <tr>
                            {["ID", "Name", "Description", "Base Points", "Dropoff Percentage", "Published?"].map((columnHeader) => (
                                <th>{columnHeader}</th>
                            ))}
                        </tr>
                        {this.state?.filteredTiers.map((tier) => {
                            return (
                                <tr>
                                    <td>
                                        <a href={"/tier/" + tier.id}>{tier.id}</a>
                                    </td>
                                    <td>
                                        <a href={"/tier/" + tier.id}>{tier.name}</a>
                                    </td>
                                    <td>{tier.description || "-"}</td>
                                    <td>{tier.base_points}</td>
                                    <td>{tier.dropoff_percentage}%</td>
                                    <td>{tier.is_active ? 'No' : 'Yes'}</td>
                                </tr>
                            );
                        })}
                    </table>
                </div>

                <AddTier reference={this.dialogAddTier} refreshTiers={this.refreshTiers.bind(this)}/>
            </div>
        );
    }
}
