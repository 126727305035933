let tables = document.getElementsByClassName("table")
if(tables)
{
	for(let t of tables)
	{
		let sorts = t.getElementsByClassName("table-sort")
		for(let s of sorts)
		{
			s._table = t
			s.addEventListener("click", function(e) {
				console.log(this.cellIndex, this._table)
				let table = this._table
				let rows = table.children[0].children
				let allRows = []
				while(rows.length != 1)
				{
					let _row = rows[1].parentElement.removeChild(rows[1]);
					allRows.push(_row)
				}

				let cellIndex = this.cellIndex
				if(table.sortIndex == cellIndex)
				{
					cellIndex = parseInt(table.dataset.sortdefault)
					table.sortIndex = undefined
				}
				else 
				{
					table.sortIndex = cellIndex	
				}
				
				var sortedRows = allRows.sort(function(a,b) {
					return parseInt(a.children[cellIndex].dataset.sort) - parseInt(b.children[cellIndex].dataset.sort);
				});

				for(let r of sortedRows)
				{
					table.children[0].appendChild(r)
				}

			})
		}
	}
}
