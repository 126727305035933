import { h, Component, createRef, Fragment } from 'preact';
import "./brands.scss"
import { fetchAPI } from '../libs/api';


export class Brands extends Component {
	brands = null
	state = { brands: [], filtered_brands: [], filter: '' }
	dialogAddBrand = createRef()
	inputAddBrand = createRef()

	componentDidMount()
	{
		let that = this

		fetchAPI("/api/brands", {
			headers: { 'Content-Type': 'application/json' },
		})
		.then(function(response) { if(response.ok) return response.json(); return Promise.reject(response); })
		.then(function(data) {
			that.processBrands(data.brands)
			that.filterBrands(null)
		})
	}

	processBrands(brands) {
		for(let b of brands)
		{
			b.search = (b.name).toLowerCase() 
		}
		this.brands = brands
	}

	filterBrands(filter)
	{
		let brands = this.brands
		if(filter && filter != "")
		{
			let tokens = filter.toLowerCase().split(" ")
			brands = this.brands.filter(x => {
				let out = false
				for(let t of tokens)
				{
					if(x.search.indexOf(t) == -1)
					{
						out = true
						break
					}
				}
				return !out
			})
		}
		this.setState({filtered_brands: brands })
	}

	filterOnInput = e => {
		const { value } = e.target
		this.setState({ filter: value })
		if(this.filter_timeout) clearTimeout(this.filter_timeout)
		this.filter_timeout = setTimeout((that) => { 
			that.filterBrands(that.state.filter, that.brands)
			that.filter_timeout = null; 
		}, 250, this);		
	}


	onAddBrand = e => {
		let that = this
		let brand = this.inputAddBrand.current.value
		fetchAPI("/api/brands/add", {
			method: "post",
			headers: { 'Content-Type': 'application/json' },
			body: JSON.stringify({ name: brand })
		})
		.then(function(response) { if(response.ok) return response.json(); return Promise.reject(response); })
		.then(function(data) {
			that.processBrands(data.brands)
			that.filterBrands(brand)
		})
	}

	render(props, state) {
		let brands = state.filtered_brands
		return(
			<div class='brands'>
				<h2 class='headline'>BRANDS</h2>
				{this.brands && 
				<Fragment>
					<div class='card'>
						<div class='searchadd'>
							<div>Search or Add:</div>
							<input type='text' value={state.filter} class='form-control form-control-sm ml-2' ref={this.inputAddBrand} onInput={this.filterOnInput}/>
							{
								brands.length == 0 && 
								<button class='btn btm-sm btn-primary ml-2' onClick={this.onAddBrand}>Add Brand</button>
							}
						</div>

					</div>
					<div class='card'>
						<table class='brands-table table'>
							<tr>
								{["ID", "Brand", "URL"].map((columnHeader) => (
									<th>{columnHeader}</th>
								))}
							</tr>

							{ brands.map(brand => {
								return (
									<tr>
										<td>
											<a href={"/brand/" + brand.id}>{brand.id}</a>
										</td>

										<td>
											<div class='mr-5'>
												<a href={"/brand/" + brand.id}>{brand.name}</a>
											</div>
										</td>

										<td>
											<a href={brand.url} target='_blank'>{brand.url}</a>
										</td>
									</tr>
								)
							})}
						</table>
					</div>
				</Fragment>}
			</div>
		)
	}

}
