import { h, render, Component, createRef, Fragment } from 'preact';
import { fetchAPI, serverAPI, websocketsServerAPI } from '../libs/api';
import { Dialog } from "./dialog"
import "./athletes.scss"
import moment from 'moment';

let internationals = ["CA", "AU", "NZ", "ZA", "BM", "BR", "AR", "MX", "CL"]

export class Athletes extends Component {
	state = { athletes: [], profiles: [], attributes: [], filtered_athletes: [], filter: null }
	dialogAddAthlete = createRef()

	componentDidMount()
	{
		let that = this

		fetchAPI("/api/athletes/", {
			headers: { 'Content-Type': 'application/json' },
		})
		.then(function(response) { if(response.ok) return response.json(); return Promise.reject(response); })
		.then(function(data) {
			console.log(data)
			let teams = ["EUR", "USA", "INT"]
			let regional_rankings = {
				"FPRO": [1,1,1],
				"MPRO": [1,1,1]
			}
			for(let a of data.athletes)
			{
				a.search = (a.name + " " + a.division + " " + a.country_3).toLowerCase() 
				let teamIndex = a.country == "US" ? 1 : (internationals.indexOf(a.country) == -1 ? 0 : 2)
				
				a.team = teams[teamIndex]
				a.team_rank = regional_rankings[a.division][teamIndex]

				regional_rankings[a.division][teamIndex] += 1
			}
			that.setState({ athletes: data.athletes, profiles: data.profiles, attributes: data.attributes, nations: data.nations })
			that.filterAthletes(null, data.athletes)
		})
	}
	
	filterOnInput = e => {
		const { value } = e.target
		this.setState({ filter: value })
		if(this.filter_timeout) clearTimeout(this.filter_timeout)
		this.filter_timeout = setTimeout((that) => { 
			that.filterAthletes(that.state.filter, that.state.athletes)
			that.filter_timeout = null; 
		}, 250, this);		
	}

	filterAthletes(filter, athletes)
	{
		if(filter && filter != "")
		{
			if(filter == "pic")
			{
				athletes = athletes.filter(x => {
					let profile = this.state.profiles.find(y => y.athlete_id == x.id)
					if(!profile) return false
					let pic_sub = this.state.attributes.find(y => y.item_id == x.id && y.name == "pic-submission")
					if(!pic_sub) return false

					return true
				})

			}
			else
			{
				let tokens = filter.toLowerCase().split(" ")
				athletes = athletes.filter(x => {
					let out = false
					for(let t of tokens)
					{
						if(x.search.indexOf(t) == -1)
						{
							out = true
							break
						}
					}
					return !out
				})
			}
		}
		this.setState({filtered_athletes: athletes.slice(0, 1000) })
	}

	export = () => {
		let csvContent = "data:text/csv;charset=utf-8,"
		let csvFile = 'ID;Name;Country;Email;Rank;Points;Team Rank;Division;Weight;Height;Pic\n'
		for(let aa of this.state.filtered_athletes)
		{
			let profile = this.state.profiles.find(y => y.athlete_id == aa.id)
			let pic = this.state.attributes.find(y => y.item_id == aa.id && y.name == "pic")
			csvFile += [aa.id, aa.name, aa.country, profile && profile.email, aa.overall_rank, aa.overall_points, aa.team + " " + aa.team_rank, aa.division, profile && profile.weight, profile && profile.height, pic ? "Y" : "N"].join(";") + "\n";
		}
		var blob = new Blob([csvFile], { type: 'text/csv;charset=utf-8;' });

		var link = document.createElement("a");
		var url = URL.createObjectURL(blob);
		link.setAttribute("href", url);
		link.setAttribute("download", "pto-athletes-" + moment().format("YYYY-MM-DD-HH-mm-ss") + ".csv");
		link.style.visibility = 'hidden';
		document.body.appendChild(link);
		link.click();
		document.body.removeChild(link);
	}

	onAddAthlete = e => {
		this.dialogAddAthlete.current.show()
	}

	addAthleteFirst = createRef()
	addAthleteLast = createRef()
	addAthleteCountry = createRef()
	addAthleteDivision = createRef()
	addAthleteEmail = createRef()

	dialogAddAthleteYes = e => {
		let that = this
		let first = this.addAthleteFirst.current.value
		let last = this.addAthleteLast.current.value
		let country = this.addAthleteCountry.current.value
		let division = this.addAthleteDivision.current.value
		let email = this.addAthleteEmail.current.value

		fetchAPI("/api/athlete/create", {
			method: "post",
			headers: { 'Content-Type': 'application/json' },
			body: JSON.stringify({ first: first, last: last, country: country, division: division, email: email })
		})
		.then(function(response) { if(response.ok) return response.json(); return Promise.reject(response); })
		.then(function(data) { 
			if(data.status == 0)
			{
				that.addAthleteFirst.current.value = ""
				that.addAthleteLast.current.value = ""
				that.addAthleteEmail.current.value = ""
			}
			else if(data.status == -1)
			{
				window.alert("This athlete already exists.")
			}
			else
			{
				window.alert("Failed adding athlete.")
			}
			//that.refreshRaceList()
		})
		//console.log(serie, date, country, city)
	}


	render(props, state) {
		let athletes = state.filtered_athletes
		return(<div class='athletes'>
			<h2 class='headline'>ATHLETES <i class="fas fa-plus-circle add_athlete" onClick={this.onAddAthlete}></i></h2>
			<div class='card'>
				<div class='d-flex align-items-center'> 
					<div>Filter:</div> 
					<input class='form-control form-control-sm ml-2' type='text' onInput={this.filterOnInput} placeholder='Search... (type "pic" for submitted pictures)'/>
					<button class='btn btn-sm btn-secondary ml-2 flex-shrink-0' onClick={this.export}>Export CSV</button>
				</div>
			</div>
			<div class='card'>
				<table class='athletes-table'>
					<tr>{["ID", "Name", "Rank", "Team", "Points", "Div.", "Weight", "Height", "Pic"].map(x => <th>{x}</th>)}</tr>
					{ athletes.map(x => {
						let profile = state.profiles.find(y => y.athlete_id == x.id)
						let pic = state.attributes.find(y => y.item_id == x.id && y.name == "pic")
						let pic_sub = state.attributes.find(y => y.item_id == x.id && y.name == "pic-submission")
						return(
							<tr>
								<td>{x.id}</td>
								<td><div class={'flag-icon flag-icon-' + x.country.toLowerCase()}></div> <a href={'/athlete/' + x.id}>{x.name}</a></td>
								<td>{x.overall_rank}</td>
								<td>{x.team} {x.team_rank}</td>
								<td>{x.overall_points.toFixed(2)}</td>
								<td>{x.division}</td>
								<td>{profile && profile.weight}</td>
								<td>{profile && profile.height}</td>
								<td>{pic && <i class="fas fa-check"></i>}</td>
								<td>{pic_sub && <i class="fas fa-portrait"></i>}</td>
							</tr>
						)
					})}
				</table>
			</div>
			<Dialog yesText="Create New Athlete" title="Add a new Athlete" ref={this.dialogAddAthlete} onYes={this.dialogAddAthleteYes}>
				<table class='table-add-athlete'>
					<tr>
						<td>First</td>
						<td><input class="form-control form-control-sm" value="" ref={this.addAthleteFirst}/></td>
					</tr>
					<tr>
						<td>Last</td>
						<td><input class="form-control form-control-sm" value="" ref={this.addAthleteLast}/></td>
					</tr>
					<tr>
						<td>Country</td>
						<td>
							<select class="form-control form-control-sm"  ref={this.addAthleteCountry}>
								{this.state.nations && this.state.nations.map(x => {
									return (
										<option value={x.alpha_2}>{x.name}</option>
									)
								})}
							</select>
						</td>
					</tr>
					<tr>
						<td>Division</td>
						<td>
							<select class="form-control form-control-sm"  ref={this.addAthleteDivision}>
								<option value='MPRO'>MPRO</option>
								<option value='FPRO'>FPRO</option>
							</select>
						</td>
					</tr>
					<tr>
						<td>Email</td>
						<td><input class="form-control form-control-sm" value="" ref={this.addAthleteEmail}/></td>
					</tr>
				</table>

			</Dialog>
		</div>)
	}
}


