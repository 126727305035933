import { h, Component, Fragment } from "preact";
import { fetchAPI } from '../libs/api';

export class Topbar extends Component {
	state = { results: null, search: '' }
	searchTimer = null

	doc_mouseup = e => {
		let withinSearch = e.target.closest(".search")
		if(!withinSearch) 
		{
			this.setState({ search: null, results: null, search: '' })
		}
	}

	doc_keydown = e => {
		
	}

	componentDidMount()
	{
		document.addEventListener("mouseup", this.doc_mouseup)
		document.addEventListener("keydown", this.doc_keydown)

	}

	componentWillUnmount()
	{
		document.removeEventListener("mouseup", this.doc_mouseup)
		document.removeEventListener("keydown", this.doc_keydown)
	}

	go = e => {
		this.setState({ results: null, search: '' })
	}
	input = e => {
		if(this.searchTimer != null) clearTimeout(this.searchTimer)

		this.setState({ search: e.target.value })
		this.searchTimer = setTimeout(function(terms, that) {
			that.searchTimer = null
			if(terms == undefined || terms.trim() == "") {
				that.setState({ results: null })
				//searchResults.classList.add("hide")
				return
			} 
			fetchAPI("/api/search/" + terms, {
				headers: { 'Content-Type': 'application/json' },
			})
			.then(function(response) { if(response.ok) return response.json(); return Promise.reject(response); })
			.then(function(data_search) {
				that.setState({ results: data_search.results })
			})
		}, 250, e.target.value, this)
	}

	render(props, state)
	{
		let user = props.user //session && session.user
		return(
			<Fragment>
				<div class='topbar'>
					<div class='float-left d-flex align-items-center'>
						<Fragment>
							<a href="/"><img class='world-rankings-logo' src='/assets/pto-logo.svg'/> <span class='headline ml-2'>Admin</span></a>
							<div class='search ml-2'>
								<input class='form-control form-control-sm' type='text' placeholder="Search..." autocomplete="off" id='search-input' inputmode="search" onInput={this.input} value={state.search}/>
								<div id='search-results' class='hide-if-empty py-2'>
									{state.results && state.results.map(x => { return (
										<a href={x.url} onClick={this.go}>{x.name}</a>
									)})}
								</div>
							</div>
						</Fragment>
					</div>
					<div class='menu'>
					</div>
					<div class='float-right d-flex align-items-center'>
						<a class='btn btn-outline-secondary btn-sm' id='login-button' href={user ? "/settings" : "/login"}>{user ? user.first + " " + user.last : "Login"}</a>
						<div id='menu-toggle d-none'><i></i></div>
					</div>
				</div>
				<div id="side-menu">
					<div id="side-menu-background"></div>
					<div class='menu'>
					</div>
				</div>
			</Fragment>
		)		
	}	
}

