import { h, Component } from "preact";
import { fetchAPI } from "../../libs/api";
const _ = require('lodash');
import "./serie.scss";

// Components
import EditTab from "./tabs/editTab";
import RedirectsTab from "./tabs/redirectsTab";

export class Serie extends Component {
	state = {
		tab: "edit",
		serie: null,
		loading: true,
	  	tabs: [
			{ tab: "edit", name: "Edit" }, 
			{ tab: "redirects", name: "Redirects" }
	  	]
	};

	refreshTab() {
		if (location.hash) {
			let tab = location.hash.substring(1);
			const { tabs } = this.state;
			tabs.find(x => x.tab == tab) && this.setState({ tab: tab });	
		}
	}

	refreshTabs() {
		this.setState({
			tabs: this.state.serie?.redirects
			? [
				{ tab: "edit", name: "Edit" },
				{ tab: "redirects", name: "Redirects" },
			]
			: [{ tab: "edit", name: "Edit" }]
		})
	}

	async refreshSerie() {
		return fetchAPI(`/api/serie/${this.props.alias}`, { headers: { 'Content-Type': 'application/json' }})
			.then(response => response.json())
			.then(data => this.setState({ serie: data.serie, loading: false }))
			.then(() => this.refreshTabs())
			.catch(err => this.setState({ error: err }));
	}

	changeTab(event) {
		location.hash = `#${event.target.dataset.tab}`
		this.setState({ tab: event.target.dataset.tab})
	}

	componentDidMount() {
		this.refreshTab();
		this.refreshSerie()
	}

	render(props, state) {
		const { serie, loading, tabs, error } = state;
		if (error) return <p>{error.message}</p>
		return !loading && (
			<div class="serie-info">
				<h2 class="headline">{serie.name}</h2>

				<div class="tabs">
					{tabs.map((tab) => {
						return (
							<div class="tab" selected={tab.tab == state.tab} data-tab={tab.tab} onClick={this.changeTab.bind(this)}>
								{tab.name}
							</div>
						);
					})}
				</div>

				{state.tab === "edit" && <EditTab serie={serie} />}
				{state.tab === "redirects" && <RedirectsTab serie={serie} />}
			</div>
		);
	}
}

