import { h, render, Component, createRef, Fragment } from 'preact';
import { route } from 'preact-router';
import moment from 'moment';
import { Dialog } from "./dialog"
import { PickDate, PleaseWait } from "./utils";
import "./rankings.scss"
import { fetchAPI, serverAPI, websocketsServerAPI } from '../libs/api';

export class Rankings extends Component {
	state = {
		rankings: []
	}
	dialogAddRankings = createRef()
	addRankingsDate = createRef()

	componentDidMount()
	{
		this.refreshRankingsList()
	}

	refreshRankingsList()
	{
		let that = this

		fetchAPI("/api/rankings", {
			headers: { 'Content-Type': 'application/json' },
		})
			.then(function(response) { if(response.ok) return response.json(); return Promise.reject(response); })
			.then(function(data) {
				that.setState({ rankings: data.rankings })
			})
	}

	dialogAddRankingsYes = e => {
		let that = this
		let date = this.addRankingsDate.current.value

		fetchAPI("/api/ranking/create", {
			method: "post",
			headers: { 'Content-Type': 'application/json' },
			body: JSON.stringify({ date: date })
		})
			.then(function(response) { if(response.ok) return response.json(); return Promise.reject(response); })
			.then(function(data) {
				that.refreshRankingsList()
			})
	}

	onAddNewRankings = e => {
		this.dialogAddRankings.current.show()
	}


	render(props, state) {
		let rankings = state.rankings

		return(<div class='races'>
			<div class='d-flex justify-content-between mb-2'>
				<h2 class='headline mb-0'>RANKINGS</h2>
				<button class='btn btn-sm btn-secondary ml-4 flex-shrink-0' onClick={this.onAddNewRankings}>Add a new Rankings</button>
			</div>
			<div class='card'>
				<table class='rankings-table'>
					<tr>{["ID", "Date", "Published"].map(x => <th>{x}</th>)}</tr>
					{ rankings.map(x => {
						return(
							<tr>
								<td>{x.id}</td>
								<td><a href={'/ranking/' + x.id}>{moment.utc(x.date).format("DD/MM/YYYY")}</a></td>
								<td>{x.published ? "PUBLISHED" : "DRAFT"}</td>
							</tr>
						)
					})}
				</table>
			</div>

			<Dialog yesText="Create Rankings" title="Create a New Rankings" ref={this.dialogAddRankings} onYes={this.dialogAddRankingsYes}>
				<table>
					<tr>
						<td>Date</td>
						<td><PickDate class="form-control form-control-sm" _type="date" ref={this.addRankingsDate}/></td>
					</tr>
				</table>
			</Dialog>
		</div>)
	}
}

export class Ranking extends Component {
	state = {
		rankings_men: [],
		rankings_women: [],
		cmp_rankings_men: [],
		cmp_rankings_women: [],
		discipline: "overall",
		please_wait: false,
		error_message: ''
	}

	deleteConfirmDialogRef = createRef()
	deleteErrorDialogRef = createRef()

	componentDidMount() {
		this.refreshRankingsList()
	}

	refreshRankingsList() {
		let that = this

		fetchAPI("/api/ranking/" + this.props.id, {
			headers: { 'Content-Type': 'application/json' },
		})
			.then(function(response) {
				if(response.ok) {
					return response.json();
				}

				return Promise.reject(response);
			})
			.then(function(data) {
				that.setState({
					rankings_men: data.rankings.filter(x => x.division === "MPRO"),
					rankings_women: data.rankings.filter(x => x.division !== "MPRO"),
					cmp_rankings_men: data.cmp_rankings.filter(x => x.division === "MPRO"),
					cmp_rankings_women: data.cmp_rankings.filter(x => x.division !== "MPRO"),
					set: data.set
				})
			})
	}

	onGenerateRanking = e => {
		this.setState({ please_wait: true })
		let that = this
		fetchAPI("/api/ranking/" + this.props.id + "/generate", {
			method: "post",
			headers: { 'Content-Type': 'application/json' },
		})
			.then(function(response) { if(response.ok) return response.json(); return Promise.reject(response); })
			.then(function(data) {
				if (that.state.set.published === 1) {
					that.calculateSofAfterUpdate()
				}
				that.refreshRankingsList()
				that.setState({ please_wait: false })
			})
	}

	onExportRanking = e => {

		this.setState({ please_wait: true })
		const discipline = this.state.discipline
		for(let div of ["men", "women"])
		{
			let csvContent = "data:text/csv;charset=utf-8,"
			let csvFile = 'Rank;Points;Score;First;Last;Email;Country;Division\n'
			for(let a of this.state["rankings_" + div])
			{
				let rank = 0
				let points = 0
				let score = 0
				if ( discipline === 'swim' ) {
					rank = a.swim_rank
					points = a.swim_points
					score = a.swim_score
				}
				else if ( discipline === 'bike' ) {
					rank = a.bike_rank
					points = a.bike_points
					score = a.bike_score
				}
				else if ( discipline === 'run' ) {
					rank = a.run_rank
					points = a.run_points
					score = a.run_score
				}
				else {
					rank = a.overall_rank
					points = a.overall_points
					score = a.overall_score
				}
				let splits = [rank, points, score, a.first, a.last, a.email, a.country_3, a.division]
				csvFile += splits.join(";") + "\n"
			}
			var blob = new Blob([csvFile], { type: 'text/csv;charset=utf-8;' });

			var link = document.createElement("a");
			var url = URL.createObjectURL(blob);
			link.setAttribute("href", url);
			link.setAttribute("download", "rankings-" + div + "-"  + discipline + "-" + moment.utc(this.state.set.date).format("YYYY-MM-DD") + ".csv");
			link.style.visibility = 'hidden';
			document.body.appendChild(link);
			link.click();
			document.body.removeChild(link);
		}
		this.setState({ please_wait: false })

	}

	onDeleteRankingSetRequest = e => {
		this.deleteConfirmDialogRef.current.show()
	}

	onDeleteRankingSetConfirmed = e => {
		this.setState({ please_wait: true })
		let that = this
		fetchAPI("/api/ranking/" + this.props.id + "/delete", {
			method: "post",
			headers: { 'Content-Type': 'application/json' },
		})
			.then(function(response) {
				if(response.ok) {
					return response.json();
				}

				return Promise.reject(response);
			})
			.then(function(data) {
				that.setState({ please_wait: false })
				if ( data.status === -1 ) {
					that.setState({
						error_message: data.message
					})
					that.deleteErrorDialogRef.current.show()
				}
				else {
					route('/rankings')
				}
			})
	}

	changeType = e => {
		let that = this
		fetchAPI("/api/ranking/" + this.props.id + "/type", {
			method: "post",
			headers: { 'Content-Type': 'application/json' },
			body: JSON.stringify({ type: e.target.value })
		})
			.then(function(response) { if(response.ok) return response.json(); return Promise.reject(response); })
			.then(function(data) {
				that.onGenerateRanking()
			})
		this.state.set.type = parseInt(e.target.value)
		this.setState({})
	}

	onPublish = e => {
		let that = this
		fetchAPI("/api/ranking/" + this.props.id + "/publish", {
			method: "post",
			headers: { 'Content-Type': 'application/json' },
		})
			.then(function(response) { if(response.ok) return response.json(); return Promise.reject(response); })
			.then(function(data) {
				that.calculateSofAfterUpdate()
			})
		this.state.set.published = 1
		this.setState({})
	}

	onUnpublish = e => {
		let that = this
		fetchAPI("/api/ranking/" + this.props.id + "/unpublish", {
			method: "post",
			headers: { 'Content-Type': 'application/json' },
		})
			.then(function(response) { if(response.ok) return response.json(); return Promise.reject(response); })
			.then(function(data) {
				that.calculateSofAfterUpdate()
			})
		this.state.set.published = 0
		this.setState({})
	}

	calculateSofAfterUpdate() {
		let that = this
		fetchAPI("/api/ranking/" + this.props.id + "/future_races", {
			method: "post",
			headers: { 'Content-Type': 'application/json' },
		})
			.then(function(response) {
				if (response.ok) {
					return response.json();
				}

				return Promise.reject(response);
			})
			.then(function(data) {
				for (const race of data.races) {
					that.calculateSof(race.id)
				}
			})
	}

	calculateSof(raceId) {
		fetchAPI("/api/race/" + raceId + "/calculate_sof", {
			method: "post",
			headers: { 'Content-Type': 'application/json' },
		})
			.then(function(response) {
				if (response.ok) {
					return response.json();
				}

				return Promise.reject(response);
			})
	}

	render(props, state) {
		let cc_rankings = 0
		let discipline = state.discipline
		if (state.discipline.startsWith("team")) {
			cc_rankings = 1
			discipline = 'overall'
		}

		let discipline_points = discipline + "_points"
		let discipline_rank = discipline + "_rank"

		let rankings_men = state.rankings_men
		let rankings_women = state.rankings_women

		let cmp_rankings_men = state.cmp_rankings_men
		let cmp_rankings_women = state.cmp_rankings_women

		rankings_men.sort((a,b) => a[discipline_rank] - b[discipline_rank])
		rankings_women.sort((a,b) => a[discipline_rank] - b[discipline_rank])
		cmp_rankings_men.sort((a,b) => a[discipline_rank] - b[discipline_rank])
		cmp_rankings_women.sort((a,b) => a[discipline_rank] - b[discipline_rank])

		if (cc_rankings == 1) {
			let exclude = [1739, 3724, 1682, 3687, 4512, 3498]
			if (state.discipline == "team-usa") {
				rankings_men = rankings_men.filter(x => x.country == "US")
				rankings_women = rankings_women.filter(x => x.country == "US")
				cmp_rankings_men = cmp_rankings_men.filter(x => x.country == "US")
				cmp_rankings_women = cmp_rankings_women.filter(x => x.country == "US")
			}

			let internationals = ["CA", "AU", "NZ", "ZA", "BM", "BR", "AR", "MX", "CL"]

			if (state.discipline == "team-int") {
				rankings_men = rankings_men.filter(x => internationals.indexOf(x.country) != -1 && x.country != "US")
				rankings_women = rankings_women.filter(x => internationals.indexOf(x.country) != -1 && x.country != "US")
				cmp_rankings_men = cmp_rankings_men.filter(x => internationals.indexOf(x.country) != -1 && x.country != "US")
				cmp_rankings_women = cmp_rankings_women.filter(x => internationals.indexOf(x.country) != -1 && x.country != "US")
			}

			if (state.discipline == "team-eur") {
				rankings_men = rankings_men.filter(x => internationals.indexOf(x.country) == -1 && x.country != "US")
				rankings_women = rankings_women.filter(x => internationals.indexOf(x.country) == -1 && x.country != "US")
				cmp_rankings_men = cmp_rankings_men.filter(x => internationals.indexOf(x.country) == -1 && x.country != "US")
				cmp_rankings_women = cmp_rankings_women.filter(x => internationals.indexOf(x.country) == -1 && x.country != "US")
			}

			let valid = [], invalid = []
			for (let rank of rankings_men) {
				if (exclude.find(x => x == rank.id)) {
					continue
				}
				let details = JSON.parse(rank.details)
				if (details[3].results.length > 1) {
					valid.push(rank)
				}
				else if (rank.overall_points > 85) {
					invalid.push(rank)
				}
			}
			rankings_men = valid.slice(0,10).concat(invalid.slice(0,3))

			valid = []
			invalid = []
			for (let rank of rankings_women) {
				if (exclude.find(x => x == rank.id)) {
					continue
				}
				let details = JSON.parse(rank.details)
				if (details[3].results.length > 1) {
					valid.push(rank)
				}
				else if (rank.overall_points > 85) {
					invalid.push(rank)
				}
			}
			rankings_women = valid.slice(0,10).concat(invalid.slice(0,3))
			while (rankings_men.length < rankings_women.length) {
				rankings_men.push(null)
			}
			while (rankings_women.length < rankings_men.length) {
				rankings_women.push(null)
			}

			valid = []
			invalid = []
			for (let rank of cmp_rankings_men) {
				if (exclude.find(x => x == rank.id)) {
					continue
				}
				let details = JSON.parse(rank.details)
				if (details[3].results.length > 1) {
					valid.push(rank)
				}
				else if (rank.overall_points > 85) {
					invalid.push(rank)
				}
			}
			cmp_rankings_men = valid

			valid = []
			invalid = []
			for (let rank of cmp_rankings_women) {
				if (exclude.find(x => x == rank.id)) {
					continue
				}
				let details = JSON.parse(rank.details)
				if (details[3].results.length > 1) {
					valid.push(rank)
				}
				else if (rank.overall_points > 85) {
					invalid.push(rank)
				}
			}
			cmp_rankings_women = valid
		}
		let set = state.set

		return(<div className='races'>
			<div className='d-flex justify-content-between mb-2'>
				<div>
					<h2 className='headline mb-0'>RANKINGS { set && moment.utc(set.date).format("DD-MM-YYYY")}</h2>
				</div>
				<div className='d-flex align-items-center'>
					{set && set.published === 0 && <button className='btn btn-sm btn-success flex-shrink-0' onClick={this.onPublish}>Publish</button> }
					{set && set.published === 1 && <button className='btn btn-sm btn-danger flex-shrink-0' onClick={this.onUnpublish}>Unpublish</button> }
					{ set &&
						<select className='form-control form-control-sm ml-2' onChange={this.changeType}>
							<option value="10" selected={set.type === 10}>2024 Rankings - last 52 weeks</option>
							<option value="8" selected={set.type === 8}>2023 Rankings - last 52 weeks</option>
							<option value="7" selected={set.type === 7}>2022 Rankings - last 52 weeks</option>
							<option value="" selected={set.type === 0} disabled>----------</option>
							<option value="0" selected={set.type === 0} disabled>Past 24 months - Sum best 3</option>
							<option value="1" selected={set.type === 1} disabled>Pre CC 2021 - Best 1 or 2 Avg</option>
							<option value="2" selected={set.type === 2} disabled>All time</option>
							<option value="3" selected={set.type === 3} disabled>Pre CC 2021 - Best 2 Avg</option>
							<option value="4" selected={set.type === 4} disabled>Dec 2020 -> now - Best 3 Avg</option>
							<option value="5" selected={set.type === 5} disabled>2022 Rankings</option>
							<option value="6" selected={set.type === 6} disabled>Dec 2020 -> now - Best 3 Avg v2</option>
						</select>
					}
					<button className='btn btn-sm btn-secondary ml-2 flex-shrink-0'
									onClick={this.onGenerateRanking} title='Generate Rankings'>Generate
					</button>
					<button className='btn btn-sm btn-secondary ml-2 flex-shrink-0'
							onClick={this.onExportRanking} title='Export Rankings'>Export
					</button>
					{ set && set.published === 0 &&
						<button className='btn btn-sm btn-danger ml-2 flex-shrink-0'
								onClick={this.onDeleteRankingSetRequest} title='Delete Rankings'>Delete
						</button>
					}
				</div>
			</div>
			<div className='card'>
				<table className='ranking-table'>
					<tr><td/><td/>
						<td colspan="3">
							<select className='form-control form-control-sm' onChange={(e) => this.setState({discipline: e.target.value})}>
								<option value="overall" selected={state.discipline == "overall"}>Overall</option>
								<option value="swim" selected={state.discipline == "swim"}>Swim</option>
								<option value="bike" selected={state.discipline == "bike"}>Bike</option>
								<option value="run" selected={state.discipline == "run"}>Run</option>
								<option value="team-eur" selected={state.discipline == "team-eur"}>Team Europe</option>
								<option value="team-int" selected={state.discipline == "team-int"}>Team Internationals</option>
								<option value="team-usa" selected={state.discipline == "team-usa"}>Team USA</option>
							</select>
						</td>
						<td/><td/>
					</tr>
					<tr>{["Men", "Points", "Move", "Rank", "Move", "Points", "Women"].map(x => <th>{x}</th>)}</tr>
					{ rankings_men.map((x, index) => {
						if(index >= 100) return

						let move_men = 0
						if(rankings_men[index])
						{
							if(cc_rankings)
							{
								let pos = cmp_rankings_men.findIndex(y => y.id == rankings_men[index].id)
								if(pos >= 0) move_men = pos - index
							}
							else
							{
								let cmp_men = state.cmp_rankings_men.find(y => y.id == x.id)
								if(cmp_men) move_men = cmp_men[discipline_rank] - x[discipline_rank]
							}
						}

						let move_women = 0
						if(rankings_women[index])
						{
							if(cc_rankings)
							{
								let pos = cmp_rankings_women.findIndex(y => y.id == rankings_women[index].id)
								if(pos >= 0) move_women = pos - index
							}
							else
							{
								let cmp_women = state.cmp_rankings_women.find(y => y.id == rankings_women[index].id)
								if(cmp_women) move_women = cmp_women[discipline_rank] - rankings_women[index][discipline_rank]
							}
						}

						return(
							<tr hunt={cc_rankings == 1 && index >= 10}>
								<td>{x && <a href={'/athlete/' + x.id}>{x.name}</a>}</td>
								<td>{x && x[discipline_points].toFixed(2)}</td>
								<td up={move_men > 0} down={move_men < 0}>{move_men != 0 && move_men}</td>
								<td>#{index+1}</td>
								<td up={move_women > 0} down={move_women < 0}>{move_women != 0 && move_women}</td>
								<td>{rankings_women[index] && rankings_women[index][discipline_points].toFixed(2)}</td>
								<td>{rankings_women[index] && <a href={'/athlete/' + rankings_women[index].id}>{rankings_women[index].name}</a>}</td>
							</tr>
						)
					})}
				</table>
			</div>

			<PleaseWait show={state.please_wait}/>

			<Dialog title="Delete Rankings Set?" ref={this.deleteConfirmDialogRef} yesText="Confirm Delete" onYes={this.onDeleteRankingSetConfirmed}>
				<p>Are you sure you want to delete this rankings set?</p>
			</Dialog>

			<Dialog nofooter={true} title="Delete Error" ref={this.deleteErrorDialogRef}>
				<p>{state.error_message}</p>
			</Dialog>
		</div>)
	}
}
