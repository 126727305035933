import { h, render, Component, createRef, Fragment } from 'preact';
import moment from 'moment';
import { Dialog } from "./dialog"
import { PleaseWait } from "./utils"
import "./races.scss"
import { fetchAPI, serverAPI, websocketsServerAPI } from '../libs/api';


export class Races extends Component {
	state = {
		races: [],
		filtered_races: [],
		search: null,
		filter: null,
		series: [],
		tiers: [],
		countries: [],
		cities: [],
		working: false,
		working_text: null
	}
	dialogAddRace = createRef()

	componentDidMount() {
		this.setState({search: sessionStorage.getItem("races-search"), filter: new URL(window.location.href).searchParams.get("filter")}, () => {
			this.fetchSeries();
			this.fetchTiers();
			this.refreshRaceList()
		})
	}

	fetchSeries() {
		fetchAPI("/api/series/", { headers: { 'Content-Type': 'application/json' }})
			.then(response => response.json())
			.then(data => this.setState({ series: data.series }))
			.catch(error => alert(error.message));
	}

	fetchTiers() {
		fetchAPI("/api/tiers/", { headers: { 'Content-Type': 'application/json' }})
			.then(response => response.json())
			.then(data => this.setState({ tiers: data.tiers }))
			.catch(error => alert(error.message));
	}

	refreshRaceList() {
		let that = this;
		let url = "/api/races/";
		const { filter } = this.state;

		if (filter) {
			url += `?filter=${filter}`;
		}

		fetchAPI(url, {
			headers: { 'Content-Type': 'application/json' },
		})
			.then(function(response) { if(response.ok) return response.json(); return Promise.reject(response); })
			.then(function(data) {
				let countries = [], cities = []
				for(let a of data.races)
				{
					a.search = (a.serie + " " + a.name + " " + a.country_name + " " + a.city + " " + a.id + " " + a.date + " " + a.t100_season + " " + a.t100_regional_championship).toLowerCase()

					if(a.country_name != "" && !countries.find(x => x == a.country_name)) countries.push(a.country_name)
					if(a.city != "" && !cities.find(x => x == a.city)) cities.push(a.city)
				}

				countries.sort()
				cities.sort()

				that.setState({ races: data.races, countries: countries, cities: cities })
				that.searchRaces(that.state.search, data.races)
			})
	}

	searchOnInput = e => {
		const { value } = e.target
		sessionStorage.setItem("races-search", value)
		this.setState({ search: value })
		if (this.filter_timeout) clearTimeout(this.filter_timeout)
		this.filter_timeout = setTimeout((that) => {
			that.searchRaces(that.state.search, that.state.races)
			that.filter_timeout = null;
		}, 250, this);
	}

	filterOnInput = e => {
		const { value } = e.target
		let url = new URL(window.location.href)

		if(value == "") {
			url.searchParams.delete("filter")
		} else {
			url.searchParams.set("filter", value)
		}

		window.history.replaceState({}, document.title, url)

		this.setState({ filter: value }, () => {
			this.refreshRaceList()
		})
	}

	searchRaces(search, races) {
		if(search && search != "") {
			let tokens = search.toLowerCase().split(" ")
			races = races.filter(x => {
				let out = false
				for(let t of tokens) {
					if(x.search.indexOf(t) == -1)
					{
						out = true
						break
					}
				}
				return !out
			})
		}

		this.setState({filtered_races: races.slice(0, 200) })
	}

	onAddNewRace = e => {
		this.dialogAddRace.current.show()
	}

	addRaceSerie = createRef()
	addRaceDate = createRef()
	addRaceCountry = createRef()
	addRaceCity = createRef()
	addRaceDivision = createRef()

	dialogAddRaceYes = e => {
		let that = this
		let serie = this.addRaceSerie.current.value
		let date = this.addRaceDate.current.value
		let country = this.addRaceCountry.current.value
		let city = this.addRaceCity.current.value
		let division = this.addRaceDivision.current.value

		fetchAPI("/api/races/create", {
			method: "post",
			headers: { 'Content-Type': 'application/json' },
			body: JSON.stringify({ serie: serie, date: date, country: country, city: city, division: division })
		})
			.then(function(response) { if(response.ok) return response.json(); return Promise.reject(response); })
			.then(function(data) {
				that.refreshRaceList()
			})
	}

	onPointsAllRaces = async e => {
		let count = 0, now = new Date()
		this.setState({ working: true })
		for(let r of this.state.races)
		{
			let date = new Date(r.date)
			if(now.getFullYear() - date.getFullYear() > 3) break;
			count += 1
		}

		for(let r of this.state.races)
		{
			if(!r.ait_men && !r.ait_women) continue
			let date = new Date(r.date)
			if(now.getFullYear() - date.getFullYear() > 3) break;
			await fetchAPI("/api/race/" + r.id + "/update_points", { method: "post", headers: { 'Content-Type': 'application/json' }})
			console.log(r.id)
			count -= 1
			this.setState({ working_text: "Races left: " + count })
		}
		this.setState({ working: false, working_text: null })
	}

	onSofAllRaces = async e => {
		let count = 0, now = new Date()
		this.setState({ working: true })
		for (let r of this.state.races) {
			let date = new Date(r.date)
			if (now.getFullYear() - date.getFullYear() > 1) {
				break;
			}
			count += 1
		}
		this.setState({ working_text: "Races left: " + count })

		for (let r of this.state.races) {
			let date = new Date(r.date)
			if (now.getFullYear() - date.getFullYear() > 1) {
				break;
			}
			this.calculateSof(r.id)
			count -= 1
			this.setState({ working_text: "Races left: " + count })
		}
		this.setState({ working: false, working_text: null })
	}

	calculateSof(raceId) {
		fetchAPI("/api/race/" + raceId + "/calculate_sof", {
			method: "post",
			headers: { 'Content-Type': 'application/json' },
		})
			.then(function(response) {
				if (response.ok) {
					return response.json();
				}

				return Promise.reject(response);
			})
	}

	render(props, state) {
		let races = state.filtered_races
		return(<div class='races'>
			<h2 class='headline'>RACES</h2>
			<div class='card'>
				<div class='d-flex align-items-center'>
					<div>Search:</div>
					<input class='form-control form-control-sm ml-2 mr-3' type='text' onInput={this.searchOnInput} value={state.search}/>
					<div>Filter:</div>
					<select class='form-control filter-select form-control-sm ml-2' onChange={this.filterOnInput} value={state.filter}>
						<option value="">All</option>
						<option value="current" selected={this.state.filter === "current"}>Current</option>
						<option value="upcoming" selected={this.state.filter === "upcoming"}>Upcoming</option>
						<option value="past" selected={this.state.filter === "past"}>Past</option>
					</select>
					<button class='btn btn-sm btn-secondary ml-3 flex-shrink-0' onClick={this.onAddNewRace}>Add a new Race</button>
				</div>
			</div>
			<div class='card'>
				<table class='races-table'>
					<tr>{["ID", "Serie", "Date", "Brand", "Country", "Res.", "Tier", "SOF MPRO", "SOF FPRO"].map(x => <th>{x}</th>)}</tr>
					{ races.map(x => {
						return(
							<tr>
								<td><a href={"/race/" + x.id}>{x.id}</a></td>
								<td><a href={"/race/" + x.id}>{x.serie}</a></td>
								<td>{moment.utc(x.date).format("MMM DD YY")}</td>
								<td>{x.brand}</td>
								<td>{x.country_name}</td>
								<td>{x.results_count}</td>
								<td>{x.tier ?? '-'}</td>
								<td>{x.sof_mpro_manual ?? x.sof_mpro_calculated ?? '-'}</td>
								<td>{x.sof_fpro_manual ?? x.sof_fpro_calculated ?? '-'}</td>
							</tr>
						)
					})}
				</table>
			</div>
			<Dialog yesText="Create New Race" title="Add a new Race" ref={this.dialogAddRace} onYes={this.dialogAddRaceYes}>
				<table>
					<tr>
						<td>Serie</td>
						<td>
							<select class="form-control form-control-sm" name="serie" ref={this.addRaceSerie}>
								<option selected disabled value="" class="d-none">Select a Serie...</option>
								{state.series.map(serie => {
									return <option value={serie.id}>{serie.name}</option>
								})}
							</select>
						</td>
					</tr>
					<tr>
						<td>Date</td>
						<td><input class="form-control form-control-sm" type="date" ref={this.addRaceDate}/></td>
					</tr>
					<tr>
						<td>Country</td>
						<td><input class="form-control form-control-sm" value="" list="dl-countries" ref={this.addRaceCountry}/></td>
					</tr>
					<tr>
						<td>City</td>
						<td><input class="form-control form-control-sm" value="" list="dl-cities" ref={this.addRaceCity}/></td>
					</tr>
					<tr>
						<td>Division</td>
						<td>
							<select class="form-control form-control-sm" name="division" ref={this.addRaceDivision}>
								<option selected disabled value="" class="d-none">Select a division...</option>
								<option value="MPRO">MPRO</option>
								<option value="FPRO">FPRO</option>
								<option value="BOTH">Both</option>
							</select>
						</td>
					</tr>
				</table>

			</Dialog>

			<datalist id="dl-countries">
				{state.countries.map(x => {
					return <option value={x}/>
				})}
			</datalist>
			<datalist id="dl-cities">
				{state.cities.map(x => {
					return <option value={x}/>
				})}
			</datalist>
			<PleaseWait show={state.working} text={state.working_text}/>
		</div>)
	}
}
