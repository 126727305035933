import { h, Component, createRef } from "preact"
import { fetchAPI } from "../../../libs/api"
import AddSeason from "./dialogs/addSeason"
import moment from "moment"


import t100Logo from "../../../assets/t100-logo.png"
import "./seasons.scss"

export class T100Seasons extends Component {
    state = {
        filter: sessionStorage.getItem("seasons-filter"),
        filteredSeasons: [],
        seasons: [],
    }

    dialogAddSeason = createRef()

    filter(event) {
        const filter = event.target.value
        sessionStorage.setItem("seasons-filter", filter)
        const { seasons } = this.state
        let filteredSeasons

        if (filter && filter != "") {
            let tokens = filter.toLowerCase().split(" ")
            filteredSeasons = seasons.filter(season => {
                let out = false;
                for (let token of tokens) {
                    if (season.search.indexOf(token) == -1) {
                        out = true;
                        break;
                    }
                }
                return !out
            })
        } else {
            filteredSeasons = seasons
        }
        this.setState({
            filter: filter,
            filteredSeasons: filteredSeasons.slice(0, 200)
        })
    }

    fetchSeasons() {
        fetchAPI("/api/t100/seasons/", { headers: { 'Content-Type': 'application/json' }})
            .then(response => response.json())
            .then(data => this.setState({ seasons: data.seasons, filteredSeasons: data.seasons }))
            .catch(error => alert(error.message))
    }

    componentDidMount() {
        this.fetchSeasons()
    }

    render(props, state) {
        return (
            <div class="seasons">
                <h2 class="headline">
                    <img src={t100Logo} alt="T100 Logo" />
                    Seasons
                </h2>

                <div class='card'>
                    <div class='d-flex align-items-center'>
                        <div>Filter:</div>
                        <input class='form-control form-control-sm ml-2' type='text' onInput={this.filter.bind(this)} value={this.state.filter}/>
                        <button class='btn btn-sm btn-secondary ml-3 flex-shrink-0' onClick={() => this.dialogAddSeason.current.show()}>Add a new Season</button>
                    </div>
                </div>

                <div class="card">
                    <table class="seasons-table table">
                        <tr>
                            {["ID", "Name", "Start Date", "End Date", "Total Races", "Top Scoring Results", "Published?"].map((columnHeader) => (
                                <th>{columnHeader}</th>
                            ))}
                        </tr>

                        {this.state?.filteredSeasons.map((season) => {
                            return (
                                <tr>
                                    <td>
                                        <a href={"/t100/season/" + season.id}>{season.id}</a>
                                    </td>
                                    <td>
                                        <a href={"/t100/season/" + season.id}>{season.name}</a>
                                    </td>
                                    <td>{moment.utc(season.start_date).format("DD/MM/YYYY")}</td>
                                    <td>{moment.utc(season.end_date).format("DD/MM/YYYY")}</td>
                                    <td>{season.total_race_count}</td>
                                    <td>{season.top_scoring_results_count}</td>
                                    <td>{season.is_active ? "Yes" : "No"}</td>
                                </tr>
                            );
                        })}
                    </table>
                </div>

                <AddSeason reference={this.dialogAddSeason} fetchSeasons={this.fetchSeasons.bind(this)}/>
            </div>
        )
    }
}
