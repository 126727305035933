import { h, Component } from "preact"
import { fetchAPI } from "../../../libs/api"
import EditTab from "./tabs/editTab"

const _ = require('lodash')
import "./season.scss"
import ParticipantsTab from "./tabs/participantsTab"

export class T100Season extends Component {
	state = {
		tab: "info",
		season: {},
		originalSeason: {},
		loading: true,
		error: null,
		tabs: [
			{ tab: 'info', name: 'Info' },
			{ tab: 'participants', name: 'Participants' },
		]
	}

	refreshTab() {
		if (location.hash) {
			let tab = location.hash.substring(1)
			const { tabs } = this.state
			tabs.find(x => x.tab == tab) && this.setState({ tab: tab })
		}
	}

	async refreshSeason() {
		return fetchAPI(`/api/t100/seasons/${this.props.id}`, { headers: { 'Content-Type': 'application/json' } })
			.then(response => response.json())
			.then(data => this.setState({ originalSeason: data.season, season: data.season, loading: false }))
			.catch(err => this.setState({ error: err }))
	}

	changeTab(event) {
		location.hash = `#${event.target.dataset.tab}`
		this.setState({ tab: event.target.dataset.tab })
	}

	componentDidMount() {
		this.refreshSeason()
		this.refreshTab()
	}

	render(props, state) {
		const {
			originalSeason,
			season,
			loading,
			error,
			tabs,
			tab
		} = this.state

		if (error) return <p>{ error.message }</p>
		return !loading && (
			<div class="season-info">
				<h2 class="headline">{ originalSeason.name }</h2>

				<div class="tabs">
					{ tabs.map((tab) => {
						return (
							<div class="tab" selected={ tab.tab == state.tab } data-tab={ tab.tab } onClick={ this.changeTab.bind(this) }>
								{ tab.name }
							</div>
						)
					}) }
				</div>

				{ tab === "info" && <EditTab season={ this.state.season } refreshSeason={ this.refreshSeason.bind(this) } /> }
				{ tab === "participants" && <ParticipantsTab season={ this.state.season } /> }
			</div>
		)
	}
}
