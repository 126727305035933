import { h, render, Component, createRef, Fragment } from 'preact';
import moment from 'moment';
import "./utils.scss"
import "./profile.scss"

export class PickDate extends Component {

	mount = (d) => {
		if(!d) return

		d.addEventListener('change', this.onChange)

		this.picker = new Pikaday({ 
			field: d,
			format: 'YYYY-MM-DD',
			defaultDate: moment.utc(this.props.value).toDate(),
			firstDay: 1,
			toString: function (date, format) {
				return moment(date).format(format)
			},
			parse: function(dateString, format) {
				return moment.utc(dateString).toDate()
			},
			yearRange: [1960, 2030]
		});
		this.picker.hide()
	}

	get value()
	{
		return moment.utc(this.picker.getDate()).add(2, 'hours').format('YYYY-MM-DD')
	}

	set value(v)
	{
		this.picker.setDate(moment.utc(v).toDate())
	}

	onChange = (e) => {
		if(this.props.onChange) this.props.onChange(e)
	}

	focus() {
		this.input.focus()
	}
	select() {
		this.input.select()
	}

	shouldComponentUpdate (nextProps, nextState) {
		return false
	}

	render (props, state) {
		if(window.webkit && window.webkit.messageHandlers.cordova_iab) return (
			<input type='date' {...props}/>
		)
		return (
			<Fragment>
				<input ref={this.mount} {...props}/>
			</Fragment>
		)
	}
}

export class PleaseWait extends Component {
	render (props, state) {
		if(!props.show) return
		return <div class='please-wait'>{props.text || "Please Wait..."}</div>
	}
}

export class Selector extends Component {

	selectorInputRef = createRef()
	selectorULRef = createRef()
	selectorSelectedRef = createRef()

	open = e => {
		e.preventDefault()
		let select = e.target
		//this.selector_select = select

		let selector = {
			options: [],
			title: this.props.title
		}
		for(let option of this.props.options)
		{
			selector.options.push({ value: option.value, text: option.name, filter: option.name.toLowerCase(), selected: option.value == select.value })
		}
		//document.body.classList.add("noscroll")
		this.setState({selector: selector, selector_filter: null}, () => {
			this.selectorULRef.current.scrollTop = 0
			if(this.selectorSelectedRef.current)
			{
				this.selectorULRef.current.scrollTop = this.selectorSelectedRef.current.offsetTop - (this.selectorULRef.current.offsetHeight - this.selectorSelectedRef.current.offsetHeight) * 0.25 //this.selectorSelectedRef.current.offsetTop - this.selectorULRef.current.offsetHeight * 0.5
			}
			let supportsTouch = 'ontouchstart' in window || navigator.msMaxTouchPoints
			if(!supportsTouch) this.selectorInputRef.current.focus()
		})
		return false
	}

	selectorLI = e => {
		this.props.value = e.target.dataset.value
		if(this.props.onChange) this.props.onChange(this.props.value) 
		//this.selector_select.value = e.target.dataset.value
		//this.selector_select.dispatchEvent(new Event('change'))
		//document.body.classList.remove("noscroll")
		this.setState({selector: null})
	}

	selectorFilter = e => {
		this.setState({ selector_filter: e.target.value == "" ? null : e.target.value.toLowerCase()})
	}

	selectorBack = e => {
		//document.body.classList.remove("noscroll")
		this.setState({selector: null})
	}

	render (props, state) {
		let selected = props.options.find(x => x.value == props.value)
		return(
			<Fragment>
				<div>
					<button class='form-control from-control-sm text-left' onClick={this.open}>{selected ? selected.name : props.title}</button>
				</div>
				<div class='selector' hidden={!state.selector}>
					<div class='selector-container'>
						<div class='selector-title h4'><div class='back' onClick={this.selectorBack}></div>{state.selector && state.selector.title}</div>
						<input class='form-control' placeholder="Filter..." ref={this.selectorInputRef} onInput={this.selectorFilter} value={state.selector && state.selector.filter}/>
						<ul ref={this.selectorULRef}>
							{state.selector && state.selector.options.map(x => {
								if(state.selector_filter && x.filter.indexOf(state.selector_filter) == -1) return
								if(x.selected) return <li data-value={x.value} onClick={this.selectorLI} selected ref={this.selectorSelectedRef}>{x.text}</li>
								return <li data-value={x.value} onClick={this.selectorLI}>{x.text}</li>
							})}
						</ul>
					</div>
				</div>
			</Fragment>
		)
	}

}