import { h, Component, createRef } from "preact";
import { fetchAPI } from "../../../libs/api";
import { Dialog } from "../../dialog";
import "./redirectsTab.scss";
const _ = require('lodash');

export default class RedirectsTab extends Component {
	deleteConfirmDialogRef = createRef();

	state = {
		redirects: this.props.serie.redirects
	}

	buildTestUrl(alias) {
		const { protocol, hostname } = window.location;
		return `${protocol}//${hostname}/serie/${alias}`;
	}

	deleteRedirect() {
		const redirectId = this.state.redirectToDelete;
		fetchAPI(`/api/series_redirects/${redirectId}`, { method: 'delete' })
			.then(() => location = `/serie/${this.props.serie.alias}`)
			.catch(error => alert(error));
	}

	async checkUrlStatus(url) {
		return fetch(url, {
			method: 'HEAD',
			redirect: 'follow'
		})
			.then(res => res.ok ? "active" : "broke")
			.catch("Network error")
	}

	async testRedirects() {
		const redirects = this.state.redirects;
		const redirectsWithUrls = redirects.map(redirect => ({ ...redirect, url: this.buildTestUrl(redirect.alias) }));

		let updatedRedirects = [];
		for (const redirect of redirectsWithUrls) {
			const status = await this.checkUrlStatus(redirect.url);
			updatedRedirects.push({ ...redirect, status: status });
		}

		this.setState({ redirects: updatedRedirects })
	}

	openConfirmDialog(redirectId) {
		this.setState({ redirectToDelete: redirectId }, () => {
			this.deleteConfirmDialogRef.current.show();
		});
	}

	render(props, state) {
		return (
			<div class="serie-redirects">
				<div class='card'>
					<div class='d-flex align-items-center'>
						<button class='btn btn-sm btn-secondary flex-shrink-0' onClick={this.testRedirects.bind(this)}>Test Redirects</button>
					</div>
				</div>

				<div class="card">
					<table class="series-redirects__table">
						<tr>
							<th>ID</th>
							<th>Alias</th>
							<th>Test URL</th>
							<th>Status</th>
						</tr>
						{state.redirects.map((redirect) => {
							const url = this.buildTestUrl(redirect.alias);
							return (
								<tr>
									<td>{redirect.id}</td>
									<td>{redirect.alias}</td>
									<td><a href={url} target="_blank">{url}</a></td>
									<td className={redirect.status} >{redirect.status || '-'}</td>
									<td><button class='btn btn-sm btn-secondary flex-shrink-0' onClick={() => this.openConfirmDialog(redirect.id)}>Delete</button></td>
								</tr>
							);
						})}
					</table>
				</div>

				<Dialog title="Delete Redirect?" ref={this.deleteConfirmDialogRef} yesText="Confirm Delete" onYes={this.deleteRedirect.bind(this)}>
					<p>Are you sure you want to delete this redirect?</p>
				</Dialog>
			</div>
		);
	}
}
