import { h, Component, Fragment, createRef } from "preact"
import { fetchAPI } from "../../../../libs/api"
import { Dialog } from "../../../dialog"
import { PickDate } from "../../../utils"
const _ = require('lodash')

export default class EditTab extends Component {
	deleteConfirmDialogRef = createRef()
	errorDialogRef = createRef()
	archiveConfirmDialogRef = createRef()

	state = {
		isEmptyField: false,
		isInputError: false,
		isChangesToPersist: false,
		season: this.props.season,
		originalSeason: this.props.season
	}

	changeHandler(event) {
		const { season, originalSeason } = this.state
		const field = event.target.dataset.attr
		const value = event.target.value
		const isInputError = !event.target.checkValidity()

		// Create copy of tier object and update relevant value
		let updatedSeason = { ...season }
		const isNumberField = event.target.type === "number"
		updatedSeason[field] = isNumberField ? Number(value) : value

		// Check if inputted values are different to original values
		const isChangesToPersist = !_.isEqual(originalSeason, updatedSeason)

		this.setState({
			isEmptyField: value === (null || ""),
			isInputError,
			isChangesToPersist,
			season: updatedSeason,
		})
	}

	componentDidUpdate() {
		if (!_.isEqual(this.props.season, this.state.originalSeason)) {
			this.setState({ season: this.props.season, originalSeason: this.props.season })
		}
	}

	updateSeason() {
		const { season } = this.state

		fetchAPI(`/api/t100/seasons/${this.props.season.id}`, {
			method: "put",
			body: JSON.stringify({ ...season }),
			headers: { 'Content-Type': 'application/json' }
		})
			.then(response => response.json())
			.then(() => {
				this.setState({ originalSeason: season, isChangesToPersist: false })
				location = `/t100/season/${this.props.season.id}`
			})
			.catch(error => {
				this.setState({ errorMessage: error.message })
				this.errorDialogRef.current.show()
			})
	}

	handleError(error) {
		this.setState({ errorMessage: error.message })
		this.errorDialogRef.current.show()
	}

	deleteSeason() {
		fetchAPI(`/api/t100/seasons/${this.props.season.id}`, { method: "delete", headers: { 'Content-Type': 'application/json' } })
			.then(() => location = '/t100/seasons')
			.catch(this.handleError.bind(this))
	}

	openDeleteDialog() {
		this.deleteConfirmDialogRef.current.show()
	}

	openArchiveDialog() {
		this.archiveConfirmDialogRef.current.show()
	}

	toggleIsActive() {
		const { season } = this.state
		fetchAPI(`/api/t100/seasons/${season.id}/${season.is_active ? "archive" : "publish"}`, { method: "post" })
			.then(this.props.refreshSeason)
			.catch(this.handleError.bind(this))
	}

	openArchiveDialog() {
		this.archiveConfirmDialogRef.current.show()
	}

	render(props, state) {
		const {
			season,
			errorMessage,
			isEmptyField,
			isInputError,
			isChangesToPersist
		} = this.state

		return (
			<div class="card">
				<table>
					<tr>
						<td>Name</td>
						<td>
							<input
								class="form-control form-control-sm"
								onInput={ this.changeHandler.bind(this) }
								data-attr="name"
								value={ state.season.name }
								type="text"
							/>
						</td>
					</tr>
					<tr>
						<td>Start Date</td>
						<td>
							<PickDate
								_type="date"
								data-attr="start_date"
								class="form-control form-control-sm"
								onInput={ this.changeHandler.bind(this) }
								onChange={ this.changeHandler.bind(this) }
								value={ state.season.start_date?.split("T")[0] }
							/>
						</td>
					</tr>
					<tr>
						<td>End Date</td>
						<td>
							<PickDate
								_type="date"
								data-attr="end_date"
								class="form-control form-control-sm"
								onInput={ this.changeHandler.bind(this) }
								onChange={ this.changeHandler.bind(this) }
								value={ state.season.end_date?.split("T")[0] }
							/>
						</td>
					</tr>
					<tr>
						<td>Total Races Count</td>
						<td>
							<span class='ml-2'>
								{state.season.total_race_count}
							</span>
						</td>
					</tr>
					<tr>
						<td>Top Scoring Results Count</td>
						<td>
							<input
								class="form-control form-control-sm"
								onInput={ this.changeHandler.bind(this) }
								data-attr="top_scoring_results_count"
								value={ state.season.top_scoring_results_count }
								type="number"
								min="0"
							/>
						</td>
					</tr>
				</table>
				<div class="pt-3">
					<button class="btn btn-sm btn-primary"
						onClick={ this.updateSeason.bind(this) }
						disabled={ (!isChangesToPersist || isEmptyField || isInputError) }>
						Save Changes
					</button>

					{season.showArchivePublishButton && (
						<Fragment>
							<button class="btn btn-sm btn-secondary ml-3" onClick={ this.openArchiveDialog.bind(this) }>
								{ this.state.season.is_active ? "Archive" : "Publish" }
							</button>

							<Dialog title={ `${season.is_active ? "Archive" : "Publish"} season?` } ref={ this.archiveConfirmDialogRef }
								yesText={ season.is_active ? "Archive" : "Publish" }
								onYes={ this.toggleIsActive.bind(this) }>
								<p>Are you sure you want to { season.is_active ? "archive" : "publish" } this season?</p>
							</Dialog>
						</Fragment>
					)}

					{ !season.isRelatedRecords && (
						<Fragment>
							<button class="btn btn-sm btn-danger ml-3" onClick={ this.openDeleteDialog.bind(this) }>
								Delete Season
							</button>

							<Dialog title="Delete season?" ref={ this.deleteConfirmDialogRef } yesText="Confirm Delete" onYes={ this.deleteSeason.bind(this) }>
								<p>Are you sure you want to delete this season?</p>
							</Dialog>
						</Fragment>
					) }
				</div>

				<Dialog nofooter={ true } title="Error!" ref={ this.errorDialogRef }>
					<p>{ errorMessage }</p>
				</Dialog>
			</div>
		)
	}
}
