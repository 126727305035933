import { h, Component, createRef } from "preact"
import { fetchAPI } from "../../../../libs/api"
import { canonical } from "../../../../server/components/utils"
import fuzz from 'fuzzball'
import { Dialog } from "../../../dialog"
import { Tags } from "../../../tags"
const _ = require('lodash')

export default class ParticipantsTab extends Component {
	dialogAddParticipant = createRef()

	defaultParticipant = {
		athlete_id: null,
		contract_tier_id: null,
	}

	state = {
		athletes: [],
		participants: [],
		contractTiers: [],
		importParticipantsError: null,
		participant: this.defaultParticipant,
		isEmptyField: true,
	}

	fetchAthletes = async () => {
		await fetchAPI("/api/all_athletes/")
			.then((response) => response.json())
			.then((data) => this.setState({ athletes: data.athletes }))
	}

	fetchContractTiers = async () => {
		await fetchAPI("/api/t100/contract-tiers?is_active=1")
			.then((response) => response.json())
			.then((data) => this.setState({ contractTiers: data.contractTiers }))
	}

	fetchParticipants = async () => {
		await fetchAPI(`/api/t100/seasons/${this.props.season.id}/participants/`)
			.then((response) => response.json())
			.then((data) => this.setState({ participants: data.participants }))
	}

	addParticipant = (athlete_id, contract_tier_id) => {
		const { participants } = this.state
		const { season } = this.props

		this.setState({ participant: { athlete_id: null, contract_tier_id: null } })

		// Guard clause - if the athlete is already within the list of participants then don't add them again
		if (participants.find(participant => participant.id == athlete_id)) return

		fetchAPI(`/api/t100/seasons/${season.id}/add_participant`, {
			method: "post",
			headers: { 'Content-Type': 'application/json' },
			body: JSON.stringify({ season_id: season.id, athlete_id: athlete_id, contract_tier_id: contract_tier_id })
		})
			.then((response) => response.json())
			.then((data) => this.setState({ participants: data.participants }))
	}

	removeParticipant = (event) => {
		const athlete_id = event.target.closest(".remove").dataset.athlete_id
		const { season } = this.props

		fetchAPI(`/api/t100/seasons/${season.id}/remove_participant/${athlete_id}`, {
			method: "post",
			headers: { 'Content-Type': 'application/json' },
		})
			.then((response) => response.json())
			.then((data) => this.setState({ participants: data.participants }))
	}

	changeHandler(event, data) {
		const field = event?.target?.dataset?.attr || data.props['data-attr']
		const value = typeof event === "number" ? event : event?.target?.value

		let updatedParticipant = this.state.participant
		updatedParticipant[field] = (value === "" || value === undefined) ? null : value

		this.setState({ participant: updatedParticipant },
			() => {
				const { participant } = this.state
				const isEmptyField = (participant.athlete_id === null) || (participant.contract_tier_id === null)
				this.setState({ isEmptyField })
			})
	}

	importParticipantsCSV = async (e) => {
		let that = this
		let input = document.createElement('input')
		input.type = "file"
		input.accept = ".csv"
		input.addEventListener("change", (e) => {
			var fr = new FileReader()
			fr.onload = function () {
				let lines = fr.result.split("\n")
				let columns = lines[0].split(";")

				let firstIndex = columns.indexOf("firstname")
				let lastIndex = columns.indexOf("lastname")
				let countryIndex = columns.indexOf("nation")
				let divisionIndex = columns.indexOf("division")
				let gearRatioIndex = columns.indexOf("gear_ratio")
				let contractTierIdIndex = columns.indexOf("contract_tier_id")

				let athletes = []
				for (let i = 1; i < lines.length; i++) {
					let row = lines[i].split(";")
					let a = {
						fname: row[firstIndex],
						lname: row[lastIndex],
						name: row[firstIndex] + " " + row[lastIndex],
						country: row[countryIndex],
						gearRatio: row[gearRatioIndex],
						division: row[divisionIndex] == "MPRO" ? "MPRO" : "FPRO"
					}

					let fuzzy_athlete = null
					let name = a.name
					let db_athlete = that.state.athletes.find(x => x.name == name)

					if (!db_athlete) {
						let link = canonical(name)
						db_athlete = that.state.athletes.find(x => x.link == link)

						for (let dba of that.state.athletes) {
							let ratio = fuzz.ratio(dba.name, name)
							if (ratio > 85) {
								fuzzy_athlete = dba
								break
							}
						}
					}

					if (db_athlete) {
						that.addParticipant(db_athlete.id, Number(row[contractTierIdIndex]))
					} else {
						athletes.push(a)
					}
				}
				let alert = null
				if (athletes.length > 0) {
					alert = "Couldn't add the following participants:\n\n" + athletes.map(x => x.name + " (" + x.country + ")").join("\n")
				}
				that.setState({ importParticipantsError: alert })
			}
			fr.readAsText(e.target.files[0])
			e.target.value = ''
		})
		input.click()
	}

	componentDidMount() {
		this.fetchAthletes()
		this.fetchParticipants()
		this.fetchContractTiers()
	}

	render(props, state) {
		const { participants, importParticipantsError } = this.state

		return (
			<div class='participants'>
				<div class='alert alert-danger hide-if-empty mb-2' style='white-space: pre-wrap'>
					{ importParticipantsError }
				</div>

				<div class='card'>
					<div class='d-flex'>
						<button class='btn btn-sm btn-secondary flex-shrink-0' onClick={ () => this.dialogAddParticipant.current.show() }>Add a new participant</button>
						<button class='btn btn-sm btn-secondary ml-3' onClick={ this.importParticipantsCSV }>Import CSV File</button>
					</div>
				</div>
				<div class='card'>
					<table class='participants-table table'>
						<tr>
							{ ["Division", "Name", "Country", "Contract Tier", "Olympic Bonus?"].map((columnHeader) => (
								<th>{ columnHeader }</th>
							)) }
						</tr>

						{ participants.length > 0 ? participants.map(participant => (
								<tr>
									<td>{participant.division}</td>
									<td><a
										href={`/t100/season/${this.props.season.id}/participants/${participant.id}`}>{participant.first} {participant.last}</a>
									</td>
									<td>
										<div class={'flag-icon flag-icon-' + participant.country.toLowerCase()}></div>
									</td>
									<td>{participant.contract_tier || "-"}</td>
									<td>{participant.has_olympic_bonus === 1 ? "Yes" : "No"}</td>
									<td class='remove' onClick={this.removeParticipant} data-athlete_id={participant.id}><i
										class="far fa-trash-alt"></i></td>
								</tr>
							)
						) : (
							<tr>
								<td colspan="5">No participants added.</td>
							</tr>
						) }
					</table>
				</div>

				<Dialog yesText="Add Participant" title="Add a new Participant" ref={ this.dialogAddParticipant } onYes={ () => this.addParticipant(this.state.participant.athlete_id, this.state.participant.contract_tier_id) } disabled={ state.isEmptyField }>
					<table>
						<tr>
							<th>Athlete</th>
							<td>
								<Tags
									value={ state.participant.athlete_id }
									placeholder="Type an athlete..."
									options={ state.athletes }
									mode="unique"
									data-attr="athlete_id"
									onChange={ this.changeHandler.bind(this) }
								/>
							</td>
						</tr>
						<tr>
							<th>Contract Tier</th>
							<td>
								<div class='select'>
									<select class='form-control form-control-sm' data-attr="contract_tier_id" onChange={ this.changeHandler.bind(this) }>
										<option value='' selected={ !state.participant.contract_tier_id }>-</option>
										{ state.contractTiers.map(x => <option value={ x.id } >{ x.name }</option>) }
									</select>
								</div>
							</td>
						</tr>
					</table>
				</Dialog>
			</div>
		)
	}
}
