import { h, render, Component, Fragment } from 'preact';
import { canonical, secondsToTime, handleNullValue, getSeconds } from "../server/components/utils";
import { PickDate, PleaseWait } from "./utils";
import moment from 'moment';
import { Tags } from './tags'
import fuzz from 'fuzzball'
import "./race.scss"
import { fetchAPI } from '../libs/api';
import { MediaPicture } from './medias'
import md5 from 'md5';

export class Race extends Component {
	tabs = [
		{ tab: 'info', name: 'Info' },
		{ tab: 'results', name: 'Results' },
		{ tab: 'rtrt', name: 'Import Results' },
		{ tab: 'participants', name: 'Participants' },
		{ tab: 'sectors', name: 'Sectors' },
	]

	rtrt_ulr = "https://api.rtrt.me/events/"
	rtrt_auth = "?appid=52139b797871851e0800638e&token=2830AD3C9C8E4BEB5A74"

	state = {
		rtrt_events: [],
		rtrt_athletes: [],
		rtrt_points: [],
		active_points: [],
		tab: "info",
		im_events: [],
		wt_events: [],
		series: [],
		tiers: [],
		currencies: [],
		championships: [],
		seasons: [],
		data: null
	}
	db_athletes = { athletes: [] }

	im_results = []
	attributes = []

	componentDidMount() {
		this.loadRace(this.props.id)
		this.fetchSeries();
		this.fetchSeasons();
		this.fetchChampionships();
		this.fetchTiers();
		this.fetchCurrencies();
		this.loadAllAthletes()
	}

	shouldComponentUpdate(nextProps, nextState) {
		if (location.hash) {
			let tab = location.hash.substring(1)
			if (this.tabs.find(x => x.tab == tab)) this.setState({ tab: tab })
		}
	}

	fetchSeries() {
		fetchAPI("/api/series/", { headers: { 'Content-Type': 'application/json' } })
			.then(response => response.json())
			.then(data => this.setState({ series: data.series }))
			.catch(error => alert(error.message));
	}

	fetchTiers() {
		fetchAPI("/api/tiers/1", { headers: { 'Content-Type': 'application/json' } })
			.then(response => response.json())
			.then(data => this.setState({ tiers: data.tiers }))
			.catch(error => alert(error.message));
	}

	fetchCurrencies() {
		fetchAPI("/api/currencies/", { headers: { 'Content-Type': 'application/json' } })
			.then(response => response.json())
			.then(data => this.setState({ currencies: data.currencies }))
			.catch(error => alert(error.message));
	}

	loadRTRTRaces = async () => {
		let ulr = "https://api.rtrt.me/events"

		let events = []

		let bunch = 200
		let start = 1
		while (1) {
			const events_fetch = await fetch(ulr + this.rtrt_auth + "&max=" + bunch + "&start=" + start)
			const events_json = await events_fetch.json()
			if (events_json.error) break
			if (events_json.info.first == events_json.info.last) break
			for (let e of events_json.list) {
				if (e.desc) e.desc = e.desc.replace("®", "")
				events.push(e)
			}
			if (events.length > 1000) break
			start = parseInt(events_json.info.last) + 1
		}
		this.setState({ rtrt_events: events })
	}

	loadRTRTRaceInfo = async () => {
		let rtrt_name = this.state.data.race.rtrt_name
		if(!rtrt_name) return

		let swim_point, t1_point, bike_point, t2_point, finish_point
		let points = []
		let bunch = 200
		let start = 1

		while(1) {
			const points_fetch = await fetch(this.rtrt_ulr + rtrt_name + "/points" + this.rtrt_auth + "&max=" + bunch + "&start=" + start)
			const points_json = await points_fetch.json()
			let dataTemp = this.state.data
			if(points_json.error) break
			if(points_json.info.first == points_json.info.last) break
			for(let e of points_json.list) {
				switch (e.name.toLowerCase()) {
					case "swim":
						swim_point = e.name
						dataTemp.race.rtrt_swim = e.name
						this.attributes.rtrt_swim = e.name
						break
					case "t1":
						t1_point = e.name
						dataTemp.race.rtrt_t1 = e.name
						this.attributes.rtrt_t1 = e.name
						break
					case "bike":
						bike_point = e.name
						dataTemp.race.rtrt_bike = e.name
						this.attributes.rtrt_bike = e.name
						break
					case "t2":
						t2_point = e.name
						dataTemp.race.rtrt_t2 = e.name
						this.attributes.rtrt_t2 = e.name
						break
					case "finish":
						finish_point = e.name
						dataTemp.race.rtrt_finish = e.name
						this.attributes.rtrt_finish = e.name
						break
				}

				points.push(e)
			}
			start = parseInt(points_json.info.last) + 1
		}

		let active_points = {
			swim: swim_point,
			t1: t1_point,
			bike: bike_point,
			t2: t2_point,
			finish: finish_point
		}

		this.setState({ rtrt_points: points, active_points: active_points })
		this.save()
	}

	getResults = async () => {
		let rtrt_name = this.state.data.race.rtrt_name
		if(!rtrt_name) return

		let raceulr = "https://api.rtrt.me/events/"

		let athletes = []
		let pidlist = []

		let bunch = 100
		let start = 1
		while (1) {
			const profiles_fetch = await fetch(raceulr + rtrt_name + "/profiles" + this.rtrt_auth + "&max=" + bunch + "&start=" + start)
			const profiles_json = await profiles_fetch.json()

			if (profiles_json.error) break
			if (profiles_json.info.first == profiles_json.info.last) break

			for (let e of profiles_json.list) {
				if (e.class == "relay") continue
				if (e.race.toUpperCase().indexOf("PRO") == -1 && e.race.toUpperCase().indexOf("ELITE") == -1 && e.class != "pro") continue
				e.division = e.sex.toUpperCase() + "PRO"
				e.splits = []
				e.first = e.fname
				e.last = e.lname
				athletes.push(e)
				pidlist.push(e.pid)
			}

			start = parseInt(profiles_json.info.last) + 1
		}

		let race = this.state.data.race
		let split_list = []
		if (race.rtrt_swim) split_list.push(race.rtrt_swim)
		if (race.rtrt_t1) split_list.push(race.rtrt_t1)
		if (race.rtrt_bike) split_list.push(race.rtrt_bike)
		if (race.rtrt_t2) split_list.push(race.rtrt_t2)
		if (race.rtrt_finish) split_list.push(race.rtrt_finish)

		bunch = 100
		start = 1
		while (1) {
			const splits_fetch = await fetch(raceulr + rtrt_name + "/profiles/" + pidlist.join(",") + "/splits/" + split_list.join(",") + this.rtrt_auth + "&max=" + bunch + "&start=" + start)
			const splits_json = await splits_fetch.json()

			if (splits_json.error) break
			if (splits_json.info.first == splits_json.info.last) break

			for (let split of splits_json.list) {
				let a = athletes.find(x => x.pid == split.pid)
				if (!a) continue
				a.splits.push(split)
			}

			start = parseInt(splits_json.info.last) + 1
		}

		for (let a of athletes) {
			a.rank = 99999
			a.times = [0, 0, 0, 0, 0, 0]

			let split_swim = a.splits.find(x => x.point == race.rtrt_swim)
			let split_t1 = a.splits.find(x => x.point == race.rtrt_t1)
			let split_bike = a.splits.find(x => x.point == race.rtrt_bike)
			let split_t2 = a.splits.find(x => x.point == race.rtrt_t2)
			let split_finish = a.splits.find(x => x.point == race.rtrt_finish)

			if (split_swim) a.times[0] = getSeconds(split_swim.legTime)
			if (split_t1) a.times[1] = getSeconds(split_t1.legTime)
			if (split_bike) a.times[2] = getSeconds(split_bike.legTime)
			if (split_t2) a.times[3] = getSeconds(split_t2.legTime)
			if(split_finish) {
				a.times[4] = getSeconds(split_finish.legTime)
				a.times[5] = getSeconds(split_finish.time)

				if (split_finish.results?.["course-sex"]) {
					a.rank = parseInt(split_finish.results["course-sex"].p)
				}
				if (split_finish.results?.["course-sex-division"]) {
					a.rank = parseInt(split_finish.results["course-sex-division"].p)
				}
				if (split_finish.results?.["course-sex-agegroup"]) {
					a.rank = parseInt(split_finish.results["course-sex-agegroup"].p)
				}
				if (split_finish.results?.["course-class-sex-division"]) {
					a.rank = parseInt(split_finish.results["course-class-sex-division"].p)
				}
				if (split_finish.results?.["course-class-sex-agegroup"]) {
					a.rank = parseInt(split_finish.results["course-class-sex-agegroup"].p)
				}
			}
		}

		athletes = athletes.filter(x => x.splits.length)

		athletes.sort((a, b) => a.division == b.division ? a.rank - b.rank : a.division.charCodeAt(0) - b.division.charCodeAt(0))

		this.setState({ rtrt_athletes: athletes }, () => this.matchNames())
	}

	exportResults = async () => {
		let rtrt_name = this.state.data.race.rtrt_name
		if(!rtrt_name) return

		this.setState({ working: true })

		let raceulr = "https://api.rtrt.me/events/"

		let athletes = []
		let pidlist = []

		let bunch = 100
		let start = 1
		while (1) {
			const profiles_fetch = await fetch(raceulr + rtrt_name + "/profiles" + this.rtrt_auth + "&max=" + bunch + "&start=" + start)
			const profiles_json = await profiles_fetch.json()

			if (profiles_json.error) break
			if (profiles_json.info.first == profiles_json.info.last) break

			for (let e of profiles_json.list) {
				if (e.class == "relay") continue
				if (e.race.toUpperCase().indexOf("PRO") == -1 && e.race.toUpperCase().indexOf("ELITE") == -1 && e.class != "pro") continue
				e.division = e.sex.toUpperCase() + "PRO"
				e.splits = []
				athletes.push(e)
				pidlist.push(e.pid)
			}

			start = parseInt(profiles_json.info.last) + 1
		}

		let race = this.state.data.race
		let split_list = []
		/*if(race.rtrt_swim) split_list.push(race.rtrt_swim)
		if(race.rtrt_t1) split_list.push(race.rtrt_t1)
		if(race.rtrt_bike) split_list.push(race.rtrt_bike)
		if(race.rtrt_t2) split_list.push(race.rtrt_t2)
		if(race.rtrt_finish) split_list.push(race.rtrt_finish)
		*/

		bunch = 100
		start = 1
		while (1) {
			const splits_fetch = await fetch(raceulr + rtrt_name + "/profiles/" + pidlist.join(",") + "/splits/" + split_list.join(",") + this.rtrt_auth + "&max=" + bunch + "&start=" + start)
			const splits_json = await splits_fetch.json()

			if (splits_json.error) break
			if (splits_json.info.first == splits_json.info.last) break

			for (let split of splits_json.list) {
				let a = athletes.find(x => x.pid == split.pid)
				if (!a) continue
				split.epochTime = parseFloat(split.epochTime)
				a.splits.push(split)
			}

			start = parseInt(splits_json.info.last) + 1
		}

		let splits_names = []
		for (let a of athletes) {
			a.splits.sort((a, b) => a.epochTime - b.epochTime)

			for (let split of a.splits) {
				if (splits_names.indexOf(split.label) == -1) splits_names.push(split.label)
			}
		}

		console.log(splits_names)

		let csvContent = "data:text/csv;charset=utf-8,"
		let csvFile = 'Name;Division;' + splits_names.join(";") + '\n'
		for (let a of athletes) {
			let splits = [a.name, a.division]
			for (let split_name of splits_names) {
				let split = a.splits.find(x => x.label == split_name)
				if (split) splits.push(split.time)
				else splits.push("-")
			}
			csvFile += splits.join(";") + "\n"
		}
		var blob = new Blob([csvFile], { type: 'text/csv;charset=utf-8;' });

		var link = document.createElement("a");
		var url = URL.createObjectURL(blob);
		link.setAttribute("href", url);
		link.setAttribute("download", "splits-pto-athletes-" + rtrt_name + ".csv");
		link.style.visibility = 'hidden';
		document.body.appendChild(link);
		link.click();
		document.body.removeChild(link);
		this.setState({ working: false })
	}

	importCSV = e => {
		let that = this
		let input = document.createElement('input')
		input.type = "file"
		input.addEventListener("change", (e) => {
			var fr = new FileReader()
			fr.onload = function () {
				let lines = fr.result.split("\n"
			)
				// if last line is blank, remove
				if (lines[lines.length - 1] == "") lines.pop()
				let columns = lines[0].split(';').join(',').split(',');

				let rankIndex = columns.indexOf("Place")
				let forenameIndex = columns.indexOf("First")
				let surnameIndex = columns.indexOf("Last")
				let divisionIndex = columns.indexOf("Division")
				let swimTimeIndex = columns.indexOf("Swim")
				let t1TimeIndex = columns.indexOf("T1")
				let countryIndex = columns.indexOf("Country")
				let bikeTimeIndex = columns.indexOf("Bike")
				let t2TimeIndex = columns.indexOf("T2")
				let runTimeIndex = columns.indexOf("Run")
				let overallTimeIndex = columns.indexOf("Overall")
				let swimPointsIndex = columns.indexOf("Swim Pts")
				let bikePointsIndex = columns.indexOf("Bike Pts")
				let runPointsIndex = columns.indexOf("Run Pts")
				let overallPointsIndex = columns.indexOf("Race Points")
				let rankingSystemIdIndex = columns.indexOf("Rank System")

				let athletes = []
				for (let i = 1; i < lines.length; i++) {
					let row = lines[i].split(';').join(',').split(',');
					let a = {
						pid: i,
						rank: row[rankIndex],
						first: row[forenameIndex],
						last: row[surnameIndex],
						country: row[countryIndex],
						division: row[divisionIndex] == "MPRO" ? "MPRO" : "FPRO",
						times: [
							getSeconds(row[swimTimeIndex] === "--:--" ? "00:00:00" : row[swimTimeIndex]),
							getSeconds(row[t1TimeIndex] === "--:--" ? "00:00:00" : row[t1TimeIndex]),
							getSeconds(row[bikeTimeIndex] === "--:--" ? "00:00:00" : row[bikeTimeIndex]),
							getSeconds(row[t2TimeIndex] === "--:--" ? "00:00:00" : row[t2TimeIndex]),
							getSeconds(row[runTimeIndex] === "--:--" ? "00:00:00" : row[runTimeIndex]),
							getSeconds(row[overallTimeIndex] === "--:--" ? "00:00:00" : row[overallTimeIndex]),
						],
						status: getSeconds(row[overallTimeIndex]) < 0 ? "DNF" : "Finish",
						swim_points: row[swimPointsIndex] === 'NULL' ? 0 : row[swimPointsIndex],
						bike_points: row[bikePointsIndex] === 'NULL' ? 0 : row[bikePointsIndex],
						run_points: row[runPointsIndex] 	=== 'NULL' ? 0 : row[runPointsIndex],
						overall_points: row[overallPointsIndex] === 'NULL' ? 0 : row[overallPointsIndex],
						ranking_system_id: row[rankingSystemIdIndex] === 1 ? 1 : 2
					}
					athletes.push(a)
				}

				let prev = null, rank = 1
				for (let a of athletes) {
					if (prev != a.division) {
						rank = 1
						prev = a.division
					}
					if (a.times[5] > 0) {
						a.rank = rank
						rank += 1
					}
				}

				that.setState({ rtrt_athletes: athletes }, () => that.matchNames())
			}
			fr.readAsText(e.target.files[0])
			e.target.value = ''
		})
		input.click()
	}

	loadAllAthletes = async () => {
		if (!this.db_athletes.athletes.length) {
			const athletes_fetch = await fetchAPI("/api/all_athletes/")
			this.db_athletes = await athletes_fetch.json()
			this.setState({})
		}
	}

	matchNames = async () => {
		await this.loadAllAthletes()

		for (let a of this.state.rtrt_athletes) {
			let fuzzy_athlete = null
			let name = a.name ? a.name : a.first + " " + a.last
			let db_athlete = this.db_athletes.athletes.find(x => x.name == name)
			if (!db_athlete) {
				let link = canonical(name)
				db_athlete = this.db_athletes.athletes.find(x => x.link == link)
			}
			if (!db_athlete) {
				for (let dba of this.db_athletes.athletes) {
					let ratio = fuzz.ratio(dba.name, name)
					if (ratio > 85) {
						fuzzy_athlete = dba
						break
					}
				}
			}
			a.db_athlete = db_athlete || fuzzy_athlete
		}
		this.setState({})
	}

	onChangeDBAthlete = e => {
		let value = e.target.value
		let pid = e.target.dataset.pid

		let rtrt_athlete = this.state.rtrt_athletes.find(x => x.pid == pid)
		let db_athlete = this.db_athletes.athletes.find(x => x.name == value)

		if (rtrt_athlete) rtrt_athlete.db_athlete = db_athlete
	}

	submitRTRTResults = async () => {
		let that = this
		let list_new = []
		for (let a of this.state.rtrt_athletes) {
			if (!a.db_athlete) list_new.push(a)
		}
		if (list_new.length) {
			let c = confirm("Are you sure you want to create the new athletes below:\n\n" + list_new.map(x => x.first + " " + x.last + " (" + x.country + ")").join("\n"))
			if (!c) return
		}
		const ranking_system_id = this.state.rtrt_athletes[0].ranking_system_id === 1 ? 1 : 2

		let results = []
		for (let a of this.state.rtrt_athletes) {
			results.push({
				athlete_id: a.db_athlete ? a.db_athlete.id : 0,
				name: a.name ? a.name : a.first + " " + a.last,
				fname: a.fname,
				lname: a.lname,
				first: a.first,
				last: a.last,
				country: a.country,
				country_iso: a.country_iso,
				division: a.division,
				rank: a.rank,
				swim: a.times[0],
				t1: a.times[1],
				bike: a.times[2],
				t2: a.times[3],
				run: a.times[4],
				overall: a.times[5],
				status: a.times[5] > 0 ? "Finish" : "DNF",
				swim_points: a.swim_points,
				bike_points: a.bike_points,
				run_points: a.run_points,
				overall_points: a.overall_points,
				ranking_system_id: a.ranking_system_id === 1 ? 1 : 2
			})
		}

		fetchAPI("/api/race/" + this.props.id + "/results", {
			method: "post",
			headers: { 'Content-Type': 'application/json' },
			body: JSON.stringify({
				ranking_system_id: ranking_system_id,
				results: results
			})
		})
			.then(function (response) {
				if (response.ok) {
					return response.json();
				}
				return Promise.reject(response);
			})
			.then(function (data) {
				that.calculatePoints(data.results, ranking_system_id)
			})
	}

	calculatePoints = (results, ranking_system_id) => {
		let that = this
		fetchAPI("/api/race/" + this.props.id + "/update_points", {
			method: "post",
			headers: { 'Content-Type': 'application/json' },
			body: JSON.stringify({
				ranking_system_id: ranking_system_id,
				results: results
			})
		})
			.then(function (response) {
				if (response.ok) {
					return response.json();
				}
				return Promise.reject(response);
			})
			.then(function (data) {
				that.calculateSof(that.props.id)

				that.goToTab("results")
				that.loadRace(that.props.id)
			})
	}

	onClickTab = e => {
		this.goToTab(e.target.dataset.tab)
	}

	goToTab(tab) {
		location.hash = "#" + tab

		if (tab == 'participants') {
			this.loadAllAthletes()
		}

		this.setState({ tab: tab })
	}

	onClickDeleteAllResults = e => {
		let that = this
		let c = prompt("Type 'DELETE ALL' to delete all the results from this race", "")
		if (c == "DELETE ALL") {
			fetchAPI("/api/race/" + this.props.id + "/delete_all_results", {
				method: "post",
				headers: { 'Content-Type': 'application/json' },
			})
				.then(function (response) { if (response.ok) return response.json(); return Promise.reject(response); })
				.then(function (data) {
					that.loadRace(that.props.id)
				})
		}
	}

	onClickDeleteRace = e => {
		let that = this
		let c = prompt("Type 'DELETE' to delete this race", "")
		if (c == "DELETE") {
			fetchAPI("/api/race/" + this.props.id + "/delete", {
				method: "post",
				headers: { 'Content-Type': 'application/json' },
			})
				.then(function (response) { if (response.ok) return response.json(); return Promise.reject(response); })
				.then(function (data) {
					location = '/races'
				})
		}
	}

	loadRace(race_id) {
		let that = this

		this.race_id = race_id

		fetchAPI("/api/race/" + race_id, {
			headers: { 'Content-Type': 'application/json' },
		})
			.then(function(response) { if(response.ok) return response.json(); return Promise.reject(response); })
			.then(function(data) {
				that.setState({ data: data })
			})
	}

	onInputText = e => {
		if (!e.target.validity.valid) {
			return
		}
		let attr = e.target.dataset.attr || e.target.name;
		let value = e.target.value

		if (attr == "rtrt_name" && value != "") {
			if (!this.state.rtrt_events.find(x => x.name == value)) return
		}

		if (value == "") {
			value = null
		}

		this.attributes[attr] = value

		let dataTemp = this.state.data
		dataTemp.race[attr] = value
		this.setState({ data: dataTemp })

		if (attr == "rtrt_name") {
			this.loadRTRTRaceInfo()
		}

		this.save()
	}

	save = (options) => {
		let that = this

		if (this.save_timeout) {
			clearTimeout(this.save_timeout)
		}
		this.save_timeout = setTimeout((that) => {
			let attributes = []
			for (let a in this.attributes) {
				attributes.push({ name: a, value: this.attributes[a] })
			}
			this.attributes = []

			fetchAPI("/api/race/" + this.props.id + "/attributes", {
				method: "post",
				headers: { 'Content-Type': 'application/json' },
				body: JSON.stringify({ attributes: attributes })
			})
				.then(function (response) { if (response.ok) return response.json(); return Promise.reject(response); })
				.then(function (data) { })

			that.save_timeout = null;
		}, 250, this);
	}

	emailParticipants = e => {
		let race = this.state.data.race

		console.log(this.state.data.participants.filter(x => x.email).map(x => x.email))

		let csvContent = "data:text/csv;charset=utf-8,"
		let csvFile = 'Rank;Points;Division;Name;Email\n'
		for (let a of this.state.data.participants) {
			let splits = [a.overall_rank, a.overall_points, a.division, a.name, a.email]
			csvFile += splits.join(";") + "\n"
		}
		var blob = new Blob([csvFile], { type: 'text/csv;charset=utf-8;' });

		var link = document.createElement("a");
		var url = URL.createObjectURL(blob);
		link.setAttribute("href", url);
		link.setAttribute("download", "participants-" + race.year + " " + race.serie + ".csv");
		link.style.visibility = 'hidden';
		document.body.appendChild(link);
		link.click();
		document.body.removeChild(link);
	}

	exportFinalResults = e => {
		let race = this.state.data.race
		let points = this.state.data.points
		let csvFile = 'Place;First;Last;Division;Country;Swim;T1;Bike;T2;Run;Overall;Swim Pts;Bike Pts;Run Pts;Race Points;Rank System\n'
		
		for (let a of this.state.data.results) {
			let pts = points.find(p => p.result_id == a.result_id)

			let splits = [
				a.rank,
				a.first,
				a.last,
				a.division,
				a.country,
				secondsToTime(a.swim, true),
				secondsToTime(a.t1, true),
				secondsToTime(a.bike, true),
				secondsToTime(a.t2, true),
				secondsToTime(a.run, true),
				secondsToTime(a.overall, true),
				pts && pts.swim_points && pts.swim_points.toFixed(2),
				pts && pts.bike_points && pts.bike_points.toFixed(2),
				pts && pts.run_points && pts.run_points.toFixed(2),
				pts && pts.overall_points && pts.overall_points.toFixed(2),
				a.ranking_system
			]

			csvFile += splits.join(";") + "\n"
		}
		var blob = new Blob([csvFile], { type: 'text/csv;charset=utf-8;' });

		var link = document.createElement("a");
		var url = URL.createObjectURL(blob);
		link.setAttribute("href", url);
		link.setAttribute("download", "results-" + race.year + " " + race.serie + ".csv");
		link.style.visibility = 'hidden';
		document.body.appendChild(link);
		link.click();
		document.body.removeChild(link);
	}

	handleDayChange = e => {
		console.log(e.target.value)
	}

	fetchParticipants = (e) => {
		let link = this.state.data.race['active_link']
		fetchAPI("/api/race/active_participants", {
			method: "post",
			headers: { 'Content-Type': 'application/json' },
			body: JSON.stringify({ link: link })
		})
		console.log(link)
	}

	onAddParticipant = (athlete_id, calculate_sof = true) => {
		if (this.state.data.participants.find(x => x.id == athlete_id)) {
			return
		}

		let that = this
		fetchAPI("/api/race/" + this.props.id + "/add_participant/" + athlete_id, {
			method: "post",
			headers: { 'Content-Type': 'application/json' },
		})
			.then(function (response) {
				if (response.ok) {
					return response.json();
				}

				return Promise.reject(response);
			})
			.then(function(data) {
				let dataTemp  = that.state.data
				dataTemp.participants = data.participants
				that.setState({ data: dataTemp })

				that.calculateSof(that.props.id)
			})
	}

	onRemoveParticipant = e => {
		let that = this
		fetchAPI("/api/race/" + this.props.id + "/remove_participant/" + e.target.closest(".remove").dataset.athlete_id, {
			method: "post",
			headers: { 'Content-Type': 'application/json' },
		})
			.then(function(response) { if(response.ok) return response.json(); return Promise.reject(response); })
			.then(function(data) {
				let dataTemp  = that.state.data
				dataTemp.participants = data.participants
				that.setState({ data: dataTemp })
				that.calculateSof(that.props.id)
			})
	}

	importParticipantsCSV = async (e) => {
		await this.loadAllAthletes()

		let that = this
		let input = document.createElement('input')
		input.type = "file"
		input.addEventListener("change", (e) => {
			var fr = new FileReader()
			fr.onload = function () {
				let lines = fr.result.split("\n")
				let columns = lines[0].split(";")

				let firstIndex = columns.indexOf("firstname")
				let lastIndex = columns.indexOf("lastname")
				let countryIndex = columns.indexOf("nation")
				let divisionIndex = columns.indexOf("division")

				let athletes = []
				for (let i = 1; i < lines.length; i++) {
					let row = lines[i].split(";")
					let a = {
						fname: row[firstIndex],
						lname: row[lastIndex],
						name: row[firstIndex] + " " + row[lastIndex],
						country: row[countryIndex],
						division: row[divisionIndex] == "MPRO" ? "MPRO" : "FPRO",
					}

					let fuzzy_athlete = null
					let name = a.name ? a.name : a.first + " " + a.last
					let db_athlete = that.db_athletes.athletes.find(x => x.name == name)
					if (!db_athlete) {
						let link = canonical(name)
						db_athlete = that.db_athletes.athletes.find(x => x.link == link)
					}
					if (!db_athlete) {
						for (let dba of that.db_athletes.athletes) {
							let ratio = fuzz.ratio(dba.name, name)
							if (ratio > 85) {
								fuzzy_athlete = dba
								break
							}
						}
					}
					if (db_athlete) {
						that.onAddParticipant(db_athlete.id, false)
					}
					else {
						athletes.push(a)
					}
				}
				let alert = null
				if (athletes.length) {
					alert = "Couldn't add the following athletes:\n\n" + athletes.map(x => x.name + " (" + x.country + ")").join("\n")
				}
				that.setState({ warning_import_participants: alert })

				that.calculateSof(that.props.id)
			}
			fr.readAsText(e.target.files[0])
			e.target.value = ''
		})
		input.click()
	}

	calculateSof(raceId) {
		fetchAPI("/api/race/" + raceId + "/calculate_sof", {
			method: "post",
			headers: { 'Content-Type': 'application/json' },
		})
			.then(function (response) {
				if (response.ok) {
					return response.json();
				}

				return Promise.reject(response);
			})
	}

	async fetchSeasons() {
		fetchAPI("/api/t100/seasons?is_active=1", { headers: { 'Content-Type': 'application/json' } })
			.then(response => response.json())
			.then(data => this.setState({ seasons: data.seasons }))
			.catch(error => alert(error.message));
	}

	async fetchChampionships() {
		fetchAPI("/api/t100/championships?is_active=1", { headers: { 'Content-Type': 'application/json' } })
			.then(response => response.json())
			.then(data => this.setState({ championships: data.championships }))
			.catch(error => alert(error.message));
	}

	async removeSectorImage(name) {
		let that = this
		fetchAPI("/api/race/" + this.props.id + '/attributes', {
			method: "delete",
			headers: { 'Content-Type': 'application/json' },
			body: JSON.stringify({ attributes: [{ name: name}] })
		})
			.then(function(response) {
				if (response.ok) {
					return response.json();
				}
				return Promise.reject(response);
			})
			.then(() => {
				that.loadRace(that.race_id)
			})
	}

	imageToBlob(image, mime) {
		var byteString = atob(image.split(',')[1])
		var ab = new ArrayBuffer(byteString.length)
		var ia = new Uint8Array(ab)
		for (var i = 0; i < byteString.length; i++) {
			ia[i] = byteString.charCodeAt(i)
		}
		var bb = new Blob([ab], { type: mime })
		return bb
	}

	async uploadFile(file, attributeName) {
		let that = this
		let mime = 'image/png'
		let blob = this.imageToBlob(file, mime)
		let hash = md5(blob)

		this.setState({ working: true })

		let now = Date.now()
		
		fetchAPI("/api/createuploadurl?md5=false", {
			method: "post",
			headers: { 'Content-Type': 'application/json' },
			body: JSON.stringify({ name: `${this.state.data.race.name.toLowerCase().replace(" ","-")}${attributeName}.png`, size: blob.size, type: mime, lastModified: parseInt(now / 1000), md5: hash })
		})
			.then( response => response.json())
			.then(function(data_uploadurl) {
				let xhr = new window.XMLHttpRequest()
				xhr.open('put', data_uploadurl.put)
				xhr.onload = () => {
					fetchAPI("/api/convert-image", {
						method: "post",
						headers: { 'Content-Type': 'application/json' },
						body: JSON.stringify({ file: data_uploadurl })
					})
						.then(response => response.json())
						.then(data_convert => {
							let media = data_convert.media
							fetchAPI("/api/race/" + that.race_id + "/attributes", {
								method: "post",
								headers: { 'Content-Type': 'application/json' },
								body: JSON.stringify({ attributes: [{ name: attributeName, value: media.id }] })
							})
								.then(() => {
									that.loadRace(that.race_id)
									that.setState({ working: false })
								})
						})
				}
				xhr.onerror = () => { reject() }
				xhr.setRequestHeader('Content-Type', data_uploadurl.type)
				xhr.setRequestHeader('Cache-Control', data_uploadurl.cc)
				xhr.send(blob);
			})
	}

	handleFileUpload = (attributeName) => {
		let that = this
		let input = document.createElement('input')
		input.type = "file"
		input.accept = "image/*"

		input.addEventListener("change", (event) => {
			that.current_file_pic = event.target.files[0]
			var fr = new FileReader()
			fr.onloadend = function () {
				that.uploadFile(fr.result, attributeName)
			}
			fr.readAsDataURL(event.target.files[0])
			event.target.value = ''
		})
		input.click()
	}

	render(props, state) {
		if(!this.state.data) return
		let race = this.state.data.race
		let results = this.state.data.results
		let participants = this.state.data.participants
		let points = this.state.data.points
		let participants_emails = this.state.data.participants.filter(x => x.email).map(x => x.email)

		const sectorBikeDesktopImage = race?.sectors_bike_lg || null
		const sectorBikeMobileImage = race?.sectors_bike_sm || null
		const sectorRunDesktopImage = race?.sectors_run_lg || null
		const sectorRunMobileImage = race?.sectors_run_sm || null

		const locales = [
			{ abbreviation: 'ACDT', description: "Australian Central Daylight Time: UTC +10:30" },
			{ abbreviation: 'ACST', description: "Australian Central Standard Time: UTC +9:30" },
			{ abbreviation: 'ACT', description: "Acre Time: UTC -5" },
			{ abbreviation: 'ADT', description: "Atlantic Daylight Time: UTC -3" },
			{ abbreviation: 'AEDT', description: "Australian Eastern Daylight Time: UTC +11" },
			{ abbreviation: 'AEST', description: "Australian Eastern Standard Time: UTC +10" },
			{ abbreviation: 'AET', description: "Australian Eastern Time: UTC +10" },
			{ abbreviation: 'AFT', description: "Afghanistan Time: UTC +4:30" },
			{ abbreviation: 'AKDT', description: "Alaska Daylight Time: UTC -8" },
			{ abbreviation: 'AKST', description: "Alaska Standard Time: UTC -9" },
			{ abbreviation: 'ALMT', description: "Alma-Ata Time: UTC +6" },
			{ abbreviation: 'AMST', description: "Amazon Summer Time: UTC -3" },
			{ abbreviation: 'AMT', description: "Amazon Time: UTC -4" },
			{ abbreviation: 'ANAST', description: "Anadyr Summer Time: UTC +12" },
			{ abbreviation: 'ANAT', description: "Anadyr Time: UTC +12" },
			{ abbreviation: 'AQTT', description: "Aqtobe Time: UTC +5" },
			{ abbreviation: 'ART', description: "Argentina Time: UTC -3" },
			{ abbreviation: 'AST', description: "Atlantic Standard Time: UTC -4" },
			{ abbreviation: 'AT', description: "Atlantic Time: UTC -4" },
			{ abbreviation: 'AWDT', description: "Australian Western Daylight Time: UTC +9" },
			{ abbreviation: 'AWST', description: "Australian Western Standard Time: UTC +8" },
			{ abbreviation: 'AZOST', description: "Azores Summer Time: UTC +0" },
			{ abbreviation: 'AZOT', description: "Azores Time: UTC -1" },
			{ abbreviation: 'AZT', description: "Azerbaijan Time: UTC +4" },
			{ abbreviation: 'BNT', description: "Brunei Time: UTC +8" },
			{ abbreviation: 'BOT', description: "Bolivia Time: UTC -4" },
			{ abbreviation: 'BRST', description: "Brasília Summer Time: UTC -2" },
			{ abbreviation: 'BRT', description: "Brasília Time: UTC -3" },
			{ abbreviation: 'BST', description: "Bangladesh Standard Time: UTC +6" },
			{ abbreviation: 'CAT', description: "Central Africa Time: UTC +2" },
			{ abbreviation: 'CCT', description: "Cocos Islands Time: UTC +6:30" },
			{ abbreviation: 'CDT', description: "Central Daylight Time: UTC -5" },
			{ abbreviation: 'CEST', description: "Central European Summer Time: UTC +2" },
			{ abbreviation: 'CET', description: "Central European Time: UTC +1" },
			{ abbreviation: 'CHADT', description: "Chatham Daylight Time: UTC +13:45" },
			{ abbreviation: 'CHAST', description: "Chatham Standard Time: UTC +12:45" },
			{ abbreviation: 'CHOST', description: "Choibalsan Summer Time: UTC +9" },
			{ abbreviation: 'CHOT', description: "Choibalsan Time: UTC +8" },
			{ abbreviation: 'CHST', description: "Chamorro Standard Time: UTC +10" },
			{ abbreviation: 'CHUT', description: "Chuuk Time: UTC +10" },
			{ abbreviation: 'CIST', description: "Clipperton Island Standard Time: UTC -8" },
			{ abbreviation: 'CIT', description: "Central Indonesia Time: UTC +8" },
			{ abbreviation: 'CKT', description: "Cook Islands Time: UTC -10" },
			{ abbreviation: 'CLST', description: "Chile Summer Time: UTC -3" },
			{ abbreviation: 'CLT', description: "Chile Standard Time: UTC -4" },
			{ abbreviation: 'COST', description: "Colombia Summer Time: UTC -4" },
			{ abbreviation: 'COT', description: "Colombia Time: UTC -5" },
			{ abbreviation: 'CST', description: "Central Standard Time: UTC -6" },
			{ abbreviation: 'CVT', description: "Cape Verde Time: UTC -1" },
			{ abbreviation: 'CWST', description: "Central Western Standard Time: UTC +8:45" },
			{ abbreviation: 'DAVT', description: "Davis Time: UTC +7" },
			{ abbreviation: 'DDUT', description: "Dumont-d'Urville Time: UTC +10" },
			{ abbreviation: 'EASST', description: "Easter Island Summer Time: UTC -5" },
			{ abbreviation: 'EAST', description: "Easter Island Standard Time: UTC -6" },
			{ abbreviation: 'EAT', description: "East Africa Time: UTC +3" },
			{ abbreviation: 'ECT', description: "Ecuador Time: UTC -5" },
			{ abbreviation: 'EDT', description: "Eastern Daylight Time: UTC -4" },
			{ abbreviation: 'EEST', description: "Eastern European Summer Time: UTC +3" },
			{ abbreviation: 'EET', description: "Eastern European Time: UTC +2" },
			{ abbreviation: 'EGST', description: "Eastern Greenland Summer Time: UTC +0" },
			{ abbreviation: 'EGT', description: "Eastern Greenland Time: UTC -1" },
			{ abbreviation: 'EIT', description: "Eastern Indonesian Time: UTC +9" },
			{ abbreviation: 'EST', description: "Eastern Standard Time: UTC -5" },
			{ abbreviation: 'FET', description: "Further Eastern European Time: UTC +3" },
			{ abbreviation: 'FJST', description: "Fiji Summer Time: UTC +13" },
			{ abbreviation: 'FJT', description: "Fiji Time: UTC +12" },
			{ abbreviation: 'FKST', description: "Falkland Islands Summer Time: UTC -3" },
			{ abbreviation: 'FKT', description: "Falkland Islands Time: UTC -4" },
			{ abbreviation: 'FNT', description: "Fernando de Noronha Time: UTC -2" },
			{ abbreviation: 'GALT', description: "Galapagos Time: UTC -6" },
			{ abbreviation: 'GAMT', description: "Gambier Time: UTC -9" },
			{ abbreviation: 'GET', description: "Georgia Standard Time: UTC +4" },
			{ abbreviation: 'GFT', description: "French Guiana Time: UTC -3" },
			{ abbreviation: 'GILT', description: "Gilbert Islands Time: UTC +12" },
			{ abbreviation: 'GIT', description: "Gambier Islands Time: UTC -9" },
			{ abbreviation: 'GMT', description: "Greenwich Mean Time: UTC +0" },
			{ abbreviation: 'GST', description: "Gulf Standard Time: UTC +4" },
			{ abbreviation: 'GYT', description: "Guyana Time: UTC -4" },
			{ abbreviation: 'HAA', description: "Atlantic Daylight Time: UTC -3" },
			{ abbreviation: 'HAC', description: "Azores Time: UTC -1" },
			{ abbreviation: 'HADT', description: "Hawaii-Aleutian Daylight Time: UTC -9" },
			{ abbreviation: 'HAE', description: "Eastern Daylight Time: UTC -4" },
			{ abbreviation: 'HAP', description: "Acre Time: UTC -5" },
			{ abbreviation: 'HAR', description: "Armenia Time: UTC +4" },
			{ abbreviation: 'HAST', description: "Hawaii-Aleutian Standard Time: UTC -10" },
			{ abbreviation: 'HAT', description: "Atlantic Time: UTC -4" },
			{ abbreviation: 'HAY', description: "Haymatgyy Time: UTC +4" },
			{ abbreviation: 'HKT', description: "Hong Kong Time: UTC +8" },
			{ abbreviation: 'HLV', description: "Indochina Time: UTC +7" },
			{ abbreviation: 'HNA', description: "Heard and McDonald Islands Time: UTC +5" },
			{ abbreviation: 'HNC', description: "Cocos Islands Time: UTC +6:30" },
			{ abbreviation: 'HNE', description: "Acre Time: UTC -5" },
			{ abbreviation: 'HNF', description: "Atlantic Time: UTC -4" },
			{ abbreviation: 'HNG', description: "Acre Time: UTC -5" },
			{ abbreviation: 'HNI', description: "Acre Time: UTC -5" },
			{ abbreviation: 'HNJ', description: "Atlantic Time: UTC -4" },
			{ abbreviation: 'HNM', description: "Atlantic Time: UTC -4" },
			{ abbreviation: 'HNN', description: "Atlantic Time: UTC -4" },
			{ abbreviation: 'HNP', description: "Acre Time: UTC -5" },
			{ abbreviation: 'HNR', description: "Armenia Time: UTC +4" },
			{ abbreviation: 'HNS', description: "Atlantic Time: UTC -4" },
			{ abbreviation: 'HNT', description: "Acre Time: UTC -5" },
			{ abbreviation: 'HNU', description: "Acre Time: UTC -5" },
			{ abbreviation: 'HNY', description: "Acre Time: UTC -5" },
			{ abbreviation: 'HOVT', description: "Hovd Time: UTC +7" },
			{ abbreviation: 'HST', description: "Hawaii-Aleutian Standard Time: UTC -10" },
			{ abbreviation: 'IOT', description: "Indian Chagos Time: UTC +6" },
			{ abbreviation: 'IRDT', description: "Iran Daylight Time: UTC +4:30" },
			{ abbreviation: 'IRKST', description: "Irkutsk Summer Time: UTC +9" },
			{ abbreviation: 'IRKT', description: "Irkutsk Time: UTC +8" },
			{ abbreviation: 'IRST', description: "Iran Standard Time: UTC +3:30" },
			{ abbreviation: 'IST', description: "Indian Standard Time: UTC +5:30" },
			{ abbreviation: 'JST', description: "Japan Standard Time: UTC +9" },
			{ abbreviation: 'KGT', description: "Kyrgyzstan Time: UTC +6" },
			{ abbreviation: 'KOST', description: "Kosrae Time: UTC +11" },
			{ abbreviation: 'KRAT', description: "Krasnoyarsk Time: UTC +7" },
			{ abbreviation: 'KST', description: "Korea Standard Time: UTC +9" },
			{ abbreviation: 'LHDT', description: "Lord Howe Daylight Time: UTC +11" },
			{ abbreviation: 'LHST', description: "Lord Howe Standard Time: UTC +10:30" },
			{ abbreviation: 'LINT', description: "Line Islands Time: UTC +14" },
			{ abbreviation: 'MAGST', description: "Magadan Summer Time: UTC +12" },
			{ abbreviation: 'MAGT', description: "Magadan Time: UTC +11" },
			{ abbreviation: 'MART', description: "Marquesas Time: UTC -9:30" },
			{ abbreviation: 'MAWT', description: "Mawson Time: UTC +5" },
			{ abbreviation: 'MDT', description: "Mountain Daylight Time: UTC -6" },
			{ abbreviation: 'MET', description: "Middle European Time: UTC +1" },
			{ abbreviation: 'MHT', description: "Marshall Islands Time: UTC +12" },
			{ abbreviation: 'MIST', description: "Marshall Islands Summer Time: UTC +12" },
			{ abbreviation: 'MIT', description: "Marquesas Islands Time: UTC -9:30" },
			{ abbreviation: 'MMT', description: "Myanmar Time: UTC +6:30" },
			{ abbreviation: 'MSD', description: "Moscow Daylight Time: UTC +4" },
			{ abbreviation: 'MSK', description: "Moscow Standard Time: UTC +3" },
			{ abbreviation: 'MST', description: "Mountain Standard Time: UTC -7" },
			{ abbreviation: 'MUT', description: "Mauritius Time: UTC +4" },
			{ abbreviation: 'MVT', description: "Maldives Time: UTC +5" },
			{ abbreviation: 'MYT', description: "Malaysia Time: UTC +8" },
			{ abbreviation: 'NCT', description: "New Caledonia Time: UTC +11" },
			{ abbreviation: 'NDT', description: "Newfoundland Daylight Time: UTC -2:30" },
			{ abbreviation: 'NFT', description: "Norfolk Island Time: UTC +11:30" },
			{ abbreviation: 'NPT', description: "Nepal Time: UTC +5:45" },
			{ abbreviation: 'NST', description: "Newfoundland Standard Time: UTC -3:30" },
			{ abbreviation: 'NT', description: "Newfoundland Time: UTC -3:30" },
			{ abbreviation: 'NUT', description: "Niue Time: UTC -11" },
			{ abbreviation: 'NZDT', description: "New Zealand Daylight Time: UTC +13" },
			{ abbreviation: 'NZST', description: "New Zealand Standard Time: UTC +12" },
			{ abbreviation: 'OMSST', description: "Omsk Summer Time: UTC +7" },
			{ abbreviation: 'OMST', description: "Omsk Standard Time: UTC +7" },
			{ abbreviation: 'ORAT', description: "Oral Time: UTC +5" },
			{ abbreviation: 'PDT', description: "Pacific Daylight Time: UTC -7" },
			{ abbreviation: 'PET', description: "Peru Time: UTC -5" },
			{ abbreviation: 'PETST', description: "Kamchatka Summer Time: UTC +12" },
			{ abbreviation: 'PETT', description: "Kamchatka Time: UTC +12" },
			{ abbreviation: 'PGT', description: "Papua New Guinea Time: UTC +10" },
			{ abbreviation: 'PHOT', description: "Phoenix Islands Time: UTC +13" },
			{ abbreviation: 'PHT', description: "Philippine Time: UTC +8" },
			{ abbreviation: 'PKT', description: "Pakistan Standard Time: UTC +5" },
			{ abbreviation: 'PMDT', description: "Pierre & Miquelon Daylight Time: UTC -2" },
			{ abbreviation: 'PMST', description: "Pierre & Miquelon Standard Time: UTC -3" },
			{ abbreviation: 'PONT', description: "Pohnpei Standard Time: UTC +11" },
			{ abbreviation: 'PST', description: "Pacific Standard Time: UTC -8" },
			{ abbreviation: 'PT', description: "Pacific Time: UTC -8" },
			{ abbreviation: 'PWT', description: "Palau Time: UTC +9" },
			{ abbreviation: 'PYST', description: "Paraguay Summer Time: UTC -3" },
			{ abbreviation: 'PYT', description: "Paraguay Time: UTC -4" },
			{ abbreviation: 'RET', description: "Reunion Time: UTC +4" },
			{ abbreviation: 'ROTT', description: "Rothera Time: UTC -3" },
			{ abbreviation: 'SAKT', description: "Sakhalin Time: UTC +11" },
			{ abbreviation: 'SAMT', description: "Samara Time: UTC +4" },
			{ abbreviation: 'SAST', description: "South Africa Standard Time: UTC +2" },
			{ abbreviation: 'SBT', description: "Solomon Islands Time: UTC +11" },
			{ abbreviation: 'SCT', description: "Seychelles Time: UTC +4" },
			{ abbreviation: 'SDT', description: "Samoa Daylight Time: UTC -10" },
			{ abbreviation: 'SGT', description: "Singapore Time: UTC +8" },
			{ abbreviation: 'SLST', description: "Sri Lanka Standard Time: UTC +5:30" },
			{ abbreviation: 'SRET', description: "Srednekolymsk Time: UTC +11" },
			{ abbreviation: 'SRT', description: "Suriname Time: UTC -3" },
			{ abbreviation: 'SST', description: "Samoa Standard Time: UTC -11" },
			{ abbreviation: 'SYOT', description: "Syowa Time: UTC +3" },
			{ abbreviation: 'TAHT', description: "Tahiti Time: UTC -10" },
			{ abbreviation: 'THA', description: "Thailand Standard Time: UTC +7" },
			{ abbreviation: 'TJT', description: "Tajikistan Time: UTC +5" },
			{ abbreviation: 'TKT', description: "Tokelau Time: UTC +13" },
			{ abbreviation: 'TLT', description: "Timor Leste Time: UTC +9" },
			{ abbreviation: 'TMT', description: "Turkmenistan Time: UTC +5" },
			{ abbreviation: 'TRT', description: "Turkey Time: UTC +3" },
			{ abbreviation: 'TOT', description: "Tonga Time: UTC +13" },
			{ abbreviation: 'TVT', description: "Tuvalu Time: UTC +12" },
			{ abbreviation: 'ULAST', description: "Ulaanbaatar Summer Time: UTC +9" },
			{ abbreviation: 'ULAT', description: "Ulaanbaatar Standard Time: UTC +8" },
			{ abbreviation: 'UTC', description: "Coordinated Universal Time: UTC +0" },
			{ abbreviation: 'UYST', description: "Uruguay Summer Time: UTC -2" },
			{ abbreviation: 'UYT', description: "Uruguay Standard Time: UTC -3" },
			{ abbreviation: 'UZT', description: "Uzbekistan Time: UTC +5" },
			{ abbreviation: 'VET', description: "Venezuelan Standard Time: UTC -4" },
			{ abbreviation: 'VLAST', description: "Vladivostok Summer Time: UTC +11" },
			{ abbreviation: 'VLAT', description: "Vladivostok Time: UTC +10" },
			{ abbreviation: 'VOST', description: "Vostok Time: UTC +6" },
			{ abbreviation: 'VUT', description: "Vanuatu Time: UTC +11" },
			{ abbreviation: 'WAKT', description: "Wake Island Time: UTC +12" },
			{ abbreviation: 'WAST', description: "West Africa Summer Time: UTC +2" },
			{ abbreviation: 'WAT', description: "West Africa Time: UTC +1" },
			{ abbreviation: 'WEST', description: "Western European Summer Time: UTC +1" },
			{ abbreviation: 'WET', description: "Western European Time: UTC +0" },
			{ abbreviation: 'WIT', description: "Eastern Indonesia Time: UTC +9" },
			{ abbreviation: 'WITA', description: "Central Indonesia Time: UTC +8" },
			{ abbreviation: 'WST', description: "Western Standard Time: UTC +8" },
			{ abbreviation: 'YAKST', description: "Yakutsk Summer Time: UTC +10" },
			{ abbreviation: 'YAKT', description: "Yakutsk Time: UTC +9" },
			{ abbreviation: 'YAPT', description: "Yap Time: UTC +10" }
		]

		return(
			<div class='race-info'>
				<h2 class='headline'>{race.year + " " + race.serie}</h2>
				<div class='tabs'>
					{this.tabs.map(t => {
						return (<div class='tab' selected={t.tab == state.tab} data-tab={t.tab} onClick={this.onClickTab}>{t.name}</div>)
					})}
				</div>
				{ state.tab == 'info' &&
					<Fragment>
						<div class='card'>
							<table>
								<tr>
									<td>Brand</td>
									<td><input class="form-control form-control-sm" onInput={this.onInputText} onChange={this.onInputText}
														 value={race.brand}
														 data-attr="brand"
									/></td>
								</tr>
								<tr>
									<td>Serie</td>
									<td>
										<div className='select'>
											<select className="form-control form-control-sm" name="serie" onInput={this.onInputText}>
												{state.series.map(serie => {
													return <option value={serie.id} selected={race.serie === serie.name}>{serie.name}</option>
												})}
											</select>
										</div>
									</td>
								</tr>
								<tr>
									<td>Division</td>
									<td>
										<div className='select'>
											<select className="form-control form-control-sm" name="division" onInput={this.onInputText}>
												<option value="MPRO" selected={race.division.toLowerCase() === "mpro"}>MPRO</option>
												<option value="FPRO" selected={race.division.toLowerCase() === "fpro"}>FPRO</option>
												<option value="BOTH" selected={race.division.toLowerCase() === "both"}>Both</option>
											</select>
										</div>
									</td>
								</tr>
								<tr>
									<td>Distance</td>
									<td>
										<div className='select'>
											<select className="form-control form-control-sm" onInput={this.onInputText}
															onChange={this.onInputText}
															value={race.distance_type}
															data-attr="distance_type">
												<option value="0">N/A</option>
												<option value="1">70.3</option>
												<option value="2">140.6</option>
												<option value="3">100K</option>
												<option value="4">Other Long</option>
												<option value="5">Other Middle</option>
											</select>
										</div>
									</td>
								</tr>
								<tr>
									<td>Course Shortened?</td>
									<td>
										<div className='select'>
											<select className='form-control form-control-sm' data-attr="course_shortened"
															onInput={this.onInputText} onChange={this.onInputText}>
												<option value={1} selected={race.course_shortened == 1}>Yes</option>
												<option value={0} selected={race.course_shortened == 0}>No</option>
											</select>
										</div>
									</td>
								</tr>
								<tr>
									<td>RTRT Event ID</td>
									<td>
										<input className="form-control form-control-sm"
													 onInput={this.onInputText}
													 onChange={this.onInputText}
													 value={race.rtrt_event_id}
													 data-attr="rtrt_event_id"/>
									</td>
								</tr>
								<tr>
									<td>Course: Disciplines</td>
									<td>
										<div className='select'>
											<select className="form-control form-control-sm" name="course_disciplines"
															onInput={this.onInputText}>
												<option value="SBR" selected={race.course_disciplines === "SBR"}>Swim, Bike, Run</option>
												<option value="RBR" selected={race.course_disciplines === "RBR"}>Run, Bike, Run</option>
											</select>
										</div>
									</td>
								</tr>
								<tr>
									<td>Distance Course</td>
									<td>
										<input className="form-control form-control-sm"
													 onInput={this.onInputText}
													 onChange={this.onInputText}
													 value={race.distance_course}
													 data-attr="distance_course"
										/>
									</td>
								</tr>
								<tr>
									<td>Distance Course: Swim</td>
									<td>
										<input className="form-control form-control-sm"
													 onInput={this.onInputText}
													 onChange={this.onInputText}
													 value={race.distance_course_swim}
													 data-attr="distance_course_swim"
										/>
									</td>
								</tr>
								<tr>
									<td>Distance Course: T1</td>
									<td>
										<input className="form-control form-control-sm"
													 onInput={this.onInputText}
													 onChange={this.onInputText}
													 value={race.distance_course_t1}
													 data-attr="distance_course_t1"
										/>
									</td>
								</tr>
								<tr>
									<td>Distance Course: Bike</td>
									<td>
										<input className="form-control form-control-sm"
													 onInput={this.onInputText}
													 onChange={this.onInputText}
													 value={race.distance_course_bike}
													 data-attr="distance_course_bike"
										/>
									</td>
								</tr>
								<tr>
									<td>Distance Course: T2</td>
									<td>
										<input className="form-control form-control-sm"
													 onInput={this.onInputText}
													 onChange={this.onInputText}
													 value={race.distance_course_t2}
													 data-attr="distance_course_t2"
										/>
									</td>
								</tr>
								<tr>
									<td>Distance Course: Run</td>
									<td>
										<input className="form-control form-control-sm"
													 onInput={this.onInputText}
													 onChange={this.onInputText}
													 value={race.distance_course_run}
													 data-attr="distance_course_run"
										/>
									</td>
								</tr>
								<tr>
									<td>Sectors: Swim</td>
									<td>
										<input className="form-control form-control-sm"
													 onInput={this.onInputText}
													 onChange={this.onInputText}
													 value={race.sectors_swim}
													 data-attr="sectors_swim"
										/>
									</td>
								</tr>
								<tr>
									<td>Sectors: Bike</td>
									<td>
										<input className="form-control form-control-sm"
													 onInput={this.onInputText}
													 onChange={this.onInputText}
													 value={race.sectors_bike}
													 data-attr="sectors_bike"
										/>
									</td>
								</tr>
								<tr>
									<td>Sectors: Run</td>
									<td>
										<input className="form-control form-control-sm"
													 onInput={this.onInputText}
													 onChange={this.onInputText}
													 value={race.sectors_run}
													 data-attr="sectors_run"
										/>
									</td>
								</tr>
								<tr>
									<td>Laps: Swim</td>
									<td>
										<input className="form-control form-control-sm"
													 onInput={this.onInputText}
													 onChange={this.onInputText}
													 value={race.laps_swim}
													 data-attr="laps_swim"
										/>
									</td>
								</tr>
								<tr>
									<td>Laps: Bike</td>
									<td>
										<input className="form-control form-control-sm"
													 onInput={this.onInputText}
													 onChange={this.onInputText}
													 value={race.laps_bike}
													 data-attr="laps_bike"
										/>
									</td>
								</tr>
								<tr>
									<td>Laps: Run</td>
									<td>
										<input className="form-control form-control-sm"
													 onInput={this.onInputText}
													 onChange={this.onInputText}
													 value={race.laps_run}
													 data-attr="laps_run"
										/>
									</td>
								</tr>
								<tr>
									<td>Expected Swim Pace: FPRO</td>
									<td>
										<input className="form-control form-control-sm"
													 onInput={this.onInputText}
													 onChange={this.onInputText}
													 value={race.expected_swim_pace_fpro}
													 data-attr="expected_swim_pace_fpro"
										/>
									</td>
								</tr>
								<tr>
									<td>Expected Swim Pace: MPRO</td>
									<td>
										<input className="form-control form-control-sm"
													 onInput={this.onInputText}
													 onChange={this.onInputText}
													 value={race.expected_swim_pace_mpro}
													 data-attr="expected_swim_pace_mpro"
										/>
									</td>
								</tr>
								<tr>
									<td>Tier</td>
									<td>
										<div className='select'>
											<select className="form-control form-control-sm" name="tier_id"
															onInput={this.onInputText}>
												<option value=''
																selected={!race.tier_id}>None
												</option>
												{state.tiers.map(tier => {
													return <option value={tier.id}
																				 selected={parseInt(race.tier_id) === tier.id}>{tier.name}</option>
												})}
											</select>
										</div>
									</td>
								</tr>
								<tr>
									<td>Prize Currency</td>
									<td>
										<div className='select'>
											<select className="form-control form-control-sm" name="prize_currency_id"
															onInput={this.onInputText}>
												<option value=''
																selected={!race.prize_currency_id}>-
												</option>
												{state.currencies.map(currency => {
													return <option value={currency.id}
																				 selected={race.prize_currency_id === currency.id}>{currency.code}</option>
												})}
											</select>
										</div>
									</td>
								</tr>
								<tr>
									<td>Prize Amount</td>
									<td><input className="form-control form-control-sm" onInput={this.onInputText}
														 onChange={this.onInputText}
														 value={race.prize_amount}
														 data-attr="prize_amount"
									/></td>
								</tr>
								<tr>
									<td>SOF MPRO Calculated</td>
									<td><input className="form-control form-control-sm" disabled={true}
														 value={`${race.sof_mpro_calculated ?? ''} ${race.sof_mpro_last_calculated ? '(' + moment(race.sof_mpro_last_calculated).format('MM/DD/YYYY H:mm') + ')' : ''}`}
														 data-attr="sof_mpro_calculated"
									/></td>
								</tr>
								<tr>
									<td>SOF MPRO Manual</td>
									<td><input className="form-control form-control-sm" onInput={this.onInputText}
														 onChange={this.onInputText}
														 value={race.sof_mpro_manual}
														 data-attr="sof_mpro_manual"
									/></td>
								</tr>
								<tr>
									<td>SOF FPRO Calculated</td>
									<td><input className="form-control form-control-sm" disabled={true}
														 value={`${race.sof_fpro_calculated ?? ''} ${race.sof_fpro_last_calculated ? '(' + moment(race.sof_fpro_last_calculated).format('MM/DD/YYYY H:mm') + ')' : ''}`}
														 data-attr="sof_fpro_calculated"
									/></td>
								</tr>
								<tr>
									<td>SOF FPRO Manual</td>
									<td><input className="form-control form-control-sm" onInput={this.onInputText}
														 onChange={this.onInputText}
														 value={race.sof_fpro_manual}
														 data-attr="sof_fpro_manual"
									/></td>
								</tr>
								<tr>
									<td>Bonus Points</td>
									<td><input className="form-control form-control-sm" disabled={true}
														 value={`${race.bonus_point}%`}
														 data-attr="bonus_point"
									/></td>
								</tr>
								<tr>
									<td>Date</td>
									<td>
										<PickDate class="form-control form-control-sm" onInput={this.onInputText}
															onChange={this.onInputText}
															_type="date"
															value={race.date.split("T")[0]}
															data-attr="date"
										/>
									</td>
								</tr>
								<tr>
									<td>Start Time (UTC timestamp)</td>
									<td>
										<input className="form-control form-control-sm"
													 onInput={this.onInputText}
													 onChange={this.onInputText}
													 value={race.start_timestamp}
													 data-attr="start_timestamp"
										/>
									</td>
								</tr>
								<tr>
									<td>Country</td>
									<td><input class="form-control form-control-sm" onInput={this.onInputText} onChange={this.onInputText}
														 value={race.country_name}
														 data-attr="country_name"
									/></td>
								</tr>
								<tr>
									<td>City</td>
									<td><input class="form-control form-control-sm" onInput={this.onInputText} onChange={this.onInputText}
														 value={race.city}
														 data-attr="city"
									/></td>
								</tr>
								<tr>
									<td>T100 Season</td>
									<td>
										<div className='select'>
											<select className='form-control form-control-sm' data-attr="t100_season_id"
															onInput={this.onInputText} onChange={this.onInputText}>
												<option value='' selected={!race.t100_regional_championship_id}>-</option>
												{state.seasons.map(x => <option value={x.id}
																												selected={x.id === race.t100_season_id}>{x.name}</option>)}
											</select>
										</div>
									</td>
								</tr>
								<tr>
									<td>T100 Championship</td>
									<td>
										<div className='select'>
											<select className='form-control form-control-sm' data-attr="t100_regional_championship_id"
															onInput={this.onInputText} onChange={this.onInputText}>
												<option value='' selected={!race.t100_regional_championship_id}>-</option>
												{state.championships.map(x => <option value={x.id}
																															selected={x.id === race.t100_regional_championship_id}>{x.name}</option>)}
											</select>
										</div>
									</td>
								</tr>
								<tr>
									<td>Is T100 Grand Final?</td>
									<td>
										<div className='select'>
											<select className='form-control form-control-sm' data-attr="is_t100_grand_final"
															onInput={this.onInputText} onChange={this.onInputText}>
												<option value={1} selected={race.is_t100_grand_final == 1}>Yes</option>
												<option value={0} selected={race.is_t100_grand_final == 0}>No</option>
											</select>
										</div>
									</td>
								</tr>
								<tr>
									<td>Broadcast Time</td>
									<td><input class="form-control form-control-sm" onInput={this.onInputText} onChange={this.onInputText}
														 value={race.broadcast_time}
														 data-attr="broadcast_time"
														 type="time"
									/></td>
								</tr>
								<tr>
									<td>Broadcast Locale</td>
									<td>
										<div className='select'>
											<select className='form-control form-control-sm' data-attr="broadcast_locale"
															onInput={this.onInputText} onChange={this.onInputText}>
												<option value='' selected={!race.broadcast_locale}>-</option>
												{locales.map(x => <option value={x.abbreviation}
																									selected={x === race.broadcast_locale}>{x.abbreviation} - {x.description}</option>)}
											</select>
										</div>
									</td>
								</tr>
								<tr>
									<td>Watch Button 1 URL</td>
									<td><input class="form-control form-control-sm" onInput={this.onInputText} onChange={this.onInputText}
														 value={race.event_link1_url}
														 data-attr="event_link1_url"
									/></td>
								</tr>
								<tr>
									<td>Watch Button 1 Text</td>
									<td><input class="form-control form-control-sm" onInput={this.onInputText} onChange={this.onInputText}
														 value={race.event_link1_text}
														 data-attr="event_link1_text"
									/></td>
								</tr>
								<tr>
									<td>Watch Button 2 URL</td>
									<td><input class="form-control form-control-sm" onInput={this.onInputText} onChange={this.onInputText}
														 value={race.event_link2_url}
														 data-attr="event_link2_url"
									/></td>
								</tr>
								<tr>
									<td>Watch Button 2 Text</td>
									<td><input class="form-control form-control-sm" onInput={this.onInputText} onChange={this.onInputText}
														 value={race.event_link2_text}
														 data-attr="event_link2_text"
									/></td>
								</tr>
								<tr>
									<td>More Info URL</td>
									<td><input class="form-control form-control-sm" onInput={this.onInputText} onChange={this.onInputText}
														 value={race.event_link3_url}
														 data-attr="event_link3_url"
									/></td>
								</tr>
								<tr>
									<td>More Info Text</td>
									<td><input class="form-control form-control-sm" onInput={this.onInputText} onChange={this.onInputText}
														 value={race.event_link3_text}
														 data-attr="event_link3_text"
									/></td>
								</tr>
							</table>
						</div>
						<div class='pt-2'>
							<button class='btn btn-sm btn-danger' onClick={this.onClickDeleteRace}>Delete this race</button>
						</div>
					</Fragment>
				}
				{state.tab == 'rtrt' &&
					<Fragment>
						<div class='card'>
							<table>
								<tr>
									<td>RTRT Name</td>
									<td><input class="form-control form-control-sm" onChange={this.onInputText}
														 value={race.rtrt_name}
														 data-attr="rtrt_name"
														 list="rtrt-events"
									/>
									</td>
									{!state.rtrt_events.length &&
										<td>
											<button class='btn btn-sm btn-secondary' onClick={this.loadRTRTRaces}>Fetch Races from Tracker</button>
										</td>
									}
								</tr>
								<tr>
									<td>Swim Point</td>
									<td><input class="form-control form-control-sm" onChange={this.onInputText}
														 value={race.rtrt_swim || state.active_points?.swim}
														 data-attr="rtrt_swim"
														 list="rtrt-points"
									/>
									</td>
									{!state.rtrt_points.length &&
										<td>
											<button class='btn btn-sm btn-secondary' onClick={this.loadRTRTRaceInfo}>Fetch Points</button>
										</td>
									}
								</tr>
								<tr>
									<td>T1 Point</td>
									<td><input class="form-control form-control-sm" onChange={this.onInputText}
														 value={race.rtrt_t1 || state.active_points?.t1}
														 data-attr="rtrt_t1"
														 list="rtrt-points"
									/>
									</td>
								</tr>
								<tr>
									<td>Bike Point</td>
									<td><input class="form-control form-control-sm" onChange={this.onInputText}
														 value={race.rtrt_bike || state.active_points?.bike}
														 data-attr="rtrt_bike"
														 list="rtrt-points"
									/>
									</td>
								</tr>
								<tr>
									<td>T2 Point</td>
									<td><input class="form-control form-control-sm" onChange={this.onInputText}
														 value={race.rtrt_t2 || state.active_points?.t2}
														 data-attr="rtrt_t2"
														 list="rtrt-points"
									/>
									</td>
								</tr>
								<tr>
									<td>Finish Point</td>
									<td><input class="form-control form-control-sm" onChange={this.onInputText}
														 value={race.rtrt_finish || state.active_points?.finish}
														 data-attr="rtrt_finish"
														 list="rtrt-points"
									/>
									</td>
								</tr>
								<tr>
									<td></td>
									<td>
										<button class='btn btn-sm btn-secondary' onClick={this.getResults}>Fetch Tracker Results</button>
										<button class='btn btn-sm btn-secondary ml-2' onClick={this.exportResults}>Export Splits</button>
									</td>
								</tr>
							</table>
						</div>
						<div class='card'>
							<button class='btn btn-sm btn-secondary ml-2' onClick={this.importCSV}>Import CSV File</button>
							<table>
								<tr>
									<th colspan="5"></th>
									<th colspan="6" style="text-align: center;">Times</th>
									<th colspan="4" style="text-align: center;">Points</th>
								</tr>
								<tr>
									{ ["Div.", "Place", "First", "Last", "Country", "DB", "Swim", "T1", "Bike", "T2", "Run", "Overall", "Swim", "Bike", "Run", "Overall"].map(x => <th>{x}</th>)}
								</tr>
								{state.rtrt_athletes.map(a => {
									return(<tr>
										<td>{a.division}</td>
										<td>{a.rank}</td>
										<td>{a.first}</td>
										<td>{a.last}</td>
										<td>{a.country}</td>
										<td>
											<td><input class="form-control form-control-sm"
																 value={a.db_athlete ? a.db_athlete.name : ""}
																 list="db-athletes"
																 onChange={this.onChangeDBAthlete}
																 data-pid={a.pid}
											/>
											</td>
										</td>
										<td>{secondsToTime(a.times[0], true)}</td>
										<td>{secondsToTime(a.times[1], true)}</td>
										<td>{secondsToTime(a.times[2], true)}</td>
										<td>{secondsToTime(a.times[3], true)}</td>
										<td>{secondsToTime(a.times[4], true)}</td>
										<td>{secondsToTime(a.times[5], true)}</td>
										<td>{a.swim_points}</td>
										<td>{a.bike_points}</td>
										<td>{a.run_points}</td>
										<td>{a.overall_points}</td>
									</tr>)
								})}
							</table>
							{state.rtrt_athletes.length != 0 &&
								<button class='btn btn-sm btn-danger' onClick={this.submitRTRTResults}>Submit Results</button>
							}
						</div>
					</Fragment>
				}
				{ state.tab == 'results' &&
					<Fragment>
						{["FPRO", "MPRO"].map(division => {
							const tableHeadings = ["Place", "First", "Last", "Country", "Swim", "T1", "Bike", "T2", "Run", "Overall", "Swim Pts", "Bike Pts", "Run Pts", "Race Pts", "Rank", "Rank Pts", "Rank System"]
							const isT100Race = race.t100_season_id != null

							if (isT100Race) {
								tableHeadings.splice(-1, 0, "T100 Pts")
							}

							return (
								<div class='card'>
									<table class='race-results'>
										<tr>
											{ tableHeadings.map(x => <th>{x}</th>)}
										</tr>
										{results.filter(x => x.division == division).map(x => {
											let pts = points.find(p => p.result_id == x.result_id)
											return(<tr>
													<td>{x.rank}</td>
													<td><a href={'/athlete/' + x.id}>{x.first}</a></td>
													<td><a href={'/athlete/' + x.id}>{x.last}</a></td>
													<td>{x.country}</td>
													<td>{secondsToTime(x.swim, true)}</td>
													<td>{secondsToTime(x.t1, true)}</td>
													<td>{secondsToTime(x.bike, true)}</td>
													<td>{secondsToTime(x.t2, true)}</td>
													<td>{secondsToTime(x.run, true)}</td>
													<td>{secondsToTime(x.overall, true)}</td>
													<td><div class='points'>{pts && pts.swim_points && pts.swim_points.toFixed(2)}</div></td>
													<td><div class='points'>{pts && pts.bike_points && pts.bike_points.toFixed(2)}</div></td>
													<td><div class='points'>{pts && pts.run_points && pts.run_points.toFixed(2)}</div></td>
													<td><div class='points'>{pts && pts.overall_points && pts.overall_points.toFixed(2)}</div></td>
													<td>{x.overall_rank}</td>
													<td>{x.overall_points}</td>
													{isT100Race && <td>{pts && pts.t100_points}</td>}
													<td>{x.ranking_system}</td>
												</tr>
											)
										})}
									</table>
								</div>
							)
						})}
						<div class='pb-4 d-flex justify-content-between'>
							<button class='btn btn-sm btn-secondary ml-2' onClick={this.exportFinalResults}>Export Results</button>
							<button class='btn btn-sm btn-danger' onClick={this.onClickDeleteAllResults}>Delete All Results</button>
						</div>
					</Fragment>
				}
				{ state.tab == 'participants' &&
					<Fragment>
						<div class='card'>
							<table class='d-none'>
								<tr>
									<td>Active Link</td>
									<td><input class="form-control form-control-sm" onChange={this.onInputText}
														 value={race.active_link}
														 data-attr="active_link"
									/>
									</td>
									<td>
										<button class='btn btn-sm btn-secondary' onClick={this.fetchParticipants}>Fetch Participants</button>
									</td>
								</tr>
							</table>
							<div class='alert alert-danger hide-if-empty mb-2' style='white-space: pre-wrap;'>
								{state.warning_import_participants}
							</div>
							<div class='d-flex'>
								<div class='flex-grow-1'>
									<Tags value="" placeholder="Add an athlete..."mode="select" options={this.db_athletes.athletes} onChange={this.onAddParticipant} />
								</div>
								<button class='btn btn-sm btn-secondary ml-2' onClick={this.importParticipantsCSV}>Import CSV File</button>
								<button class='btn btn-sm btn-secondary ml-2' _href={'mailto:' + participants_emails.join(",")} onClick={this.emailParticipants}>Export Participants</button>
							</div>
						</div>
						<div class='card'>
							<table class='participants'>
								<thead>
								<tr>
									<th>Division</th>
									<th>First</th>
									<th>Last</th>
									<th>Country</th>
									<th>Rank</th>
									<th>Points</th>
									<th>Bib #</th>
									<th>Reserve?</th>
									<th>Swim Cap Color</th>
									<th>Max HR Swim</th>
									<th>Max HR Bike</th>
									<th>Max HR Run</th>
									<th>Gear Ratio</th>
									<th>Weight</th>
									<th></th>
								</tr>
								</thead>
								{participants.map(x => {
									return(
										<tr>
											<td>{x.division}</td>
											<td>{x.first}</td>
											<td>{x.last}</td>
											<td>{x.country}</td>
											<td>#{x.overall_rank}</td>
											<td>{x.overall_points}</td>
											<td>{handleNullValue(x.bib)}</td>
											<td>{x.is_reserve ? 'Y' : 'N'}</td>
											<td>{handleNullValue(x.swim_cap_color)}</td>
											<td>{handleNullValue(x.max_hr_swim)}</td>
											<td>{handleNullValue(x.max_hr_bike)}</td>
											<td>{handleNullValue(x.max_hr_run)}</td>
											<td>{handleNullValue(x.gear_ratio)}</td>
											<td>{handleNullValue(x.weight)}</td>
											<td class='remove' onClick={this.onRemoveParticipant} data-athlete_id={x.id}><i
												class="far fa-trash-alt"></i></td>
										</tr>
									)
								})}
							</table>
						</div>
					</Fragment>
				}
				{ state.tab == 'sectors' &&
					<Fragment>
						<div class='card'>
							<table>
								<tr>
									<th className='pt-2 align-top'>Bike Sector Image (Desktop)</th>
									<td>
										<div class='d-flex'>
											<div class='media-pic'>
												<MediaPicture media={sectorBikeDesktopImage} sizes="160px"/>
											</div>
										</div>

										<div class='mt-1 d-flex'>
											<button class='btn btn-secondary btn-sm' onClick={() => this.handleFileUpload("sectors_bike_lg")}>Upload File</button>
											{ sectorBikeDesktopImage?.srcset && <button class='btn btn-sm btn-danger ml-2' onClick={() => this.removeSectorImage("sectors_bike_lg")}>Remove</button>}
										</div>

										<p>
											<em>
												<small>
													Recommended width: 1920px
												</small>
											</em>
										</p>
									</td>
								</tr>
								<tr>
									<th className='pt-2 align-top'>Bike Sector Image (Mobile)</th>
									<td>
										<div class='d-flex'>
											<div class='media-pic'>
												<MediaPicture media={sectorBikeMobileImage} sizes="160px"/>
											</div>
										</div>

										<div class='mt-1 d-flex'>
											<button class='btn btn-secondary btn-sm' onClick={() => this.handleFileUpload("sectors_bike_sm")}>Upload File</button>
											{ sectorBikeMobileImage?.srcset && <button class='btn btn-sm btn-danger ml-2' onClick={() => this.removeSectorImage("sectors_bike_sm")}>Remove</button>}
										</div>

										<p>
											<em>
												<small>
													Recommended width: 1920px
												</small>
											</em>
										</p>
									</td>
								</tr>
								<tr>
									<th className='pt-2 align-top'>Run Sector Image (Desktop)</th>
									<td>
										<div class='d-flex'>
											<div class='media-pic'>
												<MediaPicture media={sectorRunDesktopImage} sizes="160px"/>
											</div>
										</div>

										<div class='mt-1 d-flex'>
											<button class='btn btn-secondary btn-sm' onClick={() => this.handleFileUpload("sectors_run_lg")}>Upload File</button>
											{ sectorRunDesktopImage?.srcset && <button class='btn btn-sm btn-danger ml-2' onClick={() => this.removeSectorImage("sectors_run_lg")}>Remove</button>}
										</div>

										<p>
											<em>
												<small>
													Recommended width: 1920px
												</small>
											</em>
										</p>
									</td>
								</tr>
								<tr>
									<th className='pt-2 align-top'>Run Sector Image (Mobile)</th>
									<td>
										<div class='d-flex'>
											<div class='media-pic'>
												<MediaPicture media={sectorRunMobileImage} sizes="160px"/>
											</div>
										</div>

										<div class='mt-1 d-flex'>
											<button class='btn btn-secondary btn-sm' onClick={() => this.handleFileUpload("sectors_run_sm")}>Upload File</button>
											{ sectorRunMobileImage?.srcset && <button class='btn btn-sm btn-danger ml-2' onClick={() => this.removeSectorImage("sectors_run_sm")}>Remove</button>}
										</div>

										<p>
											<em>
												<small>
													Recommended width: 1920px
												</small>
											</em>
										</p>
									</td>
								</tr>
							</table>
						</div>
					</Fragment>
				}

				<datalist id="rtrt-events">
					{state.rtrt_events.map(x => {
						return <option value={x.name}>{x.desc + " - " + x.date + ' - ' + x.loc.desc}</option>
					})}
				</datalist>

				<datalist id="rtrt-points">
					{state.rtrt_points.map(x => {
						return <option value={x.name}/>
					})}
				</datalist>

				<datalist id="db-athletes">
					{this.db_athletes.athletes.map(x => {
						return <option value={x.name}></option>
					})}
				</datalist>

				<PleaseWait show={state.working}/>
			</div>
		)
	}
}
