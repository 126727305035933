import { h, Component, Fragment } from "preact";
import Cookies from 'js-cookie'
import { fetchAPI } from '../libs/api';
import { route } from 'preact-router';

export class Login extends Component {
	state = { loginError: null }

	submit = (e) => {
		console.log(e.target)
		const formData = new FormData(e.target)

		let that = this
		this.setState({ loginError: null })

		fetchAPI("/auth/login", {
			method: "post",
			headers: { 'Accept': 'application/json', 'Content-Type': 'application/json' },
			body: JSON.stringify({
				"email": encodeURI(formData.get("email")),
				"password": formData.get("password"),
			})
		})
		.then(function(response) { if(response.ok) return response.json(); return Promise.reject(response); })
		.then(function(data) {
			if(data.token_session)
			{
				localStorage.setItem('ptotoken', data.token_session)
				location = "/"
			}
			else
			{
				that.setState({ loginError: data.error })
			}
		});

		e.preventDefault()
	}

	render(props, state) {
		return(
			<div class='d-flex flex-column align-items-center h-100 w-100 pt-4 mt-4'>		
				<div class='w-100 px-4 mb-4 d-flex justify-content-center'>
					<img class='w-100' src='/assets/logo-long.png' style='max-width: 400px; object-fit: contain;'/>
				</div>
				<div class='account'>
					<form id='login-form' class='d-flex flex-column' onSubmit={this.submit}>
						<div class='form-group'>
							<label for="">Email</label>
							<input class="form-control" type='email' placeholder='user@protriathletes.org' autocomplete='email' name="email"></input>
						</div>
						<div class='form-group'>
							<div class='d-flex justify-content-between'>
								<label for="">Password</label>
								<a class='text-muted' href={"/forgot"}><small>Forgot password?</small></a>
							</div>
							<input class="form-control" type='password' placeholder='Password' autocomplete='password' name="password"></input>
						</div>
						<button class="btn btn-primary mt-3" type='submit'>Let's go</button>
					</form>
					<div class="alert alert-danger mt-3 hide-if-empty" id='login-error'>{state.loginError}</div>
					<div class='text-center w-100 mt-3 text-grey'>Don't have an account? <a href='/signup'>Get started.</a></div>
					<div class='text-center w-100 mt-3 text-grey'>Problems signing in / creating an account? <a href='mailto:admin@protriathletes.org'>Get in touch.</a></div>
				</div>
			</div>
		);
	}
}

export class Signup extends Component {
	state = { working: false, error: null }

	submit = (e) => {
		const formData = new FormData(e.target)

		if(this.state.working) return

		let that = this
		this.setState({ working: true })

		const now = new Date();
		fetchAPI("/auth/signup", {
			method: "post",
			headers: { 'Content-Type': 'application/json' },
			body: JSON.stringify({
				"email": encodeURI(formData.get("email")),
				"first": formData.get("first"),
				"last": formData.get("last"),
				"timezone": -now.getTimezoneOffset() / 60
			})
		})
		.then(function(response) { if(response.ok) return response.json(); return Promise.reject(response); })
		.then(function(data) {
			if(data.status == 0)
			{
				route("/verifyemail/" + formData.get("email"))
			}
			else
			{
				that.setState({ error: data.error, working: false })
			}
		});

		e.preventDefault()
	}

	render(props, state)
	{
		return(
			<div class='d-flex flex-column align-items-center h-100 w-100 pt-4 mt-4'>		
				<div class='w-100 px-4 mb-4 d-flex justify-content-center'>
					<img class='w-100' src='/assets/logo-long.png' style='max-width: 400px; object-fit: contain;'/>
				</div>
				<div class='account col-lg-4'>
					<form class='d-flex flex-column' id='signup-form' onSubmit={this.submit}>
						<div class='form-group'>
							<label for="">First Name</label>
							<input class="form-control" type='text' placeholder='John' autocomplete='given-name' name='first'></input>
						</div>
						<div class='form-group'>
							<label for="">Last Name</label>
							<input class="form-control" type='text' placeholder='Doe' autocomplete='family-name' name='last'></input>
						</div>
						<div class='form-group'>
							<label for="">Email</label>
							<input class="form-control" type='email' placeholder='user@example.com' autocomplete='email' name='email'></input>
						</div>
						<button class="btn btn-primary mt-3" type='submit'>Next</button>
					</form>
					<div class="alert alert-danger mt-3 hide-if-empty" id='signup-error'>{state.error}</div>
					<div class='text-center w-100 mt-3 text-grey'>Already have an account? <a href='/login'>Log in.</a></div>
				</div>
			</div>
		);
	}
}



export class VerifyEmail extends Component {
	state = { working: false, error: null }

	submit = (e) => {
		const formData = new FormData(e.target)

		if(this.state.working) return

		let that = this
		this.setState({ working: true })

		fetchAPI("/auth/verifyemail", {
			method: "post",
			headers: { 'Content-Type': 'application/json' },
			body: JSON.stringify({
				"email": encodeURI(e.target.dataset.email),
				"code": formData.get("code"),
			})
		})
		.then(function(response) { if(response.ok) return response.json(); return Promise.reject(response); })
		.then(function(data) {
			if(data.status == 0)
			{
				route("/password/" + data.token + "/" + encodeURI(e.target.dataset.email))
			}
			else
			{
				that.setState({ error: data.error, working: false })
			}
		});

		e.preventDefault()
	}

	render(props, state) {
		return(
			<div class='d-flex flex-column align-items-center h-100 w-100 pt-4 mt-4'>		
				<div class='w-100 px-4 mb-4 d-flex justify-content-center'>
					<img class='w-100' src='/assets/logo-long.png' style='max-width: 400px; object-fit: contain;'/>
				</div>
				<div class='account col-lg-4'>
					<div class='form-group d-flex justify-content-center mt-3'>
						<a class='text-muted text-center' href='/login'>An email coming from admin@protriathletes.com was sent to you with a code. Make sure it's not in your junk folder if you haven't received it.</a>
					</div>
					<form class='d-flex flex-column' id='verify-email-form' data-email={ props.email } onSubmit={this.submit}>
						<div class='form-group'>
							<label for="">Verify Email Code</label>
							<input class="form-control" type='text' placeholder='XXXXXX' autocomplete='none' name='code'></input>
						</div>
						<div class="alert alert-danger mt-3 hide-if-empty" id='verify-email-error'>{state.error}</div>
						<button class="btn btn-primary mt-3"  type='submit'>Verify Code</button>
						<div class='form-group d-flex justify-content-center mt-3'>
							<a href='/login'>Back to sign in</a>
						</div>
					</form>
				</div>
			</div>
		);
	}
}

export class Password extends Component {
	state = { working: false, error: null }

	submit = (e) => {
		const formData = new FormData(e.target)

		if(this.state.working) return

		let that = this
		this.setState({ working: true })


		fetchAPI("/auth/setpassword", {
			method: "post",
			headers: { 'Content-Type': 'application/json' },
			body: JSON.stringify({
				"password": encodeURI(formData.get("password")),
				"token": e.target.dataset.token,
			})
		})
		.then(function(response) { if(response.ok) return response.json(); return Promise.reject(response); })
		.then(function(data) {
			if(data.status == 0)
			{
				localStorage.setItem('ptotoken', data.token_session)
				location = "/"
			}
			else
			{
				that.setState({ error: data.error, working: false })
			}
		});

		e.preventDefault()
	}

	render(props, state) {
		return(
			<div class='d-flex flex-column align-items-center h-100 w-100 pt-4 mt-4'>		
				<div class='w-100 px-4 mb-4 d-flex justify-content-center'>
					<img class='w-100' src='/assets/logo-long.png' style='max-width: 400px; object-fit: contain;'/>
				</div>
				<div class='account col-lg-4'>
					<form class='d-flex flex-column' id='password-form' data-token={ props.token } onSubmit={this.submit}>
						<div class='form-group'>
							<label for="">Password</label>
							<input class="form-control d-none" type='email' autocomplete='email' name='email' value={props.email}></input>
							<input class="form-control" type='password' placeholder='Password' autocomplete='password' name='password'/>
						</div>
						<div class="alert alert-danger mt-3 hide-if-empty" id='password-error'>{state.error}</div>
						<button class="btn btn-primary mt-3" type='submit'>Set Password</button>
					</form>
					<div class='form-group d-flex justify-content-center mt-3'>
						<a class='text-muted' href='/login'>Back to sign in</a>
					</div>
				</div>
			</div>
		);
	}
}

export class Forgot extends Component {
	state = { working: false, error: null }

	submit = (e) => {
		const formData = new FormData(e.target)

		if(this.state.working) return

		let that = this
		this.setState({ working: true })

		fetchAPI("/auth/reset", {
			method: "post",
			headers: { 'Accept': 'application/json', 'Content-Type': 'application/json' },
			body: JSON.stringify({
				"email": encodeURI(formData.get("email")),
			})
		})
		.then(function(response) { if(response.ok) return response.json(); return Promise.reject(response); })
		.then(function(data) {
			if(data.status == 0)
			{
				route("/verifycode/" + formData.get("email"))
			}
			else
			{
				that.setState({ error: data.error, working: false })
			}
		});

		e.preventDefault()
	}

	render(props, state) {
		return(
			<div class='d-flex flex-column align-items-center h-100 w-100 pt-4 mt-4'>		
				<div class='w-100 px-4 mb-4 d-flex justify-content-center'>
					<img class='w-100' src='/assets/logo-long.png' style='max-width: 400px; object-fit: contain;'/>
				</div>
				<div class='account col-lg-4'>
					<form class='d-flex flex-column' id='forgot-form' onSubmit={this.submit}>
						<div class='form-group'>
							<label for="">Email</label>
							<input class="form-control" type='email' placeholder='user@example.com' autocomplete='email' name='email'></input>
						</div>
						<div class="alert alert-danger mt-3 hide-if-empty" id='forgot-error'>{state.error}</div>
						<button class="btn btn-primary mt-3"  type='submit'>Send link to reset</button>
						<div class='form-group d-flex justify-content-center mt-3'>
							<a class='text-muted' href='/login'>Back to sign in</a>
						</div>
					</form>
				</div>
			</div>
		);
	}
}

export class VerifyCode extends Component {
	state = { working: false, error: null }

	submit = (e) => {
		const formData = new FormData(e.target)

		if(this.state.working) return

		let that = this
		this.setState({ working: true })

		fetchAPI("/auth/verifyreset", {
			method: "post",
			headers: { 'Content-Type': 'application/json' },
			body: JSON.stringify({
				"email": encodeURI(e.target.dataset.email),
				"code": formData.get("code"),
			})
		})
		.then(function(response) { if(response.ok) return response.json(); return Promise.reject(response); })
		.then(function(data) {
			if(data.status == 0)
			{
				route("/password/" + data.token + "/" + encodeURI(e.target.dataset.email))
			}
			else
			{
				that.setState({ error: data.error, working: false })
			}
		});
		e.preventDefault()
	}

	render(props, state) {
		return(
			<div class='d-flex flex-column align-items-center h-100 w-100 pt-4 mt-4'>		
				<div class='w-100 px-4 mb-4 d-flex justify-content-center'>
					<img class='w-100' src='/assets/logo-long.png' style='max-width: 400px; object-fit: contain;'/>
				</div>
				<div class='account col-lg-4'>
					<form class='d-flex flex-column' id='verify-code-form' data-email={ props.email } onSubmit={this.submit}>
						<div class='form-group'>
							<label for="">Reset Password Code</label>
							<input class="form-control" type='text' placeholder='XXXXXX' autocomplete='none' name='code'></input>
						</div>
						<div class="alert alert-danger mt-3 hide-if-empty" id='verify-code-error'>{state.error}</div>
						<button class="btn btn-primary mt-3" type='submit'>Verify Code</button>
						<div class='form-group d-flex justify-content-center mt-3'>
							<a class='text-muted' href='/login'>Back to sign in</a>
						</div>
					</form>
				</div>
			</div>
		);
	}
}


