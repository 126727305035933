import { h, Component, createRef, Fragment } from "preact";
import { fetchAPI } from "../../../../libs/api";
import { Dialog } from "../../../dialog";
import { PickDate } from "../../../utils";
import moment from "moment";

export default class AddSeason extends Component {
	nextYear = new Date().getFullYear() + 1;
	defaultStartDate = moment(new Date(this.nextYear, 0, 1)).format("YYYY-MM-DD");
	defaultEndDate = moment(new Date(this.nextYear, 11, 31)).format("YYYY-MM-DD");

	state = {
		isEmptyField: true,
		errorMessage: null,
		season: {
			name: "",
			start_date: this.defaultStartDate,
			end_date: this.defaultEndDate,
			total_race_count: 0,
			top_scoring_results_count: 0
		}
	}

	errorDialogRef = createRef();

	changeHandler(event) {
		const field = event.target.dataset.attr;
		let value = event.target.value;

		let updatedSeason = this.state.season;
		updatedSeason[field] = value;

		this.setState({ season: updatedSeason },
			() => {
				const { season } = this.state;
				const isEmptyField = (season.name === "") || (season.start_date === null) || (season.end_date === null);
				this.setState({ isEmptyField });
			});
	}

	addSeason() {
		const { name, start_date, end_date, top_scoring_results_count } = this.state.season
		fetchAPI("/api/t100/seasons", {
			method: "post",
			headers: { 'Content-Type': 'application/json' },
			body: JSON.stringify({ name: name, start_date: start_date, end_date: end_date, total_race_count: 0, top_scoring_results_count: top_scoring_results_count })
		})
			.then(response => response.json())
			.then(() => {
				this.props.fetchSeasons()
				this.setState({ season: {
					name: "",
					start_date: null,
					end_date: null,
					top_scoring_results_count: 0
				}})

				const dateInputs = document.querySelectorAll('input[_type="date"]');
				dateInputs.forEach(function(input) {
					input.value = null;
				 });
			})
			.catch(error => {
				this.setState({ errorMessage: error.message });
				this.errorDialogRef.current.show();
			});
	}

	render(props, state) {
		const { isEmptyField, errorMessage } = state;

		return (
			<Fragment>
				<Dialog yesText="Add Season" title="Add a new Season" ref={ props.reference } onYes={ this.addSeason.bind(this) } disabled={ isEmptyField }>
					<table>
						<tr>
							<td>Name</td>
							<td>
								<input
									class="form-control form-control-sm"
									onInput={ this.changeHandler.bind(this) }
									data-attr="name"
									value={ state.season.name }
									type="text"
								/>
							</td>
						</tr>
						<tr>
							<td>Start Date</td>
							<td>
								<PickDate 
									_type="date"
									data-attr="start_date"
									class="form-control form-control-sm"
									onInput={ this.changeHandler.bind(this) }
									onChange={ this.changeHandler.bind(this) }
									value={ state.season.start_date?.split("T")[0] }
								/>
							</td>
						</tr>
						<tr>
							<td>End Date</td>
							<td>
								<PickDate 
									_type="date"
									data-attr="end_date"
									class="form-control form-control-sm"
									onInput={ this.changeHandler.bind(this) }
									onChange={ this.changeHandler.bind(this) }
									value={ state.season.end_date?.split("T")[0] }
								/>
							</td>
						</tr>
						<tr>
							<td>Top Scoring Results Count</td>
							<td>
								<input
									class="form-control form-control-sm"
									onInput={ this.changeHandler.bind(this) }
									data-attr="top_scoring_results_count"
									value={ state.season.top_scoring_results_count }
									type="number"
									min="0"
								/>
							</td>
						</tr>
					</table>
				</Dialog>

				<Dialog nofooter={ true } title="Error!" ref={ this.errorDialogRef }>
					<p>{ errorMessage }</p>
				</Dialog>
			</Fragment>
		)
	}
}
