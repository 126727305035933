
let searchTimer = null
let searchInput = document.getElementById('search-input')
let searchResults = document.getElementById('search-results')
if(searchInput)
{
	searchInput.addEventListener("input", (e) => {
		if(searchTimer != null) clearTimeout(searchTimer)

		searchTimer = setTimeout(function(terms) {
			searchTimer = null

			if(terms == undefined || terms.trim() == "") {
				searchResults.classList.add("hide")
				return
			} 
			fetch("/api/search/" + terms, {
				headers: { 'Content-Type': 'application/json' },
			})
			.then(function(response) { if(response.ok) return response.json(); return Promise.reject(response); })
			.then(function(data_search) {
				let parent = searchResults
				while (parent.firstChild) {
					parent.removeChild(parent.firstChild);
				}
				let count = 1
				if(data_search.results.length || data_search.races.length)
				{
					if(!data_search.results)
					{
						searchResults.classList.add("hide")
						document.body.classList.remove("noscroll")
					}
					for(let r of data_search.results)
					{
						let a = document.createElement('a')
						a.href = r.url
						a.innerText = r.name
						a.tabIndex = -1
						a.addEventListener("click", function() {
							searchInput.value = ""
							searchResults.classList.add("hide")
							document.body.classList.remove("noscroll")
						})
						searchResults.appendChild(a);
						searchResults.classList.remove("hide")
						document.body.classList.add("noscroll")
						count += 1
					}

					for(let r of data_search.races)
					{
						let a = document.createElement('a')
						a.href = r.url
						a.innerText = r.name
						a.tabIndex = -1
						a.addEventListener("click", function() {
							searchInput.value = ""
							searchResults.classList.add("hide")
							document.body.classList.remove("noscroll")
						})
						searchResults.appendChild(a);
						searchResults.classList.remove("hide")
						document.body.classList.add("noscroll")
						count += 1
					}
				}
				else
				{
					let parent = searchResults
					while (parent.firstChild) {
						parent.removeChild(parent.firstChild);
					}
					searchResults.classList.add("hide")
					document.body.classList.remove("noscroll")
				}
			})
		}, 250, e.target.value)
	})

	document.addEventListener("mouseup", (e) => {
		if(!searchResults.classList.contains("hide"))
		{
			let withinSearch = e.target.closest(".search")
			if(!withinSearch) 
			{
				searchResults.classList.add("hide")
				document.body.classList.remove("noscroll")
			}
		}
	})

	document.addEventListener("keydown", (e) => {
		if(!searchResults.classList.contains("hide"))
		{
			let choices = Array.from(searchResults.getElementsByTagName("a"))
			let selected = choices.findIndex(x => x.classList.contains("selected"))
			if(e.code == "ArrowDown")
			{
				if(selected != -1) choices[selected].classList.remove("selected")
				if(selected == -1 || selected == choices.length - 1) { choices[0].classList.add("selected"); }
				else { choices[selected + 1].classList.add("selected"); }
				e.preventDefault()
			}
			else if(e.code == "ArrowUp")
			{
				if(selected != -1) choices[selected].classList.remove("selected")
				if(selected == -1 || selected == 0) { choices[choices.length - 1].classList.add("selected"); }
				else { choices[selected - 1].classList.add("selected"); }
				e.preventDefault()
			}
			else if(e.code == "Enter")
			{
				searchInput.value = ""
				if(selected != -1) choices[selected].click()
				searchResults.classList.add("hide")
				document.body.classList.remove("noscroll")
				e.preventDefault()
			}
			else if(e.code == "Escape")
			{
				searchResults.classList.add("hide")
				document.body.classList.remove("noscroll")
				e.preventDefault()
			}
		}
	})
}

