import { h, Component, Fragment, createRef } from "preact";
import { fetchAPI } from "../../../libs/api";
import { Dialog } from "../../dialog";
const _ = require('lodash');

export default class EditTab extends Component {
	deleteConfirmDialogRef = createRef();
	errorDialogRef = createRef();

	state = {
		errorMessage: null,
		isEmptyField: false,
		isChangesToPersist: false,

		serie: {
			name: this.props.serie.name,
			alias: this.props.serie.alias,
		},
		originalSerie: {
			name: this.props.serie.name,
			alias: this.props.serie.alias,
		}
	};

	changeHandler(event) {
		const { originalSerie, serie } = this.state;
		const field = event.target.dataset.attr;
		const value = event.target.value;

		// Create copy of serie object and update relevant value
		let updatedSerie = { ...serie };
		updatedSerie[field] = value;

		// Check if inputted values are different to original values
		const isChangesToPersist = !_.isEqual(originalSerie, updatedSerie);

		this.setState({
			isEmptyField: value === (null || ""),
			isChangesToPersist: isChangesToPersist,
			serie: updatedSerie,
		});
	}

	updateSerie() {
		const { serie } = this.state
		const { name, alias } = serie;

		fetchAPI(`/api/serie/${this.props.serie.id}`, {
			method: "put",
			headers: { 'Content-Type': 'application/json' },
			body: JSON.stringify({ name: name, alias: alias })
		})
			.then(response => response.json())
			.then(() => {
				this.setState({ originalSerie: serie, isChangesToPersist: false });
				location = `/serie/${alias}`;
				})
			.catch(error => {
				this.setState({ errorMessage: error.message });
				this.errorDialogRef.current.show();
			});

	}

	deleteSerie() {
      fetchAPI(`/api/serie/${this.props.serie.id}`, { method: "delete", headers: { 'Content-Type': 'application/json' } })
         .then(() => location = '/series')
         .catch(error => {
            this.setState({ errorMessage: error.message });
            this.errorDialogRef.current.show();
         });
	}

	openConfirmDialog() {
		this.deleteConfirmDialogRef.current.show();
	}

	render(props, state) {
		const { isEmptyField, isChangesToPersist, serie, errorMessage } = state;

		return (
			<div class="card">
				<table>
					<tr>
						<td>Serie</td>
						<td>
							<input class="form-control form-control-sm" onInput={this.changeHandler.bind(this)} value={serie.name} data-attr="name" />
						</td>
					</tr>
					<tr>
						<td>Alias</td>
						<td>
							<input class="form-control form-control-sm" onInput={this.changeHandler.bind(this)} value={serie.alias} data-attr="alias" />
						</td>
					</tr>
				</table>
				<div class="pt-3">
					<button class="btn btn-sm btn-primary" onClick={this.updateSerie.bind(this)} disabled={(!isChangesToPersist || isEmptyField)}>
						Save Changes
					</button>

					{!props.serie.isRelatedRecords && (
                  <Fragment>
                     <button class="btn btn-sm btn-danger ml-3" onClick={this.openConfirmDialog.bind(this)}>
                        Delete Serie
                     </button>

                     <Dialog title="Delete Serie?" ref={this.deleteConfirmDialogRef} yesText="Confirm Delete" onYes={this.deleteSerie.bind(this)}>
                        <p>Are you sure you want to delete this serie?</p>
                     </Dialog>
                  </Fragment>
               )}
				</div>

            <Dialog nofooter={true} title="Error!" ref={this.errorDialogRef}>
               <p>{errorMessage}</p>
            </Dialog>
			</div>
		);
	}
}
