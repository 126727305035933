import { h, Component, createRef, Fragment } from "preact";
import { fetchAPI } from "../../../libs/api";
import { Dialog } from "../../dialog";

export default class AddSerie extends Component {
   state = {
       isEmptyField: true,
       errorMessage: null,
       serie: {
           name: "",
           alias: "",
       }
   }

   errorDialogRef = createRef();

   generateAlias() {
       const name = this.state.serie.name.trim();
       // Remove everything but letters and numbers
       let generatedAlias = name.replaceAll(/[^A-Za-z0-9\s-]/g, "")
       // Replace spaces with a dash
       generatedAlias = generatedAlias.replaceAll(" ", "-");
       // Remove repeated '-' characters
       generatedAlias = generatedAlias.replace(/-+/g,"-");
       // Remove last character from string, if the character is a '-'
       generatedAlias = generatedAlias.replace(/-$/, '');
       let updatedSerie = this.state.serie;
       updatedSerie.alias = generatedAlias.toLowerCase();
       this.setState({ serie: updatedSerie });
   }

   changeHandler(event) {
       const field = event.target.dataset.attr;
       const value = event.target.value;

       let updatedSerie = this.state.serie;
       updatedSerie[field] = value;

       this.setState({ serie: updatedSerie }, 
         () => {
           const { serie } = this.state;
           const isEmptyField = (serie.name === "") || (serie.alias === "");
           this.setState({ isEmptyField });			  
           if (field === 'name') this.generateAlias();
       });
   }

   addSerie() {
       const { name, alias } = this.state.serie
       fetchAPI("/api/serie", {
           method: "post",
           headers: { 'Content-Type': 'application/json' },
           body: JSON.stringify({ name: name, alias: alias })
       })
           .then(response => response.json())
           .then(() => this.props.refreshSeries())
           .then(() => this.setState({ serie: { name: "", alias: "" } }))
           .catch(error => {
               this.setState({ errorMessage: error.message });
               this.errorDialogRef.current.show();
           });
   }

   render(props, state) {
       const { isEmptyField, errorMessage } = state;
       const { name, alias } = state.serie
       return (
           <Fragment>
               <Dialog yesText="Add Serie" title="Add a new Serie" ref={props.reference} onYes={this.addSerie.bind(this)} disabled={isEmptyField}>
                   <table>
                       <tr>
                           <td>Serie</td>
                           <td>
                               <input
                                   class="form-control form-control-sm"
                                   onInput={this.changeHandler.bind(this)}
                                   data-attr="name"
                                   value={name}
                               />
                           </td>
                       </tr>
                       <tr>
                           <td>Alias</td>
                           <td>
                               <input
                                   class="form-control form-control-sm"
                                   onInput={this.changeHandler.bind(this)}
                                   data-attr="alias"
                                   value={alias}
                               />
                           </td>
                       </tr>
                   </table>
               </Dialog>

              <Dialog nofooter={true} title="Error!" ref={this.errorDialogRef}>
                 <p>{errorMessage}</p>
              </Dialog>
           </Fragment>
       )
   }
}
