import { h, Component, Fragment, createRef } from "preact";
import { fetchAPI } from "../../../libs/api";
import { Dialog } from "../../dialog";
import { Medias, MediaPicture } from "../../medias"
const _ = require('lodash');

export default class EditTab extends Component {
	deleteConfirmDialogRef = createRef();
	errorDialogRef = createRef();

	state = {
		loading: true,
		isInputError: false,
		isChangesToPersist: false,
		brand: {
			id: this.props.brand.id,
			logo: this.props.brand.logo,
			logo_color: this.props.brand.logo_color,
			name: this.props.brand.name,
			url: this.props.brand.url,
		},
		originalBrand: {
			id: this.props.brand.id,
			logo: this.props.brand.logo,
			logo_color: this.props.brand.logo_color,
			name: this.props.brand.name,
			url: this.props.brand.url,
		}
	};

	changeHandler(event) {
		const { brand, originalBrand } = this.state;
		const field = event.target.dataset.attr;
		const value = event.target.value;
		const isInputError = !event.target.checkValidity();

		// Create copy of brand object and update relevant value
		let updatedBrand = { ...brand };
		updatedBrand[field] = value;

		// Check if inputted values are different to original values
		const isChangesToPersist = !_.isEqual(originalBrand, updatedBrand);

		this.setState({
			isEmptyField: value === (null || ""),
			isInputError,
			isChangesToPersist,
			brand: updatedBrand,
		});
	}

	updateBrand() {
		const { brand } = this.state
		const { name, url } = brand;

		fetchAPI(`/api/brands/${this.props.brand.id}/update`, {
			method: "post",
			headers: { 'Content-Type': 'application/json' },
			body: JSON.stringify({ attributes: [{ name: "name", value: name }, { name: "url", value: url }] })
		})
			.then(response => response.json())
			.then(() => {
				this.setState({ originalBrand: brand, isChangesToPersist: false });
			})
			.catch(error => {
				this.setState({ errorMessage: error.message });
				this.errorDialogRef.current.show();
			});

	}

	deleteBrand() {
		fetchAPI(`/api/brand/${this.props.brand.id}`, { method: "delete", headers: { 'Content-Type': 'application/json' } })
			.then(() => location = '/brands')
			.catch(error => {
				this.setState({ errorMessage: error.message });
				this.errorDialogRef.current.show();
			});
	}

	openDeleteDialog() {
		this.deleteConfirmDialogRef.current.show();
	}

	uploadLogo(fieldName = "logo") {
		let that = this
		const brand = this.state.brand
		let input = document.createElement('input')
		input.type = "file"
		input.accept = "image/*"

		input.addEventListener("change", async (e) => {
			let file = e.target.files[0]
			let f = await fetchAPI("/api/createuploadurl", {
				method: "post",
				headers: { 'Content-Type': 'application/json' },
				body: JSON.stringify({ name: file.name, size: file.size, type: file.type, lastModified: parseInt(file.lastModified / 1000) })
			})
			let data_uploadurl = await f.json()

			let put = await fetch(data_uploadurl.put, { 
				method: "put",
				headers: { 'Content-Type': data_uploadurl.type, 'Cache-Control': data_uploadurl.cc },
				body: file 
			})

			await fetchAPI("/api/brands/" + brand.id + "/update", {
				method: "post",
				headers: { 'Content-Type': 'application/json' },
				body: JSON.stringify({ attributes: [{ name: fieldName, value: data_uploadurl.cdn }] })
			})

			let updatedBrand = { ...brand };
			updatedBrand[fieldName] = data_uploadurl.cdn;
			that.setState({brand: updatedBrand})
		})
		input.click()
	}


	render(props, state) {
		const { isEmptyField, isChangesToPersist, isInputError, brand, errorMessage } = state;
		return (
			<div class="card">
				<table>
					<tr>
						<td>Name</td>
						<td>
							<input class="form-control form-control-sm"
								onInput={ this.changeHandler.bind(this) }
								value={ brand.name } data-attr="name" />
						</td>
					</tr>
					<tr>
						<td>URL</td>
						<td>
							<input class="form-control form-control-sm"
								onInput={ this.changeHandler.bind(this) }
								value={ brand.url } data-attr="url" />
						</td>
					</tr>
					<tr>
						<th>Logo</th>
						<td>
							<div class='d-flex'>
								<div class='image-container'>
									<img src={ brand.logo } />
								</div>
							</div>

							<div class='mt-1'>
								<button class='btn btn-secondary btn-sm' onClick={ () => this.uploadLogo("logo") }>Upload File</button>
							</div>
						</td>
					</tr>
					<tr>
						<th>Color Logo</th>
						<td>
							<div class='d-flex'>
								<div class='image-container'>
									<img src={ brand.logo_color } />
								</div>
							</div>

							<div class='mt-1'>
								<button class='btn btn-secondary btn-sm' onClick={ () => this.uploadLogo("logo_color") }>Upload File</button>
							</div>
						</td>
					</tr>
				</table>
				<div class="pt-3">
					{isChangesToPersist && (
						<button class="btn btn-sm btn-primary"
							onClick={ this.updateBrand.bind(this) }
							disabled={ (!isChangesToPersist || isEmptyField || isInputError) }>
							Save Changes
						</button>
					)}

					{ !this.props.brand.isRelatedRecords && (
						<Fragment>
							<button class="btn btn-sm btn-danger ml-3" onClick={ this.openDeleteDialog.bind(this) }>
								Delete Brand
							</button>

							<Dialog title="Delete brand?" ref={ this.deleteConfirmDialogRef } yesText="Confirm Delete" onYes={ this.deleteBrand.bind(this) }>
								<p>Are you sure you want to delete this brand?</p>
							</Dialog>
						</Fragment>
					) }
				</div>

				<Dialog nofooter={ true } title="Error!" ref={ this.errorDialogRef }>
					<p>{ errorMessage }</p>
				</Dialog>
			</div>
		);
	}
}
