import { h, render, Component, createRef, Fragment } from 'preact';
import moment from 'moment';
import { Dialog } from "./dialog"
import { PickDate, PleaseWait } from "./utils";
import "./news.scss"
import { fetchAPI, serverAPI, websocketsServerAPI } from '../libs/api';

export class News extends Component {
	state = { news: [], please_wait: false }
	dialogAddRankings = createRef()

	componentDidMount()
	{
		this.refreshNewsList()
	}

	refreshNewsList() 
	{
		let that = this

		fetchAPI("/api/news")
		.then(function(response) { if(response.ok) return response.json(); return Promise.reject(response); })
		.then(function(data) {
			that.setState({ news: data.news })
		})
	}

	addUrl = createRef()
	dialogAddRankingsYes = e => {
		let that = this
		let url = this.addUrl.current.value

		this.setState({ please_wait: true })
		fetchAPI("/api/news/create", {
			method: "post",
			headers: { 'Content-Type': 'application/json' },
			body: JSON.stringify({ url: url })
		})
		.then(function(response) { if(response.ok) return response.json(); return Promise.reject(response); })
		.then(function(data) { 
			that.setState({ please_wait: false })
			that.refreshNewsList()
		})
	}

	onAddNewRankings = e => {
		this.dialogAddRankings.current.show()
	}

	onDelete = e => {
		let that = this
		let id = e.target.dataset.id
		if(!confirm("Are you sure your want to delete thsi news?")) return
		this.setState({ please_wait: true })
		fetchAPI(`/api/news/${id}`, { method: "delete" })
		.then(function(response) { if(response.ok) return response.json(); return Promise.reject(response); })
		.then(function(data) { 
			that.setState({ please_wait: false })
			that.refreshNewsList()
		})
	}
	onSync = e => {
		this.setState({ please_wait: true })
		let that = this
		fetchAPI("/api/news/sync", {
			method: "post",
			headers: { 'Content-Type': 'application/json' },
		})
		.then(function(response) { if(response.ok) return response.json(); return Promise.reject(response); })
		.then(function(data) { 
			that.setState({ please_wait: false })
		})
	}

	render(props, state) {
		let news = state.news

		return(<div class='news'>
			<div class='d-flex justify-content-between mb-2'>
				<h2 class='headline mb-0'>NEWS</h2>
				<div>
					<button class='btn btn-sm btn-warning flex-shrink-0' onClick={this.onSync}>Sync to Website</button>
					<button class='btn btn-sm btn-secondary ml-4 flex-shrink-0' onClick={this.onAddNewRankings}>Add a news</button>
				</div>
			</div>
			<div class='card'>
				<table class='news-table'>
					<tr>{["", "Source", "Content"].map(x => <th>{x}</th>)}</tr>
					{ news.map(x => {
						let details = JSON.parse(x.details)
						console.log(details)

						let icon = details.icons?.[0].href
						let title = details.og?.['og:title']
						let description = details.og?.['og:description']
						let thumbnail = details.og?.['og:image']?.href
						let date = x.date 
						let name = x.site
						let nameInfo = null

						if(details.twitter?.content?.user)
						{
							let user = details.twitter.content.user
							name = user.name
							//nameInfo = `@${user.username} · Twitter`
							//icon = user.profile_image_url
						}

						if(details.twitter?.content?.data)
						{
							let data = details.twitter.content.data
							description = data.text
							date = data.created_at
						}

						if(details.twitter?.content?.media)
						{
							let media = details.twitter.content.media
							thumbnail = media.preview_image_url
						}

						if(details.instagram?.embed)
						{
							let embed = details.instagram?.embed
							thumbnail = embed.thumbnail_url
							description = embed.title
							name = embed.author_name
						}


						return(<tr>
							<td>
								<div class='d-flex justify-content-center'>
									<img class='news-thumnail' src={thumbnail}/>
								</div>
							</td>
							<td>
								<div class='d-flex align-items-center'>
									<div><img class='news-icon' src={icon}/></div>
									<div class='d-flex flex-column ml-2'>
										<div>{name}</div>
										<div class='news-date'>{moment.utc(x.date).format("l")}</div>
									</div>
								</div>
							</td>
							{ /*details.twitter?.embed && 
								<div>
									<script async="" src="https://platform.twitter.com/widgets.js" charset="utf-8"></script>
									<div dangerouslySetInnerHTML={{__html: details.twitter.embed.html}}>
									</div>
								</div>
							}
							{ details.instagram?.embed && 
								<div>
									<script async src="//www.instagram.com/embed.js"></script>
									<div dangerouslySetInnerHTML={{__html: details.instagram.embed.html}}>
									</div>
								</div>*/
							}
							<td style="max-width: 400px">
								<div class='news-title'>{title}</div>
								<div class='news-description'>{description}</div>
							</td>
							<td>
								<div class='d-flex flex-column'>
									<a class='btn btn-sm btn-secondary my-1' href={x.url} target='news-link'>Link</a>
									<button class='btn btn-sm btn-danger my-1' onClick={this.onDelete} data-id={x.id}>Delete</button>
								</div>
							</td>
						</tr>)
					})}
				</table>
			</div>
			<Dialog yesText="Create News" title="Create a News" large ref={this.dialogAddRankings} onYes={this.dialogAddRankingsYes}>
				<div class='d-flex w-100 align-items-center'>
					<div class='mr-2'>Enter news URL</div>
					<div style='flex: 1'><input class="form-control form-control-sm" type="url" ref={this.addUrl}/></div>
				</div>

			</Dialog>
			<PleaseWait show={state.please_wait}/>

		</div>)
	}
}

export class NewsEntry extends Component {
	state = { rankings_men: [], rankings_women: [], cmp_rankings_men: [], cmp_rankings_women: [], discipline: "overall", please_wait: false }

	componentDidMount()
	{
		this.refreshRankingsList()
	}

	refreshRankingsList() 
	{
		let that = this

		fetchAPI("/api/ranking/" + this.props.id, {
			headers: { 'Content-Type': 'application/json' },
		})
		.then(function(response) { if(response.ok) return response.json(); return Promise.reject(response); })
		.then(function(data) {
			that.setState({ 
				rankings_men: data.rankings.filter(x => x.division == "MPRO"), 
				rankings_women: data.rankings.filter(x => x.division != "MPRO"), 
				cmp_rankings_men: data.cmp_rankings.filter(x => x.division == "MPRO"), 
				cmp_rankings_women: data.cmp_rankings.filter(x => x.division != "MPRO"), 
				set: data.set 
			})
		})
	}

	onGenerateRanking = e => {
		this.setState({ please_wait: true })
		let that = this
		fetchAPI("/api/ranking/" + this.props.id + "/generate", {
			method: "post",
			headers: { 'Content-Type': 'application/json' },
		})
		.then(function(response) { if(response.ok) return response.json(); return Promise.reject(response); })
		.then(function(data) { 
			//console.log(data)
			that.refreshRankingsList()
			that.setState({ please_wait: false })
		})
	}

	onPublish = e => {
		let that = this
		fetchAPI("/api/ranking/" + this.props.id + "/publish", {
			method: "post",
			headers: { 'Content-Type': 'application/json' },
		})
		.then(function(response) { if(response.ok) return response.json(); return Promise.reject(response); })
		.then(function(data) { 
		})
		this.state.set.published = 1
		this.setState({})
	}

	onUnpublish = e => {
		let that = this
		fetchAPI("/api/ranking/" + this.props.id + "/unpublish", {
			method: "post",
			headers: { 'Content-Type': 'application/json' },
		})
		.then(function(response) { if(response.ok) return response.json(); return Promise.reject(response); })
		.then(function(data) { 
		})
		this.state.set.published = 0
		this.setState({})
	}


	render(props, state) {
		let cc_rankings = 0
		let discipline = state.discipline
		if(state.discipline.startsWith("team")) 
		{ 
			cc_rankings = 1
			discipline = 'overall'
		}

		let discipline_points = discipline + "_points"
		let discipline_rank = discipline + "_rank"

		let rankings_men = state.rankings_men
		let rankings_women = state.rankings_women

		let cmp_rankings_men = state.cmp_rankings_men
		let cmp_rankings_women = state.cmp_rankings_women
		
		rankings_men.sort((a,b) => a[discipline_rank] - b[discipline_rank])
		rankings_women.sort((a,b) => a[discipline_rank] - b[discipline_rank])
		cmp_rankings_men.sort((a,b) => a[discipline_rank] - b[discipline_rank])
		cmp_rankings_women.sort((a,b) => a[discipline_rank] - b[discipline_rank])

		if(cc_rankings == 1)
		{
			let exclude = [1739, 3724, 1682, 3687, 4512, 3498]
			if(state.discipline == "team-usa") 
			{
				rankings_men = rankings_men.filter(x => x.country == "US")
				rankings_women = rankings_women.filter(x => x.country == "US")
				cmp_rankings_men = cmp_rankings_men.filter(x => x.country == "US")
				cmp_rankings_women = cmp_rankings_women.filter(x => x.country == "US")
			}

			let internationals = ["CA", "AU", "NZ", "ZA", "BM", "BR", "AR", "MX", "CL"]

			if(state.discipline == "team-int") 
			{
				rankings_men = rankings_men.filter(x => internationals.indexOf(x.country) != -1)
				rankings_women = rankings_women.filter(x => internationals.indexOf(x.country) != -1)
				cmp_rankings_men = cmp_rankings_men.filter(x => internationals.indexOf(x.country) != -1)
				cmp_rankings_women = cmp_rankings_women.filter(x => internationals.indexOf(x.country) != -1)
			}

			if(state.discipline == "team-eur") 
			{
				rankings_men = rankings_men.filter(x => internationals.indexOf(x.country) == -1)
				rankings_women = rankings_women.filter(x => internationals.indexOf(x.country) == -1)
				cmp_rankings_men = cmp_rankings_men.filter(x => internationals.indexOf(x.country) == -1)
				cmp_rankings_women = cmp_rankings_women.filter(x => internationals.indexOf(x.country) == -1)
			}

			let valid = [], invalid = []
			for(let rank of rankings_men)
			{
				if(exclude.find(x => x == rank.id)) continue
				let details = JSON.parse(rank.details)
				if(details[3].results.length > 1) valid.push(rank)
				else if(rank.overall_points > 85) invalid.push(rank)
			}
			rankings_men = valid.slice(0,10).concat(invalid.slice(0,3))

			valid = []
			invalid = []
			for(let rank of rankings_women)
			{
				if(exclude.find(x => x == rank.id)) continue
				let details = JSON.parse(rank.details)
				if(details[3].results.length > 1) valid.push(rank)
				else if(rank.overall_points > 85) invalid.push(rank)
			}
			rankings_women = valid.slice(0,10).concat(invalid.slice(0,3))
			while(rankings_men.length < rankings_women.length) rankings_men.push(null)
			while(rankings_women.length < rankings_men.length) rankings_women.push(null)

			valid = []
			invalid = []
			for(let rank of cmp_rankings_men)
			{
				if(exclude.find(x => x == rank.id)) continue
				let details = JSON.parse(rank.details)
				if(details[3].results.length > 1) valid.push(rank)
				else if(rank.overall_points > 85) invalid.push(rank)
			}
			cmp_rankings_men = valid

			valid = []
			invalid = []
			for(let rank of cmp_rankings_women)
			{
				if(exclude.find(x => x == rank.id)) continue
				let details = JSON.parse(rank.details)
				if(details[3].results.length > 1) valid.push(rank)
				else if(rank.overall_points > 85) invalid.push(rank)
			}
			cmp_rankings_women = valid
		}
		let set = state.set

		return(<div class='races'>
			<div class='d-flex justify-content-between mb-2'>
				<div>
					<h2 class='headline mb-0'>RANKINGS { set && moment.utc(set.date).format("YYYY-MM-DD")}</h2>
				</div>
				<div>
					{set && set.published == 0 && <button class='btn btn-sm btn-success flex-shrink-0' onClick={this.onPublish}>Publish</button> }
					{set && set.published == 1 && <button class='btn btn-sm btn-danger flex-shrink-0' onClick={this.onUnpublish}>Unpublish</button> }
					<button class='btn btn-sm btn-secondary ml-2 flex-shrink-0' onClick={this.onGenerateRanking}>Generate Rankings</button>
				</div>
			</div>
			<div class='card'>
				<table class='ranking-table'>
					<tr><td/><td/>
						<td colspan="3">
							<select class='form-control form-control-sm' onChange={(e) => this.setState({discipline: e.target.value})}>
								<option value="overall" selected={state.discipline == "overall"}>Overall</option>
								<option value="swim" selected={state.discipline == "swim"}>Swim</option>
								<option value="bike" selected={state.discipline == "bike"}>Bike</option>
								<option value="run" selected={state.discipline == "run"}>Run</option>
								<option value="team-eur" selected={state.discipline == "team-eur"}>Team Europe</option>
								<option value="team-int" selected={state.discipline == "team-int"}>Team Internationals</option>
								<option value="team-usa" selected={state.discipline == "team-usa"}>Team USA</option>
							</select>
						</td>
						<td/><td/>
					</tr>
					<tr>{["Men", "Points", "Move", "Rank", "Move", "Points", "Name"].map(x => <th>{x}</th>)}</tr>
					{ rankings_men.map((x, index) => {
						if(index >= 100) return

						let move_men = 0
						if(rankings_men[index])
						{
							if(cc_rankings)
							{
								let pos = cmp_rankings_men.findIndex(y => y.id == rankings_men[index].id)
								if(pos >= 0) move_men = pos - index 
							}
							else
							{
								let cmp_men = state.cmp_rankings_men.find(y => y.id == x.id)
								if(cmp_men) move_men = cmp_men[discipline_rank] - x[discipline_rank]
							}
						}

						let move_women = 0
						if(rankings_women[index])
						{
							if(cc_rankings)
							{
								let pos = cmp_rankings_women.findIndex(y => y.id == rankings_women[index].id)
								if(pos >= 0) move_women = pos - index 
							}
							else
							{
								let cmp_women = state.cmp_rankings_women.find(y => y.id == rankings_women[index].id)
								if(cmp_women) move_women = cmp_women[discipline_rank] - rankings_women[index][discipline_rank]
							}
						}

						return(
							<tr hunt={cc_rankings == 1 && index >= 10}>
								<td>{x && <a href={'/athlete/' + x.id}>{x.name}</a>}</td>
								<td>{x && x[discipline_points].toFixed(2)}</td>
								<td up={move_men > 0} down={move_men < 0}>{move_men != 0 && move_men}</td>
								<td>#{index+1}</td>
								<td up={move_women > 0} down={move_women < 0}>{move_women != 0 && move_women}</td>
								<td>{rankings_women[index] && rankings_women[index][discipline_points].toFixed(2)}</td>
								<td>{rankings_women[index] && <a href={'/athlete/' + rankings_women[index].id}>{rankings_women[index].name}</a>}</td>
							</tr>
						)
					})}
				</table>
			</div>
			<PleaseWait show={state.please_wait}/>
		</div>)
	}
}


