import { h, render, Component, createRef, Fragment } from 'preact';
import moment from 'moment';
import { Dialog } from "./dialog"
import { PickDate, PleaseWait } from "./utils";
import { secondsToTime } from "../server/components/utils";
import "./videos.scss"
import { fetchAPI, serverAPI, websocketsServerAPI } from '../libs/api';

export class Videos extends Component {
	state = { 
		videos: [], 
		please_wait: false,
		show_videoPlayer: null 
	}
	dialogAddRankings = createRef()

	componentDidMount()
	{
		this.refreshNewsList()

	}

	componentWillUnmount() 
	{
		if(this.hls) this.hls.destroy()
	}

	videoRef = (video) => 
	{

		console.log("video", video)
		if(!video)
		{
			if(this.hls) this.hls.destroy()
			return
		}

		if(Hls.isSupported())
		{
			let hls = new Hls({
				startLevel: 4,
				enableWorker: true,
				lowLatencyMode: true,
				backBufferLength: 90,
	  			//debug: true
			})
			this.hls = hls


			/*hls.on(Hls.Events.MANIFEST_PARSED, (event, data) => {
				//hls.autoLevelEnabled = false
				console.log(data.levels)
				this.setState({ levels: data.levels })	
				let p = this.video.play()

				if (p !== undefined) {
					p.then(_ => {
						console.log("autoplay success")
					}).catch(error => {
						this.setState({ showPlayManual: true })
						console.log("autoplay failed")
					})
				}
	        })		
		
			hls.on(Hls.Events.LEVEL_SWITCHED, (event, data) => {
				console.log(event, data)
				this.setState({ current_level: data.level })	
			})

			hls.on(Hls.Events.LEVEL_UPDATED, (event, data) => {
				console.log(event, hls.type, data.details.totalduration, data)
			})

			hls.on(Hls.Events.ERROR, (event, data) => {
				console.log(event, data)
			})*/

			hls.attachMedia(video)
			//*/
		}
	}

	refreshNewsList() 
	{
		let that = this

		fetchAPI("/api/videos")
		.then(function(response) { if(response.ok) return response.json(); return Promise.reject(response); })
		.then(function(data) {
			that.setState({ videos: data.videos })
		})
	}

	/*addUrl = createRef()
	dialogAddRankingsYes = e => {
		let that = this
		let url = this.addUrl.current.value

		this.setState({ please_wait: true })
		fetchAPI("/api/news/create", {
			method: "post",
			headers: { 'Content-Type': 'application/json' },
			body: JSON.stringify({ url: url })
		})
		.then(function(response) { if(response.ok) return response.json(); return Promise.reject(response); })
		.then(function(data) { 
			that.setState({ please_wait: false })
			that.refreshNewsList()
		})
	}

	onAddNewRankings = e => {
		this.dialogAddRankings.current.show()
	}

	onDelete = e => {
		let that = this
		let id = e.target.dataset.id
		if(!confirm("Are you sure your want to delete thsi news?")) return
		this.setState({ please_wait: true })
		fetchAPI(`/api/news/${id}`, { method: "delete" })
		.then(function(response) { if(response.ok) return response.json(); return Promise.reject(response); })
		.then(function(data) { 
			that.setState({ please_wait: false })
			that.refreshNewsList()
		})
	}
	onSync = e => {
		this.setState({ please_wait: true })
		let that = this
		fetchAPI("/api/news/sync", {
			method: "post",
			headers: { 'Content-Type': 'application/json' },
		})
		.then(function(response) { if(response.ok) return response.json(); return Promise.reject(response); })
		.then(function(data) { 
			that.setState({ please_wait: false })
		})
	}
	*/

	onPlay = e => {
		let that = this
		let id = e.target.dataset.id
		let video = this.state.videos.find(x => x.id == id)
		if(!video) return

		let videoSrc = `https://content.protriathletes.org/videos/${video.id}/master.m3u8`
		this.setState({ show_videoPlayer: true}, () => {
			this.hls.loadSource(videoSrc)
		})
	}


	render(props, state) {
		let videos = state.videos

		return(<div class='videos'>
			<div class='d-flex justify-content-between mb-2'>
				<h2 class='headline mb-0'>VIDEOS</h2>
				<div>
					<button class='btn btn-sm btn-warning flex-shrink-0' onClick={this.onSync}>Sync to Website</button>
					<button class='btn btn-sm btn-secondary ml-4 flex-shrink-0' onClick={this.onAddNewRankings}>Add a video</button>
				</div>
			</div>
			<div class='card'>
				<table class='videos-table'>
					{ /*<tr>{["", "Source", "Content"].map(x => <th>{x}</th>)}</tr> */ }
					{ videos.map(x => {
						return(<tr>
							<td>
								<div class='d-flex '>
									<img class='videos-thumnail' src={x.thumbnail}/>
								</div>
							</td>
							<td class='w-100'>
								<div class='d-flex align-items-center'>
									<div class='d-flex flex-column ml-2'>
										<div class='videos-date'>{moment.utc(x.date).format("l")}</div>
										<div>{x.name}</div>
										<div class='videos-duration'>{secondsToTime(x.duration)}</div>
									</div>
								</div>
							</td>
							{ <td>
								<div class='d-flex flex-column'>
									<button class='btn btn-sm btn-secondary my-1' target='video-play' data-id={x.id} onClick={this.onPlay}>Play</button>
									{ /*<button class='btn btn-sm btn-danger my-1' onClick={this.onDelete} data-id={x.id}>Delete</button> */}
								</div>
							</td>}
						</tr>)
					})}
				</table>
			</div>
			<Dialog yesText="Create News" title="Create a News" large ref={this.dialogAddRankings} onYes={this.dialogAddRankingsYes}>
				<div class='d-flex w-100 align-items-center'>
					<div class='mr-2'>Enter news URL</div>
					<div style='flex: 1'><input class="form-control form-control-sm" type="url" ref={this.addUrl}/></div>
				</div>
			</Dialog>
			<PleaseWait show={state.please_wait}/>
			{ state.show_videoPlayer && <div class='video-player'>
				<div class='video-player-close' onClick={ () => this.setState({ show_videoPlayer: false }) }>
					<i class="fas fa-times"></i>
				</div>
				<video autoplay controls ref={this.videoRef}>
					<source src={state.videoPlayer_url} type="application/x-mpegURL"/>
				</video>
			</div>
			}

		</div>)
	}
}
