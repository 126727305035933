import { h, Component, createRef } from "preact";
import { fetchAPI } from "../../../libs/api";
import t100Logo from "../../../assets/t100-logo.png";
import AddChampionship from "./dialogs/addChampionship";
import "./championships.scss";

export class T100Championships extends Component {
	state = {
		filter: sessionStorage.getItem("championships-filter"),
		filteredChampionships: [],
		championships: [],
	}

	dialogAddChampionship = createRef();

	filter(event) {
		const filter = event.target.value;
		sessionStorage.setItem("championships-filter", filter);
		const { championships } = this.state;
		let filteredChampionships;

		if (filter && filter != "") {
			let tokens = filter.toLowerCase().split(" ");
			filteredChampionships = championships.filter(championship => {
				let out = false;
				for (let token of tokens) {
					if (championship.search.indexOf(token) == -1) {
						out = true;
						break;
					}
				}
				return !out
			})
		} else {
			filteredChampionships = championships;
		}
		this.setState({ filter: filter, filteredChampionships: filteredChampionships.slice(0, 200) })
	}

	fetchChampionships() {
		fetchAPI("/api/t100/championships/", { headers: { 'Content-Type': 'application/json' } })
			.then(response => response.json())
			.then(data => this.setState({ championships: data.championships, filteredChampionships: data.championships }))
			.catch(error => alert(error.message));
	}

	componentDidMount() {
		this.fetchChampionships();
	}

	render(props, state) {
		console.log(this.state.filteredChampionships[0])
		return (
			<div class="championships">
				<h2 class="headline">
					<img src={ t100Logo } alt="T100 Logo" />
					Championships
				</h2>

				<div class='card'>
					<div class='d-flex align-items-center'>
						<div>Filter:</div>
						<input class='form-control form-control-sm ml-2' type='text' onInput={ this.filter.bind(this) } value={ this.state.filter } />
						<button class='btn btn-sm btn-secondary ml-3 flex-shrink-0' onClick={ () => this.dialogAddChampionship.current.show() }>Add a new Championship</button>
					</div>
				</div>

				<div class="card">
					<table class="championships-table table">
						<tr>
							{ ["ID", "Name", "Region", "Season", "MPRO Winner", "FPRO Winner", "Published?"].map((columnHeader) => (
								<th>{ columnHeader }</th>
							)) }
						</tr>

						{ this.state?.filteredChampionships.map((championship) => {
							return (
								<tr>
									<td>
										<a href={ "/t100/championship/" + championship.id }>{ championship.id }</a>
									</td>
									<td>
										<a href={ "/t100/championship/" + championship.id }>{ championship.name }</a>
									</td>
									<td>{ championship.region || "-" }</td>
									<td>{ championship.season_name || "-" }</td>
									<td>{ championship.winner_mpro_name || "-" }</td>
									<td>{ championship.winner_fpro_name || "-" }</td>
									<td>{ championship.is_active ? "Yes" : "No" }</td>
								</tr>
							);
						}) }
					</table>
				</div>

				<AddChampionship reference={ this.dialogAddChampionship } fetchChampionships={ this.fetchChampionships.bind(this) } />
			</div>
		);
	}
}
