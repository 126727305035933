import { h, Component, Fragment, createRef } from "preact";
import { fetchAPI } from "../../libs/api";
import { Dialog } from "../dialog";
const _ = require('lodash');
import "./tier.scss";

export class Tier extends Component {
   deleteConfirmDialogRef = createRef();
   errorDialogRef = createRef();
   archiveConfirmDialogRef = createRef();

   state = {
      tier: {},
      originalTier: {}, 
      loading: true,
      isInputError: false,
      isChangesToPersist: false,
   };

   async refreshTier() {
      return fetchAPI(`/api/tier/${this.props.id}`, { headers: { 'Content-Type': 'application/json' }})
         .then(response => response.json())
         .then(data => this.setState({ originalTier: data.tier, tier: data.tier, loading: false }))
         .catch(err => this.setState({ error: err }));
   }

   componentDidMount() {
      this.refreshTier()
   }

   changeHandler(event) {
      const { tier, originalTier } = this.state;
      const field = event.target.dataset.attr;
      const value = event.target.value;
      const isInputError = !event.target.checkValidity();

      // Create copy of tier object and update relevant value
      let updatedTier = { ...tier };
      const isNumberField = event.target.type === "number";
      updatedTier[field] = isNumberField ? Number(value) : value;

      // Check if inputted values are different to original values
      const isChangesToPersist = !_.isEqual(originalTier, updatedTier);

      this.setState({
         isEmptyField: value === (null || ""),
         isInputError,
         isChangesToPersist,
         tier: updatedTier,
      });
   }

   updateTier() {
      const { tier } = this.state

      console.log(JSON.stringify({ ...tier }))

      fetchAPI(`/api/tier/${this.props.id}`, {
         method: "put",
         body: JSON.stringify({ ...tier }),
         headers: { 'Content-Type': 'application/json' }
      })
         .then(response => response.json())
         .then(() => {
            this.setState({ originalTier: tier, isChangesToPersist: false });
            location = `/tier/${this.props.id}`;
            })
         .catch(error => {
            this.setState({ errorMessage: error.message });
            this.errorDialogRef.current.show();
         });
   }

   handleError(error) {
      this.setState({ errorMessage: error.message });
      this.errorDialogRef.current.show();
   }

   deleteTier() {
      fetchAPI(`/api/tier/${this.props.id}`, { method: "delete", headers: { 'Content-Type': 'application/json' } })
         .then(() => location = '/tiers')
         .catch(this.handleError.bind(this));
   }

   toggleIsActive() {
      const { tier } = this.state;
      fetchAPI(`/api/tier/${this.props.id}/${tier.is_active ? "archive" : "unarchive"}`, { method: "post" })
         .then(this.refreshTier.bind(this))
         .catch(this.handleError.bind(this));
   }

   openDeleteDialog() {
      this.deleteConfirmDialogRef.current.show();
   }

   openArchiveDialog() {
      this.archiveConfirmDialogRef.current.show();
   }

   render(props, state) {
      const { 
         tier, 
         originalTier, 
         loading, 
         error, 
         errorMessage, 
         isEmptyField, 
         isInputError, 
         isChangesToPersist 
      } = this.state;
      
      if (error) return <p>{error.message}</p>
      return !loading && (
         <div class="tier-info">
            <h2 class="headline">{originalTier.name}</h2>

            <div class="card">
               <table>
                  <tr>
                     <td>Tier</td>
                     <td>
                        <input class="form-control form-control-sm"
                               onInput={this.changeHandler.bind(this)}
                               value={tier.name} data-attr="name" />
                     </td>
                  </tr>
                  <tr>
                     <td>Description</td>
                     <td>
                        <input class="form-control form-control-sm"
                               onInput={this.changeHandler.bind(this)}
                               value={tier.description} data-attr="description" />
                     </td>
                  </tr>
                  <tr>
                     <td>Base Points</td>
                     <td>
                        <input class="form-control form-control-sm" type="number" min="0" max="100"
                               onInput={this.changeHandler.bind(this)} value={tier.base_points} data-attr="base_points"
                               disabled={tier.isRelatedRecords} />
                     </td>
                  </tr>
                  <tr>
                     <td>Dropoff Percentage</td>
                     <td>
                        <input class="form-control form-control-sm" type="number" min="0" max="100"
                               onInput={this.changeHandler.bind(this)} value={tier.dropoff_percentage} data-attr="dropoff_percentage"
                               disabled={tier.isRelatedRecords} />
                     </td>
                  </tr>
               </table>
               <div class="pt-3">
                  <button class="btn btn-sm btn-primary"
                          onClick={this.updateTier.bind(this)}
                          disabled={(!isChangesToPersist || isEmptyField || isInputError)}>
                     Save Changes
                  </button>

                  <button class="btn btn-sm btn-secondary ml-3" onClick={this.openArchiveDialog.bind(this)}>
                     {originalTier.is_active ? "Archive" : "Publish"}
                  </button>

                  <Dialog title={`${tier.is_active ? "Archive" : "Publish"} tier?`} ref={this.archiveConfirmDialogRef}
                          yesText={tier.is_active ? "Archive" : "Publish"}
                          onYes={this.toggleIsActive.bind(this)}>
                     <p>Are you sure you want to {tier.is_active ? "archive" : "publish"} this tier?</p>
                  </Dialog>

                  {!tier.isRelatedRecords && (
                     <Fragment>
                        <button class="btn btn-sm btn-danger ml-3" onClick={this.openDeleteDialog.bind(this)}>
                           Delete Tier
                        </button>

                        <Dialog title="Delete tier?" ref={this.deleteConfirmDialogRef} yesText="Confirm Delete" onYes={this.deleteTier.bind(this)}>
                           <p>Are you sure you want to delete this tier?</p>
                        </Dialog>
                     </Fragment>
                  )}
               </div>

               <Dialog nofooter={true} title="Error!" ref={this.errorDialogRef}>
                  <p>{errorMessage}</p>
               </Dialog>
            </div>
         </div>
      );
   }
}

