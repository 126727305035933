import { h, Component, createRef } from "preact";
import { fetchAPI } from "../../libs/api";
import AddSerie from "./dialogs/addSerie";
import "./series.scss";

export class Series extends Component {
    state = {
        filter: sessionStorage.getItem("series-filter"),
        filteredSeries: [],
        series: [],
    }

    dialogAddSerie = createRef();

    filterSeries(event) {
        const filter = event.target.value;
        sessionStorage.setItem("series-filter", filter);
        const { series } = this.state;
        let filteredSeries;
        if (filter && filter != "") {
            let tokens = filter.toLowerCase().split(" ");
            filteredSeries = series.filter(serie => {
                let out = false;
                for (let token of tokens) {
                    if (serie.search.indexOf(token) == -1) {
                        out = true;
                        break;
                    }
                }
                return !out
            })
        } else {
            filteredSeries = series;
        }
        this.setState({ filter: filter, filteredSeries: filteredSeries.slice(0, 200) })
    }

    onAddSerie = e => {
        this.dialogAddSerie.current.show();
    }

    refreshSeries() {
        fetchAPI("/api/series/", { headers: { 'Content-Type': 'application/json' }})
            .then(response => response.json())
            .then(data => this.setState({ series: data.series, filteredSeries: data.series }))
            .catch(error => alert(error.message));
    }

    componentDidMount() {
        this.refreshSeries()
    }

    render(props, state) {
        return (
            <div class="series">
                <h2 class="headline">SERIES</h2>

                <div class='card'>
                    <div class='d-flex align-items-center'>
                        <div>Filter:</div>
                        <input class='form-control form-control-sm ml-2' type='text' onInput={this.filterSeries.bind(this)} value={this.state.filter}/>
                        <button class='btn btn-sm btn-secondary ml-3 flex-shrink-0' onClick={this.onAddSerie}>Add a new Serie</button>
                    </div>
                </div>
         
                <div class="card">
                    <table class="series-table table">
                        <tr>
                            {["ID", "Serie", "Alias"].map((columnHeader) => (
                                <th>{columnHeader}</th>
                            ))}
                        </tr>
                        {this.state.filteredSeries.map((serie) => {
                            return (
                                <tr>
                                    <td>
                                        <a href={"/serie/" + serie.alias}>{serie.id}</a>
                                    </td>
                                    <td>
                                        <a href={"/serie/" + serie.alias}>{serie.name}</a>
                                    </td>
                                    <td>{serie.alias}</td>
                                </tr>
                            );
                        })}
                    </table>
                </div>

                <AddSerie reference={this.dialogAddSerie} refreshSeries={this.refreshSeries.bind(this)}/>
            </div>
        );
    }
}
