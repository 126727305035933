
let pages = document.getElementsByClassName("pages")
if(pages)
{
	for(let ps of pages)
	{
		let pageHeader = ps.getElementsByClassName("page-header")
		let pagesNav = ps.getElementsByClassName("page-nav")
		let pagesContent = ps.getElementsByClassName("page-content")
		if(!pagesNav.length || !pagesContent.length) continue

		let pn = pagesNav[0]
		let pill = pn.getElementsByClassName("pill")
		if(!pill.length) continue

		let pc = pagesContent[0]
		pc.__pill = pill[0]
		pc.addEventListener('scroll', function(e) {
			__page__updatePill(this)
		})

		let allpages = pc.getElementsByClassName("page")

		let lis = pn.getElementsByTagName("li")
		for(let index = 0; index < lis.length; index++)
		{
			let li = lis[index]
			li.__index = index
			li.__pc = pc
			li.__lis = lis
			li.addEventListener('click', function(e) {
				this.__pc.scrollTo({ left: this.__pc.clientWidth * this.__index, behavior: 'smooth' })
			})
		}
		pc.__lis = lis
		pc.__pages = allpages

		__page__updatePill(pc)


		ps.__pages = allpages
		for(let index = 0; index < allpages.length; index++)
		{
			console.log(allpages[index])
			if(pageHeader.length)
			{
				console.log(pageHeader[0].clientHeight)
				pn.style.marginTop = pageHeader[0].clientHeight + "px"
				allpages[index].childNodes[0].style.paddingTop = (pn.clientHeight + pageHeader[0].clientHeight) + "px"
				allpages[index].__pageContent = pc
				allpages[index].__pageIndex = index
				allpages[index].__pageNav = pn
				allpages[index].__pageHeader = pageHeader[0]
				allpages[index].__pages = allpages
				allpages[index].addEventListener('scroll', function(e) {
					if(this.__skiprequestFrame)
					{
						this.__skiprequestFrame = false
						return	
					}
					this.__needsUpdate = true
					window.requestAnimationFrame(updatePageScroll)
				})
			}
		}
	}
}

/*document.addEventListener('readystatechange', () => {
	if (document.readyState == "complete")
	{
		console.log("state", document.readyState)	
	if(pages)
	{
		for(let ps of pages)
		{
			let pagesContent = ps.getElementsByClassName("page-content")
			if(!pagesContent.length) continue
			let pc = pagesContent[0]
			for(let index = 0; index < ps.__pages.length; index++)
			{
				let page = ps.__pages[index]
				page.__pageNav.style.marginTop = page.__pageHeader.clientHeight + "px"
				page.style.paddingTop = (page.__pageNav.clientHeight + page.__pageHeader.clientHeight) + "px"
				console.log(page.__pageHeader.clientHeight)
			}

		}
	}
	}
});

document.addEventListener("DOMContentLoaded", function() {
	console.log('loaded')



});
*/
function updatePageScroll()
{
	for(let ps of pages)
	{
		for(let page of ps.__pages)
		{
			if(!page.__needsUpdate) continue

				console.log(page.__pageHeader.clientHeight)
			page.__pageHeader.style.transform = `translate(0, ${-page.scrollTop}px)`
			page.__pageNav.style.transform = `translate(0, ${-Math.min(page.scrollTop, page.__pageHeader.clientHeight)}px)`
			//page.__pageHeader.style.top = -page.scrollTop + "px"		
			//page.__pageNav.style.top = -Math.min(page.scrollTop, page.__pageHeader.clientHeight) + "px"
			for(let p of page.__pages)
			{
				if(p.__pageIndex == p.__pageContent.__activePage) continue
				let minscroll = p.__pageNav.clientHeight
				//console.log(p.scrollTop, minscroll, page.scrollTop)
				if (page.scrollTop < minscroll)
				{
					p.scrollTop = page.scrollTop
				}
				if(p.scrollTop < minscroll)
				{
					p.scrollTop = Math.min(minscroll - (page.__pageNav.clientHeight - page.__pageHeader.clientHeight), page.scrollTop)
					p.__skiprequestFrame = true
				}
			}

			page.__needsUpdate = false
		}
	}
}


function __page__updatePill(pc)
{
	//console.log(pc, pc.__pill, pc.clientWidth, pc.offsetLeft, pc.scrollWidth, pc.scrollLeft)

	let left = (100 * pc.scrollLeft / pc.scrollWidth)
	let width = (100 * pc.clientWidth / pc.scrollWidth)

	let index = parseInt((pc.scrollLeft / pc.clientWidth) + 0.5)
	pc.__activePage = index

	if(!pc.__lis[index].classList.contains("active"))
	{
		for(let i = 0 ; i < pc.__lis.length; i++)
		{
			let li = pc.__lis[i]
			if(i == index) 
			{
				li.classList.add("active")
				pc.__pages[i].classList.add("active")
			}
			else 
			{
				li.classList.remove("active")
				pc.__pages[i].classList.remove("active")
			}
		}
	}

	//console.log(left)

	pc.__pill.style.left = left + "%"
	pc.__pill.style.width = width + "%"
}