import { h, Component, createRef, Fragment } from "preact"
import { fetchAPI } from "../../../../libs/api"
import { Dialog } from "../../../dialog"

export default class AddChampionship extends Component {
	defaultChampionship = {
		name: "",
		region: "",
		t100_season_id: null,
	}

	state = {
		seasons: [],
		isEmptyField: true,
		errorMessage: null,
		championship: this.defaultChampionship
	}

	errorDialogRef = createRef()

	componentDidMount() {
		this.fetchSeasons()
	}

	changeHandler(event, data) {
		const field = event.target?.dataset?.attr || data.props['data-attr']
		const value = typeof event === "number" ? event : event?.target?.value

		let updatedChampionship = this.state.championship
		updatedChampionship[field] = (value === "" || value === undefined) ? null : value
		if (field === "name" || field === "region") updatedChampionship[field] = value

		this.setState({ championship: updatedChampionship },
			() => {
				const { championship } = this.stat
				const isEmptyField = (championship.name === "") || (championship.region === "") || (championship.t100_season_id === null)
				this.setState({ isEmptyField })
			});
	}

	async fetchSeasons() {
		fetchAPI("/api/t100/seasons", { headers: { 'Content-Type': 'application/json' } })
			.then(response => response.json())
			.then(data => this.setState({ seasons: data.seasons }))
			.catch(error => alert(error.message))
	}

	addChampionship() {
		const { name, region, t100_season_id } = this.state.championship
		fetchAPI("/api/t100/championships", {
			method: "post",
			headers: { 'Content-Type': 'application/json' },
			body: JSON.stringify({ name: name, region: region, t100_season_id: t100_season_id })
		})
			.then(response => response.json())
			.then(() => this.props.fetchChampionships())
			.then(() => this.setState({
				championship: {
					name: "",
					region: "",
					t100_season_id: null,
				}
			}))
			.catch(error => {
				this.setState({ errorMessage: error.message })
				this.errorDialogRef.current.show()
			})
	}

	render(props, state) {
		const { isEmptyField, errorMessage } = state

		return (
			<Fragment>
				<Dialog yesText="Add Championship" title="Add a new Championship" ref={ props.reference } onYes={ this.addChampionship.bind(this) } disabled={ isEmptyField }>
					<table>
						<tr>
							<td>Name</td>
							<td>
								<input
									class="form-control form-control-sm"
									onInput={ this.changeHandler.bind(this) }
									data-attr="name"
									value={ state.championship.name }
									type="text"
								/>
							</td>
						</tr>
						<tr>
							<td>Region</td>
							<td>
								<input
									class="form-control form-control-sm"
									onInput={ this.changeHandler.bind(this) }
									data-attr="region"
									value={ state.championship.region }
									type="text"
								/>
							</td>
						</tr>
						<tr>
							<th>Season</th>
							<td>
								<div class='select'>
									<select class='form-control form-control-sm' data-attr="t100_season_id" onChange={ this.changeHandler.bind(this) }>
										<option value='' selected={ !state.championship.t100_season_id }>-</option>
										{ state.seasons.map(x => <option value={ x.id } selected={ x.id == state.championship.t100_season_id }>{ x.name }</option>) }
									</select>
								</div>
							</td>
						</tr>
					</table>
				</Dialog>

				<Dialog nofooter={ true } title="Error!" ref={ this.errorDialogRef }>
					<p>{ errorMessage }</p>
				</Dialog>
			</Fragment>
		)
	}
}
