import { h, render, Component, createRef, Fragment } from 'preact';
import { Dialog } from "./dialog"
import { Tags } from './tags'
import moment from 'moment';
import "./medias.scss"
import md5 from 'md5';
import { fetchAPI, serverAPI, websocketsServerAPI } from '../libs/api';

export class MediaPicture extends Component {
	render(props, state) {
		let media = props.media
		if(!media) return

		let srcset = "", srcset_webp = ""
		for(let _si = media.srcset.images.length - 1; _si >= 0; _si--)
		{
			let s = media.srcset.images[_si]
			if(srcset != "") srcset += ','
			srcset += media.cdn + '/' + s.src + ' ' + s.size + 'w'

			if(srcset_webp != "") srcset_webp += ','
			srcset_webp += media.cdn + '/' + s.src_webp + ' ' + s.size + 'w'
		}
		return(
			<picture>
				<source type="image/webp" srcset={srcset_webp} sizes={ props.sizes || "100w" }/>
				<source type={media.srcset.type} srcset={srcset} sizes={ props.sizes || "100w" }/>
				<img src={media.cdn + '/' + media.path_key}/>
			</picture>
		)
	}
}

export class Medias extends Component {
	medias = []
	athletes = []
	mediacategories = []
	uploading_files = []
	dialogEditPicture = createRef()
	dialogIndex = -1
	mediaEdit = null
	dialogAddTags = createRef()
	mediaPictureRef = createRef()

	fetchMedias()
	{
		let that = this

		if(this.props.fetchMedias)
		{
			this.props.fetchMedias((medias) => {
				for(let s of medias)
				{
					if(typeof s.srcset == "string") s.srcset = JSON.parse(s.srcset)
				}
				that.medias = medias
				that.setState({ update: true })
			})	
		}
		else
		{
			fetchAPI("/api/medias", {
				headers: { 'Content-Type': 'application/json' },
			})
			.then(function(response) { if(response.ok) return response.json(); return Promise.reject(response); })
			.then(function(data) {
				for(let s of data.medias)
				{
					if(typeof s.srcset == "string") s.srcset = JSON.parse(s.srcset)
				}
				that.medias = data.medias
				that.setState({ update: true })
			})
		}

		fetchAPI("/api/athletes/", {
			headers: { 'Content-Type': 'application/json' },
		})
		.then(function(response) { if(response.ok) return response.json(); return Promise.reject(response); })
		.then(function(data) {
			that.athletes = data.athletes
		})

		fetchAPI("/api/mediacategories/", {
			headers: { 'Content-Type': 'application/json' },
		})
		.then(function(response) { if(response.ok) return response.json(); return Promise.reject(response); })
		.then(function(data) {
			that.mediacategories = data.mediacategories
		})

	}

	componentDidMount() {
		this.fetchMedias()
		document.addEventListener('keydown', this.handleKeydown, { capture: false });
	}

	componentWillUnmount() {
		document.removeEventListener('keydown', this.handleKeydown);
	}
	
	handleKeydown = e => {
		if(e.shiftKey)
		{
			if(e.key == 'ArrowLeft') this.dialogPrev()
			else if(e.key == 'ArrowRight') this.dialogNext()
		}
	}


	onUpload = e => {
		let that = this
		let input = document.createElement('input')
		input.type = "file"
		input.multiple = "1"
		input.accept = "image/*"
		input.addEventListener("change", async (e) => {
			let fetches = [], converts = []
			let files = e.target.files
			let chain = Promise.resolve();
			let chainHash = Promise.resolve();
			for(let file of files)
			{
				file.uploading_index = that.uploading_files.length
				that.uploading_files.push({ file: file, progress: "Pending..." })
				that.setState({ update: true })

				chainHash = chainHash.then(() => {

					that.uploading_files[file.uploading_index].progress = "Reading..."
					that.setState({ update: true })

					var reader = new FileReader();
					let readPromise = new Promise((resolve, reject) => {
						reader.onerror = () => {
							reader.abort()
							reject(new DOMException("Problem parsing input file."))
						}

						reader.onload = (e) => { resolve(e.target.result) }
					})
					reader.readAsBinaryString(file)
					return readPromise						
				})

				chainHash = chainHash.then((filedata) => {
					that.uploading_files[file.uploading_index].progress = "Hashing..."
					that.setState({ update: true })

					let hash = md5(filedata)

					let loadJob = new Promise((resolve_load, reject) => {
						let formData = new FormData()
						let f = fetchAPI("/api/createuploadurl", {
							method: "post",
							headers: { 'Content-Type': 'application/json' },
							body: JSON.stringify({ name: file.name, size: file.size, type: file.type, lastModified: parseInt(file.lastModified / 1000), md5: hash })
						})
						.then(function(response) { if(response.ok) return response.json(); return Promise.reject(response); })
						.then(function(data_uploadurl) { 
							if(data_uploadurl.status == 2) 
							{
								that.uploading_files[file.uploading_index].progress = ""
								that.setState({ update: true })
								return
							}
							file.data_uploadurl = data_uploadurl

							that.uploading_files[file.uploading_index].progress = "In Upload Queue."
							that.setState({ update: true })

							return new Promise((resolve, reject) => {
								let xhr = new window.XMLHttpRequest()
								xhr.open('put', data_uploadurl.put)
								xhr.onload = () => { 
									
									that.uploading_files[file.uploading_index].progress = "Uploaded."
									that.setState({ update: true })

									//chain = chain.then(() => {
										that.uploading_files[file.uploading_index].progress = "Converting..."
										that.setState({ update: true })
										let p = new Promise((_resolve, reject) => {
											fetchAPI("/api/convert-image", {
												method: "post",
												headers: { 'Content-Type': 'application/json' },
												body: JSON.stringify({ file: file.data_uploadurl })
											})
											.then(response => response.json())
											.then(data_convert => {
												that.uploading_files[file.uploading_index].progress = ""
												//data_convert.media.srcset = JSON.parse(data_convert.media.srcset)
												that.medias.unshift(data_convert.media)
												that.setState({ update: true })
												_resolve()
											})
										})
										return p
									//})
									converts.push(p)
									resolve() 
								}
								xhr.onerror = () => { reject() }
								xhr.upload.addEventListener("progress", function(evt) {
									//console.log(evt)
									that.uploading_files[file.uploading_index].progress = "Uploading: " + parseInt(100 * evt.loaded / evt.total) + "%"
									that.setState({ update: true })
									//if (evt.lengthComputable) console.log(100*evt.loaded / evt.total)
								}, false);
								xhr.setRequestHeader('Content-Type', data_uploadurl.type)
								//xhr.setRequestHeader('Content-Size', file.size)
								xhr.setRequestHeader('Cache-Control', data_uploadurl.cc)
								xhr.send(file);
							})
						})

						fetches.push(f)
						resolve_load()
					})
					chainHash = chainHash.then(() => { return loadJob })
				})
				//chain.then(() => { return loadJob })
				//reader.readAsBinaryString(file)				
				//chain = chain.then(() => { console.log(file) });
				//console.log(file)
			}
	
			let results = await Promise.allSettled(fetches)
			for(let index in results)
			{
				let result = results[index]
				let file = files[index]
			}

			let converts_results = await Promise.allSettled(converts)
			chainHash.then(() => {
				console.log('Hash complete')
			});
			chain.then(() => {
				console.log('complete')
			});
		})
		input.click()
	}



	selectDialogImage(index)
	{
		let that = this
		if(index < 0) index = 0
		if(index > this.medias.length - 1) index = this.medias.length - 1

		that.setState({ editImageSrc: null })
		this.dialogIndex = index
		let media = this.medias[index]

		this.mediaEdit = null
		fetchAPI("/api/media/"+media.id, {
			headers: { 'Content-Type': 'application/json' },
		})
		.then(function(response) { if(response.ok) return response.json(); return Promise.reject(response); })
		.then(function(data) {
			that.mediaEdit = data.media
			that.setState({ update: null })
		})

		/*let image = new Image()
		image.addEventListener('load', function() {
			that.setState({ editImageSrc: this.src })
		})
		image.src = media.cdn + '/' + media.srcset.images[0].src*/
	}

	handleClickImage = e => {
		const path = e.path || (e.composedPath && e.composedPath());
		let item = path.find(x => x.classList.contains('media-item'))
		if(!item) return
		let id = item.dataset.id
		let index = parseInt(item.dataset.index)

		if(this.state.bulkselect)
		{
			if(e.shiftKey && this.bulkLastSelected != index)
			{
				let bulkLastSelected = this.bulkLastSelected
				if(bulkLastSelected == -1)
				{
					for(let i = 0; i < this.medias.length; i += 1)
					{
						if(this.medias[i].selected) { bulkLastSelected = i; break; }
					}
				}
				if(bulkLastSelected != -1)
				{
					let direction = bulkLastSelected < index ? 1 : -1
					for(let i = bulkLastSelected; i != index; i += direction)
					{
						this.medias[i].selected = true		
					}
					this.medias[index].selected = true		
					this.bulkLastSelected = index
				}
			}
			else
			{
				this.medias[index].selected = !this.medias[index].selected
				if(!this.medias[index].selected) this.bulkLastSelected = -1
				else this.bulkLastSelected = index

			}
			this.setState({ update: null })
		}
		else
		{
			this.selectDialogImage(index)
			this.dialogEditPicture.current.show()
		}
	}

	dialogOnShow = () =>
	{
	}

	dialogOnHide = () =>
	{
		this.dialogIndex = -1
		this.setState({ editImageSrc: null })
	}

	dialogPrev = () => {
		if(this.dialogIndex < 0) return
		this.selectDialogImage(this.dialogIndex - 1)
	}

	dialogNext = () => {
		if(this.dialogIndex < 0) return
		this.selectDialogImage(this.dialogIndex + 1)
	}

	onTagAthletes = (v) => {
		let media = this.medias[this.dialogIndex]
		let tag_ids = v == '' ? [] : v.split(',').map(x => parseInt(x))
		let f = fetchAPI("/api/tag", {
			method: "post",
			headers: { 'Content-Type': 'application/json' },
			body: JSON.stringify({ item_id: media.id, item_type: 'media', tag_ids: tag_ids, tag_type: 'athlete' })
		})
	}

	onTagCategories = (v) => {
		let media = this.medias[this.dialogIndex]
		let tag_ids = v == '' ? [] : v.split(',').map(x => parseInt(x))
		let f = fetchAPI("/api/tag", {
			method: "post",
			headers: { 'Content-Type': 'application/json' },
			body: JSON.stringify({ item_id: media.id, item_type: 'media', tag_ids: tag_ids, tag_type: 'category' })
		})
	}

	onBulkSelect = e => {
		this.bulkLastSelected = -1
		for(let m of this.medias) m.selected = false
		this.setState({ bulkselect: true })
	}

	onCancelBulkSelect = e => {
		for(let m of this.medias) m.selected = false
		this.setState({ bulkselect: false })
	}

	onClearBulkSelect = e => {
		for(let m of this.medias) m.selected = false
		this.setState({})
	}

	onBulkTagAthletes = (v) => {
		this.bulkTagAthletes = v == '' ? null : v.split(',').map(x => parseInt(x))
	}

	onBulkTagCategories = (v) => {
		this.bulkTagCategories = v == '' ? null : v.split(',').map(x => parseInt(x))
	}

	onAddTags = e => {
		this.bulkTagAthletes = null
		this.bulkTagCategories = null
		this.dialogAddTags.current.show()
	}

	onSaveBulkTags = e => {
		//console.log(this.bulkTagAthletes, this.bulkTagCategories, this.medias.filter(x => x.selected).map(x => x.id))		
		if(this.bulkTagAthletes)
		{
			let f = fetchAPI("/api/tagbulk", {
				method: "post",
				headers: { 'Content-Type': 'application/json' },
				body: JSON.stringify({ item_ids: this.medias.filter(x => x.selected).map(x => x.id), item_type: 'media', tag_ids: this.bulkTagAthletes, tag_type: 'athlete' })
			})
		}
		if(this.bulkTagCategories)
		{
			let f = fetchAPI("/api/tagbulk", {
				method: "post",
				headers: { 'Content-Type': 'application/json' },
				body: JSON.stringify({ item_ids: this.medias.filter(x => x.selected).map(x => x.id), item_type: 'media', tag_ids: this.bulkTagCategories, tag_type: 'category' })
			})
		}
	}

	refMedias = d => {
		if(!d) return

		d.__count_drag = 0
		d.addEventListener("dragenter", (e) => {
			if(d.__count_drag == 0) d.classList.add("drop")
			d.__count_drag += 1
			e.preventDefault();  
			e.stopPropagation();
		})
		d.addEventListener("dragleave", (e) => {
			d.__count_drag -= 1
			if(d.__count_drag == 0) d.classList.remove("drop")
		})
		d.addEventListener("dragover", (e) => {
			e.preventDefault();  
			e.stopPropagation();
		}, false)
		d.addEventListener("drop", (e) => {
			d.__count_drag = 0
			d.classList.remove("drop")

			var dt = e.dataTransfer;
			for (var i = 0; i < dt.files.length; i++) 
			{
				var file = dt.files[i];

				if(file.type.startsWith("image"))
				{
					console.log(file)
				}
			}

			e.preventDefault();  
			e.stopPropagation();
		})
/*		
$(".viewport").on("dragenter", function(e) {
	var vpi = parseInt($(this).attr("vp"));
	if(__count_drag__[vpi] == 0) $(this).addClass("dragging");
	__count_drag__[vpi] += 1;
	e.preventDefault();  
	e.stopPropagation();
});

$(".viewport").on('dragover', false);

$(".viewport").on("drop", function(e) {
	var vpi = parseInt($(this).attr("vp"));
	e.preventDefault();  
	e.stopPropagation();

	if(!isCurrentUserPremium())
	{
		$("#go-premium").click();
		$("#premium-message").text("Drag & Drop is only supported for Premium members.")
		return;
	}

	$(viewports[vpi].domViewport).removeClass("select-upload");

	var dt = e.originalEvent.dataTransfer;
	for (var i = 0; i < dt.files.length; i++) 
	{
		var file = dt.files[i];

		if(file.type.startsWith("image"))
		{
			var reader = new FileReader();
			reader.onload = function () {
				var image = new Image();
				image.onload = function() {
					this.type = "image";
					this.paused = true;
					this.pause = function() { this.paused = true; };
					this.play = function() { this.paused = false; };
					this.duration = 10;
					this.currentTime = 0;
					this.fps = 30;
					this.playbackRate = 1;
					this.seeking = false;

					viewports[vpi].show(false);
					viewports[vpi].reset();
					viewports[vpi].push(true);
					ProgressBarUpdate(viewports[vpi].progressBar, { max: this.duration, value: this.currentTime, start: 0, end: this.duration, step: this.fps });
					viewports[vpi].media_id = undefined;
					viewports[vpi].setMedia(this);
					$(viewports[vpi].domViewport).find(".viewport-media-info").text("[ " + this.width + " x " + this.height + " ]");
					viewports[vpi].videoPlayer.videoCurrentTime.currentTime = -1;
					viewports[vpi].show(true);

					onWindowResize();
				};
				image.src = this.result;
			};
			reader.readAsDataURL(file);
		}
		else //if(file.type.startsWith("video"))
		{
			var fileURL = URL.createObjectURL(file);

			var video = document.createElement("video");
			video.type = "video";
			video.fps = 30;
			video.autoplay = false;
			video.crossOrigin = "anonymous";
			video.preload = 'auto';
			video.autoload = true;
			video.muted = true;
			video.__seeking = false;
			video.playsInline = true;
			video.onseeked = function() 
			{ 
				this.__seeking = false; 
				this.__seeked = 10;  
				console.log("seeked", this.wantTime);
				if(this.wantTime != undefined)
				{
					this.currentTime = media.wantTime;
					this.wantTime = undefined;
					viewports[vpi].needsUpdate()
				}
			};
			video.onseeking = function() 
			{ 
				this.__seeking = true;  
				console.log("seeking");
			};
			video.addEventListener('loadedmetadata', function() {
				this.volume = 0;
				this.muted = true;

				viewports[vpi].show(false);
				viewports[vpi].reset();
				viewports[vpi].push(true);
				ProgressBarUpdate(viewports[vpi].progressBar, { max: this.duration, value: this.currentTime, start: 0, end: this.duration, step: this.fps });
				viewports[vpi].media_id = undefined;
				viewports[vpi].setMedia(this);
				$(viewports[vpi].domViewport).find(".viewport-media-info").text("[ " + video.videoWidth + " x " + video.videoHeight + " ]");
				viewports[vpi].videoPlayer.videoCurrentTime.currentTime = -1;
				viewports[vpi].show(true);

				this.pause();
				onWindowResize();
			});
			//video.autoplay = false;
			//video.crossOrigin = "";
			video.src = fileURL;

		}
		break;	
	}
	//console.log("dragover");
	$(this).removeClass("dragging");
	__count_drag__[vpi] = 0;
});

$(".viewport").on("dragleave", function(e) {
	var vpi = parseInt($(this).attr("vp"));
	__count_drag__[vpi] -= 1;
	if(__count_drag__[vpi] == 0) $(this).removeClass("dragging");
	e.preventDefault();  
	e.stopPropagation();
});
*/
	}

	render(props, state) {
		let __medias = this.medias
		let dialogMedia = this.dialogIndex == -1 ? null : this.medias[this.dialogIndex]

		//console.log(this.mediaPictureRef.current?.base?.innerHTML)

		return(
			<div class='medias' ref={this.refMedias}>
				{this.props.title && <h2 class='headline'>{this.props.title}</h2>}
				<div class='upload-section'>
				</div>
				<div class='upload-jobs'>
					{this.uploading_files.map(f => {
						if(f.progress == "") return
						return(
							<div class='job'>
								<div class='job-name'>{f.file.name}</div>
								<div class='job-progress'>{f.progress}</div>
							</div>
						)
					})}
				</div>
				<div class='card toolbar'>
					{ !state.bulkselect &&
					<div class='d-flex justify-content-between w-100'>
						<button class='btn btn-sm btn-primary' onClick={this.onUpload}>Upload Media</button>
						<button class='btn btn-sm btn-secondary' onClick={this.onBulkSelect}>Bulk Select</button>
					</div>
					}

					{ state.bulkselect &&
					<Fragment>
						<div>
							<button class='btn btn-sm btn-secondary' onClick={this.onAddTags}>Add Tags</button>
						</div>
						<div>
							<button class='btn btn-sm btn-secondary mr-2' onClick={this.onClearBulkSelect}>Clear Selection</button>
							<button class='btn btn-sm btn-secondary' onClick={this.onCancelBulkSelect}>Cancel</button>
						</div>
					</Fragment>
					}
				</div>
				<div class='card'>
					<div class='media-list'>
						{ __medias.map((media, index) => {
							let srcset = "", srcset_webp = ""
							//for(let s of media.srcset.images)
							for(let _si = media.srcset.images.length - 1; _si >= 0; _si--)
							{
								let s = media.srcset.images[_si]
								if(srcset != "") srcset += ','
								srcset += media.cdn + '/' + s.src + ' ' + s.size + 'w'

								if(srcset_webp != "") srcset_webp += ','
								srcset_webp += media.cdn + '/' + s.src_webp + ' ' + s.size + 'w'
							}
							return(
								<div class={'media-item' + ((state.bulkselect && media.selected) ? " selected" : "")} key={'media' + media.id} data-id={media.id} data-index={index} onClick={this.handleClickImage}>
									<div class='image-container'>
										<picture>
											<source type="image/webp" srcset={srcset_webp} sizes="160px"/>
											<source type={media.srcset.type} srcset={srcset} sizes="160px"/>
											<img src={media.cdn + '/' + media.path_key}/>
										</picture>
									</div>
								</div>
							)
						})}
					</div>
				</div>
				<div class='dialog-edit-picture'>
					<Dialog yesText="Save" 
						title="Edit Picture" 
						ref={this.dialogEditPicture} 
						onShow={this.dialogOnShow} 
						onHide={this.dialogOnHide} 
						headerControls={<Fragment>
							<button class='btn btn-sm btn-outline-light px-3' onClick={this.dialogPrev}><i class="fas fa-chevron-left"></i></button>
							<button class='btn btn-sm btn-outline-light px-3 ml-1' onClick={this.dialogNext}><i class="fas fa-chevron-right"></i></button>
							</Fragment>}
						>
						<div class='d-flex h-100'>
							<div class='image-container'>
								<MediaPicture ref={this.mediaPictureRef} media={this.mediaEdit}/>
							</div>
							<div class='editing'>
								{ this.mediaEdit && 
									<table>
										<tr><th></th><td></td></tr>
										<tr><th>Filename</th><td>{this.mediaEdit.filename}</td></tr>
										<tr><th>Date</th><td>{moment().format("LLL")}</td></tr>
										<tr><th>Type</th><td>{this.mediaEdit.type}</td></tr>
										<tr><th>Size Original</th><td>{this.mediaEdit.width} x {this.mediaEdit.height}</td></tr>
										<tr><th>Date</th><td><input class='form-control form-control-sm' type="date"/></td></tr>
										<tr>
											<th>Athletes</th>
											<td>
												<Tags value={this.mediaEdit.tags.athletes.map(t => t.id)} autofocus placeholder="Tag an athlete..." options={this.athletes} onChange={ v => { this.onTagAthletes(v) } } />
											</td>
										</tr>
										<tr>
											<th>Categories</th>
											<td>
												<Tags value={this.mediaEdit.tags.categories.map(t => t.id)} placeholder="Add a category..." options={this.mediacategories} onChange={ v => { this.onTagCategories(v) } } />
											</td>
										</tr>
										<tr>
											<th>Event</th>
											<td>
												<Tags value="" placeholder="Select an event..." options={this.athletes} onChange={ v => {  } } />
											</td>
										</tr>
										<tr>
											<th>Title</th>
											<td>
												<input class='form-control form-control-sm'/>
											</td>
										</tr>
										<tr>
											<th>Caption</th>
											<td>
												<textarea class='form-control form-control-sm' rows="5"/>
											</td>
										</tr>
										<tr>
											<th>Alt Text</th>
											<td>
												<input class='form-control form-control-sm'/>
											</td>
										</tr>
										<tr>
											<th>Description</th>
											<td>
												<textarea class='form-control form-control-sm' rows="5"/>
											</td>
										</tr>
										<tr>
											<th>Copyright</th>
											<td>
												<input class='form-control form-control-sm'/>
											</td>
										</tr>
										<tr>
											<th>HTML</th>
											<td>
												<textarea class='form-control form-control-sm'>
													{this.mediaPictureRef.current?.base?.innerHTML}
												</textarea>
											</td>
										</tr>
									</table>
								}
							</div>
						</div>
					</Dialog>	
				</div>
				<Dialog yesText="Save" title="Add Tags" ref={this.dialogAddTags} onYes={this.onSaveBulkTags}>
					<div class='d-flex h-100'>
						<div class='editing'>
							<table>
								<tr>
									<th>Athletes</th>
									<td>
										<Tags value='' autofocus placeholder="Tag an athlete..." options={this.athletes} onChange={ v => { this.onBulkTagAthletes(v) } } />
									</td>
								</tr>
								<tr>
									<th>Categories</th>
									<td>
										<Tags value='' placeholder="Add a category..." options={this.mediacategories} onChange={ v => { this.onBulkTagCategories(v) } } />
									</td>
								</tr>
								<tr>
									<th>Event</th>
									<td>
										<Tags value="" placeholder="Select an event..." options={this.athletes} onChange={ v => {  } } />
									</td>
								</tr>
							</table>
						</div>
					</div>
				</Dialog>	
			</div>
		)
	}
}

