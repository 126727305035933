import { h, Component, createRef, Fragment } from "preact"
import { fetchAPI } from "../../../../libs/api"
import { Dialog } from "../../../dialog"

export default class AddContractTier extends Component {
	defaultContractTier = {
		name: "",
		order: null
	}

	state = {
		isEmptyField: true,
		errorMessage: null,
		contractTier: this.defaultContractTier
	}

	errorDialogRef = createRef()

	changeHandler(event, data) {
		const field = event.target?.dataset?.attr || data.props['data-attr']
		const value = typeof event === "number" ? event : event?.target?.value

		let updatedContractTier = this.state.contractTier
		updatedContractTier[field] = (value === "" || value === undefined) ? null : value
		if (field === "name" || field === "region") updatedContractTier[field] = value

		this.setState({ contractTier: updatedContractTier },
			() => {
				const { contractTier } = this.state
				const isEmptyField = (contractTier.name === "") || (contractTier.order === null)
				this.setState({ isEmptyField })
			})
	}

	addContractTier() {
		const { name, order } = this.state.contractTier

		fetchAPI("/api/t100/contract-tiers", {
			method: "post",
			headers: { 'Content-Type': 'application/json' },
			body: JSON.stringify({ name: name, order: order })
		})
			.then(response => response.json())
			.then(() => this.props.fetchContractTiers())
			.then(() => this.setState({
				contractTier: {
					name: "",
					order: null
				}
			}))
			.catch(error => {
				this.setState({ errorMessage: error.message })
				this.errorDialogRef.current.show()
			})
	}

	render(props, state) {
		const { isEmptyField, errorMessage } = state

		return (
			<Fragment>
				<Dialog yesText="Add Contract Tier" title="Add a new Contract Tier" ref={ props.reference } onYes={ this.addContractTier.bind(this) } disabled={ isEmptyField }>
					<table>
						<tr>
							<td>Name</td>
							<td>
								<input
									class="form-control form-control-sm"
									onInput={ this.changeHandler.bind(this) }
									data-attr="name"
									value={ state.contractTier.name }
									type="text"
								/>
							</td>
						</tr>
						<tr>
							<td>Display Order</td>
							<td>
								<input
									class="form-control form-control-sm"
									onInput={ this.changeHandler.bind(this) }
									data-attr="order"
									value={ state.contractTier.order }
									type="number"
									step="1"
									min="1"
								/>
							</td>
						</tr>
					</table>
				</Dialog>

				<Dialog nofooter={ true } title="Error!" ref={ this.errorDialogRef }>
					<p>{ errorMessage }</p>
				</Dialog>
			</Fragment>
		)
	}
}
