import { h, render, Component, createRef, Fragment } from 'preact'
import Router from 'preact-router'
import { route } from 'preact-router'

import { fetchAPI } from './libs/api'

import { Login, Signup, Forgot, VerifyCode, Password, VerifyEmail } from './components/auth'
import { Athlete } from './components/athlete'
import { Athletes } from './components/athletes'
import { Medias } from './components/medias'
import { Brands } from './components/brands'
import { Brand } from './components/brand/brand'
import { Races } from './components/races'
import { Race } from './components/race'
import { Series } from './components/series/series'
import { Serie } from './components/serie/serie'
import { Tiers } from './components/tiers/tiers'
import { Tier } from './components/tier/tier'
import { T100Seasons } from './components/t100/seasons/seasons'
import { T100Season } from './components/t100/season/season'
import { T100Championships } from './components/t100/championships/championships'
import { T100Championship } from './components/t100/championship/championship'
import { T100ContractTiers } from './components/t100/contractTiers/contractTiers'
import { T100ContractTier } from './components/t100/contractTier/contractTier'
import { Rankings, Ranking } from './components/rankings'
import { Posts, Post } from './components/posts'
import { News, NewsEntry } from './components/news'
import { Videos } from './components/videos'
import { PleaseWait } from './components/utils'
import { Topbar } from './components/topbar'
import { T100SeasonParticipant } from './components/t100/season/participant'

import '/client/search'
import '/client/modal'
import '/client/table-sort'
import '/client/auth'
import '/client/page'

const t100Icon = (
	<svg width="261" height="50" viewBox="0 0 261 50" fill="none" xmlns="http://www.w3.org/2000/svg">
		<g clip-path="url(#clip0_0_3)">
			<path d="M152 1C167.354 0.999999 182.708 0.999999 198.531 1.39232C198.905 2.42299 198.985 3.15834 198.691 3.68526C193.541 12.9165 187.997 21.9478 183.271 31.3889C179.483 38.9541 174.147 44.275 166.325 47.387C164.955 47.9324 164.095 49.763 163 51C147.312 51 131.625 51 115.473 50.624C120.02 41.32 125.255 32.5088 129.97 23.4278C133.884 15.8906 138.28 9.06762 146.272 5.21644C148.371 4.20477 150.101 2.42632 152 1ZM163.618 10.9947C160.494 11.0938 157.321 10.257 155.285 14.1435C150.558 23.1639 145.414 31.9654 140.407 40.9143C146.26 40.9143 151.905 40.9143 157.389 40.9143C163.037 31.092 168.607 21.4042 174.593 10.9947C170.733 10.9947 167.648 10.9947 163.618 10.9947Z" fill="white" />
			<path d="M215 1C230.667 1 246.333 1 262 1C262 2.6994 262 4.35023 261.704 6.21096C260.611 7.22765 259.603 7.91135 259.052 8.86043C253.986 17.5881 248.754 26.234 244.092 35.1753C241.728 39.707 238.761 42.942 234.103 45.1112C231.132 46.4947 228.683 48.9985 226 51C210.646 51 195.292 51 179.469 50.612C179.191 49.4498 179.223 48.5892 179.594 47.9147C184.072 39.7758 188.53 31.6246 193.134 23.5573C197.647 15.649 201.801 7.38999 211.684 4.58902C213.021 4.21009 213.906 2.23565 215 1ZM216.032 18.6483C211.968 25.9367 207.904 33.2252 203.569 40.9995C208.089 40.9995 211.76 40.7262 215.37 41.0736C219.26 41.4478 221.394 40.0837 223.256 36.5709C227.802 27.9923 232.878 19.6948 238.049 10.752C230.044 12.1822 220.599 7.0412 216.032 18.6483Z" fill="white" />
			<path d="M46 51C36.9791 51 27.9582 51 18.4769 50.6262C21.8129 43.2118 25.4864 36.1012 29.445 29.1531C32.6071 23.6029 36.7533 19.1162 43.1275 16.8949C46.7963 15.6164 50.1439 13.4161 54.8697 11C35.9963 11 18.4981 11 1 11C1 7.67847 1 4.35694 1 1C38.6876 1 76.3751 1 114.528 1.37928C107.567 6.44217 100.328 11.4616 90.8717 11.0491C84.8926 10.7883 78.8753 11.2768 72.9087 10.899C69.1979 10.6641 67.4595 12.2105 65.9081 15.2525C63.6348 19.7099 60.8233 23.8891 58.3899 28.2694C54.1995 35.8127 50.1238 43.4197 46 51Z" fill="white" />
			<path d="M85.4686 51C89.3504 42.8199 93.669 34.6226 98.0693 26.4694C99.5145 23.7916 101.171 21.228 102.98 18.1947C101.151 18.0514 99.645 17.9335 97.1374 17.7371C98.7197 16.4878 99.5059 15.5659 100.504 15.126C109.142 11.3204 117.831 7.62888 126.469 3.82399C127.608 3.32214 128.551 2.37355 129.292 1.31594C130.714 1 132.429 1 134.571 1.38411C134.696 2.66864 134.536 3.65202 134.068 4.45691C128.023 14.8663 121.847 25.2004 115.895 35.6624C113.054 40.656 110.619 45.8802 108 51C100.646 51 93.2915 51 85.4686 51Z" fill="white" />
		</g>
		<defs>
			<clipPath id="clip0_0_3">
				<rect width="261" height="50" fill="white" />
			</clipPath>
		</defs>
	</svg>
)

let menuToggle = document.getElementById('menu-toggle')
let sideMenu = document.getElementById('side-menu')
let sideMenuBackground = document.getElementById('side-menu-background')
if(menuToggle)
{
	menuToggle.addEventListener("click", (e) => {
		menuToggle.classList.toggle("cross")
		sideMenu.classList.toggle("show")
		document.body.classList.toggle("noscroll")
	})

	if(sideMenuBackground)
	{
		sideMenuBackground.addEventListener("click", (e) => {
			menuToggle.click()
		})
	}
}


let all_navlinks = Array.from(document.getElementsByClassName("nav-link"))
all_navlinks.forEach( function(element, index) {
	element.addEventListener("click", function(e) {
		e.preventDefault()

		let tab = document.getElementById(this.attributes.getNamedItem("href").nodeValue)
		let actives = tab.parentNode.getElementsByClassName("active")
		for(let i = 0; i < actives.length; i++)
		{
			actives[i].classList.remove("active")
		}
		tab.classList.add("active")


		actives = this.parentNode.getElementsByClassName("active")
		for(let i = 0; i < actives.length; i++)
		{
			actives[i].classList.remove("active")
		}
		this.classList.add("active")

	})
})

class Sidebar extends Component {
	items = [
		{ url: "/", i: "fas fa-tachometer-alt", name: "Dashboard" },
		{ url: "/athletes", i: "fas fa-user", name: "Athletes" },
		{ url: "/brands", i: "far fa-copyright", name: "Brands" },
		{ url: "/news", i: "fas fa-newspaper", name: "News" },
		{ url: "/medias", i: "fas fa-photo-video", name: "Medias" },
		{ url: "/posts", i: "fas fa-file-alt", name: "Posts" },
		{ url: "/races", i: "fas fa-flag-checkered", name: "Races" },
		{ url: "/rankings", i: "fas fa-list-ol", name: "Rankings" },
		{ url: "/series", i: "fas fa-medal", name: "Series" },
		{ url: "/tiers", i: "fas fa-gem", name: "Tiers" },
		{ url: "/videos", i: "fas fa-video", name: "Videos" },
		{ url: "/t100/championships", customI: t100Icon, name: "Championships" },
		{ url: "/t100/seasons", customI: t100Icon, name: "Seasons" },
		{ url: "/t100/contract-tiers", customI: t100Icon, name: "Contract Tiers" },
	]
	render(props, state) {
		return(<nav class='' hidden={props.hidden}>
			{ this.items.map(x => (
				<a class={'item' + (x.url == props.url ? " selected" : "")} href={x.url}>
					{x.i ? <i class={x.i}></i> : x.customI} {x.name}
				</a>
			))}
		</nav>)
	}
}

class Settings extends Component {
	logout = e => {
		fetchAPI("/auth/logout", {
			method: "post",
			headers: { 'Content-Type': 'application/json' },
		})
		.then(function(response) { if(response.ok) return response.json(); return Promise.reject(response) })
		.then(function(data) {
			localStorage.removeItem('ptotoken')
			//Cookies.remove('pto_token')
			location = "/"
		})
	}
	render(props)
	{
		return(<div class='container mt-4'>You're logged in. <button class='btn btn-sm btn-primary' onClick={this.logout}>Logout</button></div>)
	}	
}

class Dashboard extends Component {
	state = { working: false }

	sync = e => {
		let that = this
		this.setState({ working: true })
		fetchAPI("/api/sync", {
			method: "post",
			headers: { 'Content-Type': 'application/json' },
		})
		.then(function(response) { if(response.ok) return response.json(); return Promise.reject(response) })
		.then(function(data) { 
			that.setState({ working: false })
		})
		.catch(function(data) { 
			that.setState({ working: false })
			alert("An error occured! Contact Christophe.")
		})
	}

	wordpressSync = e => {
		let that = this
		this.setState({ working: true })
		fetchAPI("/api/wordpress/sync", {
			method: "post",
			headers: { 'Content-Type': 'application/json' },
		})
		.then(function(response) { if(response.ok) return response.json(); return Promise.reject(response) })
		.then(function(data) { 
			that.setState({ working: false })
		})
		.catch(function(data) { 
			that.setState({ working: false })
			alert("An error occured! Contact Christophe.")
		})
	}
	

	athlete_cleanup = e => {
		let that = this
		this.setState({ working: true })
		fetchAPI("/api/athletes/cleanup", {
			method: "post",
			headers: { 'Content-Type': 'application/json' },
		})
		.then(function(response) { if(response.ok) return response.json(); return Promise.reject(response) })
		.then(function(data) { 
			that.setState({ working: false })
		})
		.catch(function(data) { 
			that.setState({ working: false })
			alert("An error occured! Contact Christophe.")
		})
	}

	im_sync = e => {
		let that = this
		this.setState({ working: true })

		fetchAPI("/api/im/sync", {
			headers: { 'Content-Type': 'application/json' },
		})
		.then(function(response) { if(response.ok) return response.json(); return Promise.reject(response) })
		.then(function(data) { 
			that.setState({ working: false })
		})
	}

	render(props, state) {
		return(
			<div>
				<div>Dashboard</div>
				<div class='my-1'><button class='btn btn-sm btn-warning' onClick={this.sync}>Sync to Rankings Website</button></div>
				<div class='my-1 mt-4'><button class='btn btn-sm btn-warning' onClick={this.im_sync}>Sync IM Races</button></div>
				<div class='my-1'><button class='btn btn-sm btn-warning d-none' onClick={this.athlete_cleanup}>Clean up Athletes</button></div>
				<PleaseWait show={state.working}/>
			</div>
		)
	}
}

async function suggestPeople(searchTerm) {
	const allPeople = [
		{ id: 1, value: "Fredrik Sundqvist" },
		{ id: 2, value: "Patrik Sjölin" }
	]
	return allPeople.filter(person => person.value.includes(searchTerm))
}

class QuillEditor extends Component {
	constructor (props) {
		super(props)
		this.state = {
			html: '',
			editor: null,
		}
	}

	componentDidMount () {
		this.editor = new Quill(this.props.container, { 
			theme: 'snow',
			modules: {
				mention: {
					allowedChars: /^[A-Za-z\sÅÄÖåäö]*$/,
					mentionDenotationChars: ["@", "#"],
					showDenotationChar: false,
					blotName: 'athlete',
					source: async function(searchTerm, renderList) {
						const matchedPeople = await suggestPeople(searchTerm)
						renderList(matchedPeople)
					},
					onSelect: function(item, insertItem) {
						console.log(item)
						insertItem(item)
					}
				}				
				//toolbar: ['bold', 'italic', 'underline', 'strike']
			}
		})
		//this.context.setEditor(this.editor)
	}

	shouldComponentUpdate (nextProps, nextState) {
		return false
	}

	render () {
		return <div />
	}
}

class _Posts extends Component {
	editorRef = createRef()
	/*textareaRef = (e) => {
		console.log(e, tinymce, document.body)
		tinymce.init({ 
			target: e, 
			plugins: "flags a11ychecker advcode casechange formatpainter linkchecker autolink lists checklist media mediaembed pageembed permanentpen powerpaste table advtable tinymcespellchecker",
			toolbar: 'a11ycheck addcomment casechange checklist code formatpainter pageembed permanentpen table',
		 	toolbar_mode: 'floating',
		 	//tinycomments_mode: 'embedded',
		 })
	}

	shouldComponentUpdate() { return false }*/

	click = () => {
		this.editorRef.current.editor.insertContent('click')
	}

	render() {
		return(
			<Fragment>
				<div class='d-flex flex-column w-100'>
					<div class="form-group mb-4">
						<label>Title</label>
						<input class='form-control'/>
					</div>
					<QuillEditor placeholder={''} container='#editor' theme={'bubble'} />
					<div id='editor' />
					<div class="form-row mt-4">
						<div class="form-group col-md-6">
							<label>URL</label>
							<input class='form-control'/>
						</div>
						<div class="form-group col-md-6">
							<label>Tags</label>
							<input class='form-control'/>
						</div>
					</div>
				</div>
			</Fragment>
		)
	}
}


class App extends Component {
	
	state = { nosidebar: false }

	handleRoute = (e) => { 
		if((!e.current.props.nosidebar && !this.props.user) || (this.props.user && this.props.user.admin == 0)) route("/login", true)
		this.setState({ nosidebar: e.current && e.current.props.nosidebar }) 
	}
	
	render(props, state) {
		return(
			<div class='d-flex flex-column w-100'>
				<div id="topbar-area" hidden={state.nosidebar}>
					<Topbar user={props.user}/>
				</div>
				<div class='d-flex w-100 h-100 overflow-hidden'>
					<Sidebar url={state.url} hidden={state.nosidebar}/>
					<main>
						<Router onChange={this.handleRoute}>
							<Dashboard path="/" />
							<Athletes path="/athletes" />
							<Athlete path="/athlete/:id" />
							<Brands path="/brands" />
							<Brand path="/brand/:id" />
							<Races path="/races" />
							<Race path="/race/:id" />
							<Series path="/series" />
							<Serie path="/serie/:alias" />
							<Rankings path="/rankings" />
							<Ranking path="/ranking/:id" />
							<Medias path="/medias" title="Medias"/>
							<Posts path="/posts" />
							<Post path="/post/:id" />
							<News path="/news" />
							<NewsEntry path="/news/:id" />
							<Videos path="/videos" />
							<Tiers path="/tiers" />
							<Tier path="/tier/:id" />			
							<T100Season path="/t100/season/:id" />
							<T100Seasons path="/t100/seasons" />
							<T100Championships path="/t100/championships" />
							<T100Championship path="/t100/championship/:id" />
							<T100ContractTiers path="/t100/contract-tiers" />
							<T100ContractTier path="/t100/contract-tier/:id" />
							<T100SeasonParticipant path="/t100/season/:season_id/participants/:id" />
							<Login path="/login" nosidebar/>
							<Signup path="/signup" nosidebar/>
							<Forgot path="/forgot" nosidebar/>
							<Settings path="/settings" nosidebar/>
							<VerifyEmail path="/verifyemail/:email" nosidebar/>
							<VerifyCode path="/verifycode/:email" nosidebar/>
							<Password path="/password/:token/:email" nosidebar/>
						</Router>
					</main>
				</div>
			</div>
		) 		
	}
}

sessionStorage.clear()

let __server_version__ = 0

fetchAPI("/auth/verifysession", { method: "post" })
.then(data => data.json())
.then(data => {
	console.log(data)
	__server_version__ = data.server_version

	let app = document.getElementById("app")
	if(app)
	{
		let ssr = document.getElementById("ssr")
		ssr.remove()

		app.classList.add("margin-top")
		render(<App user={data.user}/>, app)
	}

	//if(data.status != 0) route("/login")
})
