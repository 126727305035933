import { h, render, Component, createRef, Fragment } from 'preact';

export class YesNoDialog extends Component {

	options = null;
	state = { show: false, title: "", question: "" }

	show(title, question, options) 
	{ 
		this.setState({show: true, title: title, question: question}); 
		document.addEventListener('keydown', this.onKeyDown, true);
		this.options = options;
	}
	
	componentDidMount() { }
	componentDidUpdate() { }

	onKeyDown = (e) => {
		if(e.code == "Escape")
		{
			this.onCancel();
		}
		else if(e.code == "Enter")
		{
			this.onYes();
		}
		e.stopPropagation();
		return false;
	}

	onYes = () => {
		document.removeEventListener('keydown', this.onKeyDown, true);
		this.setState({ show: false }); 
		if(this.options && this.options.onYes) this.options.onYes();
		this.options = null;
	}
	onCancel = () => {
		document.removeEventListener('keydown', this.onKeyDown, true);
		this.setState({ show: false }); 
		if(this.options && this.options.onCancel) this.options.onCancel();
		this.options = null;
	}

	render(props, state) {
		if(!state.show) return;

		return(
			<div class='ox-modal'>
				<div class='dialog'>
					<div class='header'>
						<div style='font-size: 1.2em; font-weight: bold;'>{state.title}</div>
						<Icon class='close-modal material-icons-round' onClick={this.onCancel}>close</Icon>
					</div>
					<div class='body'>
						<div class=''>{state.question}</div>
					</div>
					<div class='footer justify-content-end'>
						<button class='btn btn-sm btn-dark' onClick={this.onCancel}>Cancel</button>
						<button class='btn btn-sm btn-danger ml-2' onClick={this.onYes}>Yes</button>
					</div>
				</div>
			</div>
		);
	}
}

export class Dialog extends Component {

	options = null;
	state = { show: false }

	show(options) 
	{ 
		//document.body.classList.add("noscroll")

		this.scrollPosition = window.pageYOffset;
		document.body.style.overflow = 'hidden';
		document.body.style.position = 'fixed';
		document.body.style.top = `-${this.scrollPosition}px`;
		document.body.style.width = '100%';

		let yesText = options && options.yesText
		let title = options && options.title
		let that = this
		this.setState({
			show: true, 
			yesText: yesText, 
			title: title
		}, () => {
			if(options && options.shown) options.shown()
		}); 
		//document.addEventListener('keydown', this.onKeyDown, true);
		this.options = options;
	}
	
	hide()
	{
		//document.body.classList.remove("noscroll")

		document.body.style.removeProperty('overflow');
		document.body.style.removeProperty('position');
		document.body.style.removeProperty('top');
		document.body.style.removeProperty('width');
		window.scrollTo(0, this.scrollPosition);

		if(this.props.onHide) this.props.onHide()
		this.setState({show: false}); 
	} 
	
	componentDidMount() { }
	//componentDidUpdate() { }

	/*onKeyDown = (e) => {
		if(e.code == "Escape")
		{
			this.onCancel();
		}
		else if(e.code == "Enter")
		{
			this.onYes();
		}
		e.stopPropagation();
		return false;
	}*/

	componentDidUpdate({ }, { show }) {
		if (this.state.show && !show) {
			if(this.props.onShow) this.props.onShow()
		}
	}

	onYes = () => {
		//document.removeEventListener('keydown', this.onKeyDown, true);
		let cancel = false
		if(this.props.onYes) 
		{
			cancel = this.props.onYes();
		}
		if(!cancel) this.hide()
		
		if(this.options && this.options.onYes) this.options.onYes();
		this.options = null;
	}
	onCancel = () => {
		//document.removeEventListener('keydown', this.onKeyDown, true);
		this.hide()
		if(this.options && this.options.onCancel) this.options.onCancel();
		this.options = null;
	}

	render(props, state) {
		//console.log('render', state.show)
		//if(!state.show) return;

		return(
			<div class={'ox-modal' + (state.show ? " show" : "") }>
				<div class='dialog' large={props.large}>
					{ !props.noheader && 
						<div class='header'>
							<div class='mr-4' style='font-size: 1.2em; font-weight: bold;'>{state.title || props.title}</div>
							{ props.headerControls && <div>{ props.headerControls }</div> }
							<button class='close-modal btn btn-sm btn-dark' onClick={this.onCancel}><i class="fas fa-times"></i></button>
						</div>
					}
					<div class='body'>
						{props.children}
					</div>
					{ !props.nofooter && 
						<div class='footer justify-content-end'>
							<button class='btn btn-sm btn-danger ml-2' onClick={this.onYes} disabled={props.disabled ? props.disabled : false}>{state.yesText || props.yesText || "Yes" }</button>
						</div>
					}
				</div>
			</div>
		);
	}
}
